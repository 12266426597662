import { DrivingOffencesReferenceData } from 'apiHelpers/referenceData/drivingOffences';
import { IncidentTypesReferenceData } from 'apiHelpers/referenceData/incidentTypes';
import { LicenceRestrictionsReferenceData } from 'apiHelpers/referenceData/licenceRestrictions';
import { TitlesReferenceData } from 'apiHelpers/referenceData/titles';

/* Redux types */
export enum ReferenceDataStatus {
  NotLoaded = 1,
  Loading,
  Loaded,
}

export type ReferenceDataItemWithStatus<T extends ReferenceData[ReferenceDataKey]> = {
  status: ReferenceDataStatus;
  data?: T;
};

export type ReferenceData = {
  titles: TitlesReferenceData;
  drivingOffences: DrivingOffencesReferenceData;
  incidentTypes: IncidentTypesReferenceData;
  licenceRestrictions: LicenceRestrictionsReferenceData;
};

export type ReferenceDataKey = keyof ReferenceData;

export type ReferenceDataState = {
  [TKey in ReferenceDataKey]: ReferenceDataItemWithStatus<ReferenceData[TKey]>;
};
