import { Location } from 'history';
import React from 'react';
import { PageType } from 'components/Layout';
import UnknownErrorPage from '../../templates/ErrorPage/500';

type Props = {
  children: React.ReactNode;
  location: Location;
  pageType?: PageType;
};

type State = {
  error: Error | null;
};

/**
 * This component catches any errors in its child component tree, logs those errors
 * to the console, and displays an error page instead.
 * https://reactjs.org/docs/error-boundaries.html
 *
 * This must be a class component as hooks don't support error boundaries
 */
/* istanbul ignore next */
class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const { location } = this.props;
    // Clear error if the location changes
    if (location.pathname !== prevProps.location.pathname && prevState.error) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        error: null,
      });
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('Caught error: ', error);
    console.error('Component Trace: ', info.componentStack);
  }

  public render(): React.ReactNode {
    const { error }: State = this.state;
    const { children, pageType }: Props = this.props;
    return (
      <>
        <div aria-live="assertive">{error && <UnknownErrorPage pageType={pageType} />}</div>
        {!error && children}
      </>
    );
  }
}

export default ErrorBoundary;
