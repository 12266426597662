import Accordion from '@rsa-digital/evo-shared-components/components/Accordion';
import {
  InnerSectionsWrapper,
  OuterSection,
  SectionButton,
  SectionTitle,
} from '@rsa-digital/evo-shared-components/components/Accordion/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  ${SectionTitle} {
    ${fonts.headingSmall};
  }

  ${InnerSectionsWrapper} {
    border-top: 1px solid ${colors.core03};
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(-1)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(3)};
    `}
  }

  ${SectionButton} {
    padding-left: ${spacing(1)};
  }

  ${OuterSection} {
    padding: ${spacing(3)} 0;

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(3)} 0;
    `}
  }
`;

export const AccordionWrapperBlock = styled.div`
  margin-top: ${spacing(8)};
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(8)};
  `}
`;

export const HeadingsGridItem = styled(GridItem)`
  margin: 0;
`;

export const Heading = styled.h2`
  ${fonts.headingLarge};
  margin-top: 0;
  margin-bottom: ${spacing(3.5)};
`;
