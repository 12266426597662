import ShareWidgetComponent from '@rsa-digital/evo-shared-components/components/ShareWidget';
import { SharingLink } from '@rsa-digital/evo-shared-components/components/ShareWidget/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { trackButtonClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';

type ShareWidgetProps = {
  social_media_share_widget: {
    body?: string;
    include_linkedin: boolean;
    include_pinterest: boolean;
    title?: string;
    pinterest_image?: { url: string };
  };
  canonical_url?: string | undefined;
  page_url?: string | undefined;
};

export const query = graphql`
  fragment ShareWidgetBlockNews on cs__news_article_page_blocks {
    social_media_share_widget {
      body
      include_linkedin
      include_pinterest
      title
      pinterest_image {
        url
      }
    }
  }
`;

type ShareWidgetLogos = {
  csShareWidget: {
    title: string;
    logos: {
      email: {
        logo: CsAsset;
      };
      facebook: {
        logo: CsAsset;
      };
      linkedin: {
        logo: CsAsset;
      };
      pinterest: {
        logo: CsAsset;
      };
      twitter: {
        logo: CsAsset;
      };
      whatsapp: {
        logo: CsAsset;
      };
    };
  };
};

type Site = {
  site: { siteMetadata: { siteUrl: string } };
};

const shareWidgetQuery = graphql`
  query {
    csShareWidget {
      title
      logos {
        email {
          logo {
            ...CsAsset
          }
        }
        facebook {
          logo {
            ...CsAsset
          }
        }
        linkedin {
          logo {
            ...CsAsset
          }
        }
        pinterest {
          logo {
            ...CsAsset
          }
        }
        twitter {
          logo {
            ...CsAsset
          }
        }
        whatsapp {
          logo {
            ...CsAsset
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const ShareWidgetBlock: React.FC<ShareWidgetProps> = ({
  social_media_share_widget,
  canonical_url,
  page_url,
}) => {
  const { csShareWidget, site } = useStaticQuery<ShareWidgetLogos & Site>(shareWidgetQuery);
  const email = processImageAsset(csShareWidget.logos.email.logo);
  const facebook = processImageAsset(csShareWidget.logos.facebook.logo);
  const twitter = processImageAsset(csShareWidget.logos.twitter.logo);
  const whatsApp = processImageAsset(csShareWidget.logos.whatsapp.logo);
  const linkedIn = processImageAsset(csShareWidget.logos.linkedin.logo);
  const pinterest = processImageAsset(csShareWidget.logos.pinterest.logo);

  /* istanbul ignore if */
  if (!email) {
    throw new Error('Email logo missing!');
  }
  /* istanbul ignore if */
  if (!facebook) {
    throw new Error('Facebook logo missing!');
  }
  /* istanbul ignore if */
  if (!twitter) {
    throw new Error('Twitter logo missing!');
  }
  /* istanbul ignore if */
  if (!whatsApp) {
    throw new Error('WhatsApp logo missing!');
  }
  /* istanbul ignore if */
  if (!linkedIn) {
    throw new Error('LinkedIn logo missing!');
  }
  /* istanbul ignore if */
  if (!pinterest) {
    throw new Error('Pinterest logo missing!');
  }
  /* istanbul ignore if */
  if (social_media_share_widget.include_pinterest && !social_media_share_widget.pinterest_image) {
    nonFatalBuildError(
      'Image missing',
      'Include Pinterest true and missing Pinterest image for Share Widget'
    );
  }

  /* istanbul ignore if */
  if (page_url === undefined) {
    throw new Error('Page url missing for Share Widget');
  }
  const linkUrl = new URL(canonical_url ?? page_url, site.siteMetadata.siteUrl).href;

  const link: SharingLink = {
    url: linkUrl,
    title: social_media_share_widget.title ?? undefined,
    description: social_media_share_widget.body ?? undefined,
    image: social_media_share_widget.pinterest_image
      ? social_media_share_widget.pinterest_image.url
      : undefined,
  };

  return (
    <ShareWidgetComponent
      title={csShareWidget.title}
      link={link}
      logos={{
        email: { logo: email, onClick: trackButtonClick('shareButton', 'Email') },
        facebook: {
          logo: facebook,
          onClick: trackButtonClick('shareButton', 'Facebook'),
        },
        twitter: { logo: twitter, onClick: trackButtonClick('shareButton', 'Twitter') },
        whatsApp: {
          logo: whatsApp,
          onClick: trackButtonClick('shareButton', 'WhatsApp'),
        },
        linkedIn: {
          include: social_media_share_widget.include_linkedin,
          logo: linkedIn,
          onClick: trackButtonClick('shareButton', 'LinkedIn'),
        },
        pinterest: {
          include: social_media_share_widget.include_pinterest,
          logo: pinterest,
          onClick: trackButtonClick('shareButton', 'Pinterest'),
        },
      }}
      data-cy="Share Widget"
    />
  );
};

export default React.memo(ShareWidgetBlock);
