import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { LicenceRestrictionsAction, UPDATE_LICENCE_RESTRICTIONS } from './action';
import { initialLicenceRestrictions, LicenceRestrictionsState } from './state';

export const licenceRestrictionsReducer = (
  licenceRestrictions: LicenceRestrictionsState = initialLicenceRestrictions,
  action: LicenceRestrictionsAction
): LicenceRestrictionsState => {
  switch (action.type) {
    case UPDATE_LICENCE_RESTRICTIONS:
      return {
        ...licenceRestrictions,
        ...action.update,
      };
    case RESET_STATE:
      return initialLicenceRestrictions;
    case RESET_ADD_A_DRIVER_STATE:
      return initialLicenceRestrictions;
    default:
      return licenceRestrictions;
  }
};
