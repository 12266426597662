import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const GridWithMargins = styled(Grid)`
  margin-top: ${spacing(8)};
  margin-bottom: ${spacing(12)};
`;

export const Heading = styled.h2`
  margin: 0 0 ${spacing(2)};
  ${fonts.headingLarge};
`;
export const Subheading = styled.p`
  margin: 0 0 ${spacing(8)};
  ${fonts.headingXSmall};
`;
