import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { ErrorState } from './state';

export const UPDATE_ERROR_STATE = 'UPDATE_ERROR_STATE';

export type ErrorAction = UpdateErrorStateAction | ResetStateAction;

export type UpdateErrorStateAction = {
  type: typeof UPDATE_ERROR_STATE;
  state: ErrorState;
};

export const useErrorState = (): [ErrorState, (update: ErrorState) => void] => {
  const errorState = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch();

  const updateErrorState = useCallback(
    (update: ErrorState): void => {
      dispatch({ type: UPDATE_ERROR_STATE, state: update });
    },
    [dispatch]
  );

  return [errorState, updateErrorState];
};
