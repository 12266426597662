import TableWithIcons from '@rsa-digital/evo-shared-components/components/TableWithIcons';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql } from 'gatsby';
import React from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { Icon } from 'types/contentStack';

type TableWithIconsData = {
  title: string;
  summary: string;
  icon: [Icon];
};

type TableWithIconsBlockProps = {
  table_with_icons: { rows: TableWithIconsData[]; title: string };
};

export const query = graphql`
  fragment TableWithIconsBlockInfo on cs__info_page_blocks {
    table_with_icons {
      title
      rows {
        title
        summary
        icon {
          icon_code
        }
      }
    }
  }
`;

const TableWithIconsBlock: React.FC<TableWithIconsBlockProps> = ({ table_with_icons }) => {
  const rows = table_with_icons.rows.map(({ title, summary, icon }) => {
    const iconCode = unwrapSingleton(icon)?.icon_code;
    /* istanbul ignore if */
    if (iconCode === undefined) {
      nonFatalBuildError('Icon missing', 'Icon missing for Table With Icons');
    }
    const color = iconCode === 'cross' ? colors.notificationError : colors.notificationSuccess;
    return {
      title,
      summary,
      icon: iconCode ? { iconName: iconCode, color } : undefined,
    };
  });
  return <TableWithIcons heading={table_with_icons.title} rows={rows} data-cy="TableWithIcons" />;
};

export default React.memo(TableWithIconsBlock);
