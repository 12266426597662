import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { NewDriversCoverState } from './state';

export const UPDATE_NEW_DRIVERS_COVER = 'UPDATE_NEW_DRIVERS_COVER';

export type NewDriversCoverAction =
  | UpdateNewDriversCoverAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateNewDriversCoverAction = {
  type: typeof UPDATE_NEW_DRIVERS_COVER;
  update: Partial<NewDriversCoverState>;
};

export const useNewDriversCover = (): [
  NewDriversCoverState,
  (update: Partial<NewDriversCoverState>) => void
] => {
  const newDriversCoverState = useSelector((state: RootState) => state.newDriversCover);
  const dispatch = useDispatch();

  const updateNewDriversCoverState = useCallback(
    (update: Partial<NewDriversCoverState>): void => {
      dispatch({ type: UPDATE_NEW_DRIVERS_COVER, update });
    },
    [dispatch]
  );

  return [newDriversCoverState, updateNewDriversCoverState];
};
