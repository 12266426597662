import DocumentDownloadButton, {
  documentFileSizeDisplay,
} from '@rsa-digital/evo-shared-components/components/DocumentDownloadButton';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processMandatoryAsset } from 'helpers/csTypeProcessors';
import { trackDocumentDownloadClick } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import { StyledFeatureContainer } from './styles';

export type DocumentDownloadBlockProps = {
  document_download: {
    image: CsAsset;
    documents: {
      document_name: string;
      document: CsAsset;
    }[];
  };
};

export const query = graphql`
  fragment DocumentDownloadBlockInfo on cs__info_page_blocks {
    document_download {
      image {
        ...CsAsset
      }
      documents {
        document_name
        document {
          ...CsAsset
        }
      }
    }
  }
`;

const DocumentDownloadBlock: React.FC<DocumentDownloadBlockProps> = ({ document_download }) => (
  <StyledFeatureContainer
    image={processImageAsset(document_download.image)}
    data-cy="DocumentDownload">
    {document_download.documents.map((document, index) => {
      const processedDocument = processMandatoryAsset(document.document);

      return (
        <DocumentDownloadButton
          documentName={document.document_name}
          documentUrl={processedDocument.publicUrl}
          fileSize={processedDocument.fileSize}
          key={document.document_name}
          onClick={trackDocumentDownloadClick(
            document.document_name,
            processedDocument.publicUrl,
            documentFileSizeDisplay(processedDocument.fileSize),
            index
          )}
        />
      );
    })}
  </StyledFeatureContainer>
);

export default React.memo(DocumentDownloadBlock);
