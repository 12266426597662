import { helmetJsonLdProp } from 'react-schemaorg';
import { FAQPage, Question } from 'schema-dts';

export type FAQ = {
  question: string;
  answer: string;
};

export const generateJsonLdFaqs = (
  faqs: FAQ[]
):
  | {
      type: 'application/ld+json';
      innerHTML: string;
    }
  | undefined => {
  if (faqs.length === 0) {
    return undefined;
  }
  const jsonLdQuestions: Question[] = faqs.map(
    (faq): Question => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answer,
      },
    })
  );
  return helmetJsonLdProp<FAQPage>({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: jsonLdQuestions,
  });
};
