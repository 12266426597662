import {
  AboutTheDriverRequest,
  AddDriverRequest,
  AddTemporaryDriverRequest,
  ClaimsHistoryRequest,
  DriverDetailsRequest,
  DrivingOffencesRequest,
  LicenceDetailsRequest,
  LicenceRestrictionRequest,
  NewDriversCoverRequest,
  PolicySeatStatusApiResponse,
  ReplaceDriverRequest,
} from './types';
import apiClient from '../apiClient';

export type DriversClient = {
  validatePermanentDriverRequest: (
    policyNumber: string,
    body:
      | AboutTheDriverRequest
      | DriverDetailsRequest
      | DrivingOffencesRequest
      | LicenceDetailsRequest
      | ClaimsHistoryRequest
      | LicenceRestrictionRequest,
    pageName: string
  ) => Promise<void>;
  validateTemporaryDriverRequest: (
    policyNumber: string,
    body:
      | AboutTheDriverRequest
      | DriverDetailsRequest
      | DrivingOffencesRequest
      | LicenceDetailsRequest
      | ClaimsHistoryRequest
      | LicenceRestrictionRequest
      | NewDriversCoverRequest,
    pageName: string
  ) => Promise<void>;
  getSeatStatus: (
    policyNumber: string,
    startDate?: string,
    endDate?: string
  ) => Promise<PolicySeatStatusApiResponse>;
  addPermanentDriver: (policyNumber: string, body: AddDriverRequest) => Promise<void>;
  removePermanentDriver: (policyNumber: string, driverRef: string) => Promise<void>;
  replacePermanentDriver: (policyNumber: string, body: ReplaceDriverRequest) => Promise<void>;
  addTemporaryDriver: (policyNumber: string, body: AddTemporaryDriverRequest) => Promise<void>;
  removeTemporaryDriver: (policyNumber: string, driverRef: string) => Promise<void>;
};

const driversClient: DriversClient = {
  validatePermanentDriverRequest: (
    policyNumber: string,
    body:
      | AboutTheDriverRequest
      | DriverDetailsRequest
      | DrivingOffencesRequest
      | LicenceDetailsRequest
      | ClaimsHistoryRequest
      | LicenceRestrictionRequest,
    pageName: string
  ) => {
    return apiClient.post(
      `policy/${policyNumber}/permanent-drivers${
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        !(body as any).replacedDriverRef ? '' : '/replace'
      }/validate`,
      body,
      { pageName }
    );
  },
  validateTemporaryDriverRequest: (
    policyNumber: string,
    body:
      | AboutTheDriverRequest
      | DriverDetailsRequest
      | DrivingOffencesRequest
      | LicenceDetailsRequest
      | ClaimsHistoryRequest
      | LicenceRestrictionRequest
      | NewDriversCoverRequest,
    pageName: string
  ) => {
    return apiClient.post(`policy/${policyNumber}/temporary-drivers/validate`, body, {
      pageName,
    });
  },
  getSeatStatus: (policyNumber: string, startDate?: string, endDate?: string) =>
    apiClient.get(`policy/${policyNumber}/temporary-drivers/seat-status`, { startDate, endDate }),
  addPermanentDriver: (policyNumber: string, body: AddDriverRequest) =>
    apiClient.post(`policy/${policyNumber}/permanent-drivers`, body),
  removePermanentDriver: (policyNumber: string, driverRef: string) =>
    apiClient.delete(`policy/${policyNumber}/permanent-drivers/${driverRef}`),
  replacePermanentDriver: (policyNumber: string, body: ReplaceDriverRequest) =>
    apiClient.post(`policy/${policyNumber}/permanent-drivers/replace`, body),
  addTemporaryDriver: (policyNumber: string, body: AddTemporaryDriverRequest) =>
    apiClient.post(`policy/${policyNumber}/temporary-drivers`, body),
  removeTemporaryDriver: (policyNumber: string, driverRef: string) =>
    apiClient.delete(`policy/${policyNumber}/temporary-drivers/${driverRef}`),
};

export default driversClient;
