import SimpleFooter from '@rsa-digital/evo-shared-components/components/Footer/SimpleFooter';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processLink, unwrapSingleton } from 'helpers/csTypeProcessors';
import { trackFooterClick } from 'helpers/eventTracking';
import { handleLinkClick } from 'helpers/navigation';
import { Icon as CsIcon } from 'types/contentStack';

export type AccountFooterData = {
  csAccountFooter: {
    heading: string;
    links: { url: string; text: string; open_in_new_tab: boolean; icon: [CsIcon] }[];
    footer_compliance: {
      compliance: string;
    };
  };
};

const FooterQuery = graphql`
  query {
    csAccountFooter {
      heading
      links {
        url
        text
        open_in_new_tab
        icon {
          icon_code
        }
      }
      footer_compliance {
        compliance
      }
    }
  }
`;

/* istanbul ignore next */
const handleAndTrackFooterLink = (text: string, url: string) => (event: React.MouseEvent) => {
  trackFooterClick(text, url)();
  handleLinkClick(url)(event);
};

const AccountFooter: React.FC<{ complianceText?: string }> = ({ complianceText }) => {
  const { csAccountFooter } = useStaticQuery<AccountFooterData>(FooterQuery);

  const links = csAccountFooter.links.map((link) => {
    const iconCode = unwrapSingleton(link.icon)?.icon_code;
    /* istanbul ignore if */
    if (iconCode === undefined) {
      throw new Error('Icon missing for Account Footer');
    }

    return {
      icon: iconCode,
      ...processLink(link),
      onClick: handleAndTrackFooterLink(link.text, link.url),
    };
  });

  return (
    <SimpleFooter
      complianceText={complianceText ?? csAccountFooter.footer_compliance.compliance}
      links={links}
      title={csAccountFooter.heading}
      data-cy="AccountFooter"
    />
  );
};

export default React.memo(AccountFooter);
