import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import {
  HeroPlain,
  HeroWithImageAndOrigami,
  HeroWithImageHorizontal,
  HeroWithImageVertical,
  HeroWithOrigamiAndBanner,
  HeroWithOrigamiBackground,
} from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { CsAsset } from 'types/contentStack';

export type HeroData = {
  heading: string;
  subheading: string | null;
  hero_type:
    | 'Hero banner - just text'
    | 'Hero banner - background'
    | 'Hero banner - image (vertical)'
    | 'Hero banner - image (horizontal)'
    | 'Hero banner - background and image'
    | 'Hero banner - homepage';
  image: CsAsset | null;
  logo: CsAsset | null;
  back_button?: {
    display_text: string;
    url: string;
  };
};

type HeroProps = {
  hero: HeroData;
};

const processHeroImage = (image: CsAsset | null): Image => {
  const processedImage = processImageAsset(image);
  /* istanbul ignore if */
  if (!processedImage) {
    nonFatalBuildError('Image missing', 'Image expected for hero image');
  }
  return processedImage as Image;
};

export const query = graphql`
  fragment HeroInfo on cs__info_page {
    hero {
      heading
      subheading
      hero_type
      image {
        ...CsAsset
      }
      logo {
        ...CsAsset
      }
    }
  }
  fragment HeroNewsIndex on cs__news_index_page {
    hero {
      heading
      subheading
      hero_type
      image {
        ...CsAsset
      }
    }
  }
  fragment HeroNews on cs__news_article_page {
    hero {
      heading
      subheading
      hero_type
      image {
        ...CsAsset
      }
    }
  }
  fragment HeroError on cs__error_page {
    hero {
      heading
      subheading
      hero_type
      image {
        ...CsAsset
      }
    }
  }
  fragment HeroSignedOut on cs__signed_out_page {
    hero {
      heading
      subheading
      hero_type
    }
  }
  fragment HeroBeforeYouStart on cs__add_driver_before_you_start {
    hero {
      heading
      subheading
      hero_type
      image {
        ...CsAsset
      }
    }
  }
`;

const Hero: React.FC<HeroProps> = ({ hero }) => (
  <>
    {hero.hero_type === 'Hero banner - just text' && (
      <HeroPlain
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        data-cy="HeroJustText"
      />
    )}
    {hero.hero_type === 'Hero banner - background' && (
      <HeroWithOrigamiBackground
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        data-cy="HeroOrigamiBackground"
      />
    )}
    {hero.hero_type === 'Hero banner - image (vertical)' && (
      <HeroWithImageVertical
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        image={processHeroImage(hero.image)}
        data-cy="HeroImageVertical"
      />
    )}
    {hero.hero_type === 'Hero banner - image (horizontal)' && (
      <HeroWithImageHorizontal
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        image={processHeroImage(hero.image)}
        data-cy="HeroImageHorizontal"
      />
    )}
    {hero.hero_type === 'Hero banner - background and image' && (
      <HeroWithImageAndOrigami
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        image={processHeroImage(hero.image)}
        data-cy="HeroImageAndOrigami"
      />
    )}
    {hero.hero_type === 'Hero banner - homepage' && (
      <HeroWithOrigamiAndBanner
        heading={hero.heading}
        subhead={hero.subheading ?? undefined}
        image={processHeroImage(hero.image)}
        bannerImage={processHeroImage(hero.logo)}
        data-cy="HeroOrigamiAndBanner"
      />
    )}
  </>
);

export default React.memo(Hero);
