import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type TitlesReferenceDataResponse = {
  Titles: ApiOption[];
};

export type TitlesReferenceData = { titles: ReferenceDataOption[] };

export const mapTitlesRefData = (data: TitlesReferenceDataResponse): TitlesReferenceData => ({
  titles: data.Titles.map(mapApiOption),
});
