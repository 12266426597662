import isMobile from 'is-mobile';
import { useEffect } from 'react';
import { AuthorInfoBlockProps } from 'components/blocks/AuthorInfo';
import { DocumentDownloadBlockProps } from 'components/blocks/DocumentDownload';
import { RelatedContentBlockProps } from 'components/blocks/RelatedContent';
import { CsBlock } from './blockMapper';
import { trackEvent } from './eventTracking';

export type AnalyticsPageVariables = {
  pageCategories: string[];
  pageType: string;
  pageError?: number;
  articleName?: string;
};

const getArticleWordCount = (): number => {
  const article = document.getElementsByTagName('article')[0];
  const textContent = article?.textContent;
  return textContent ? textContent.trim().split(/\s+/).length : 0;
};

export const useAnalyticsPageVariables = (
  analyticsPageVariables: AnalyticsPageVariables,
  blocks: CsBlock[],
  includeWordCount = false
): void => {
  const downloadDocuments = blocks
    .filter((block) => block.document_download)
    .flatMap((block) =>
      (block as DocumentDownloadBlockProps).document_download.documents.map(
        (document) => document.document_name
      )
    );
  const suggestedArticles = blocks
    .filter((block) => block.related_content_with_link)
    .flatMap((block) =>
      (block as RelatedContentBlockProps).related_content_with_link.related_content_cards.map(
        (card) => card.title
      )
    );
  const articleAuthorBlocks = blocks.filter((block) => block.author_info) as AuthorInfoBlockProps[];
  const articleAuthor = articleAuthorBlocks[0]?.author_info.author_info[0]?.author;

  // Explicitly apply all fields so that they are flushed if they are not defined
  useEffect(
    () =>
      trackEvent({
        event: 'pageVars',
        pageCategories: analyticsPageVariables.pageCategories,
        pageType: analyticsPageVariables.pageType,
        pageError: analyticsPageVariables.pageError,
        isMobile: isMobile({ featureDetect: true }),
        articleName: analyticsPageVariables.articleName,
        articleAuthor,
        articleWordCount: includeWordCount ? getArticleWordCount() : undefined,
        suggestedArticles,
        downloadDocuments,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
