import { RESET_STATE } from 'state/actions';
import { UPDATE_USER_STATE, UserAction } from './actions';
import { initialUserState, UserState } from './state';

export const userReducer = (state: UserState = initialUserState, action: UserAction): UserState => {
  switch (action.type) {
    case UPDATE_USER_STATE:
      return action.state;
    case RESET_STATE:
      return initialUserState;
    default:
      return state;
  }
};
