import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addDaysToDate,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export type TimeValue = {
  hour?: string;
  minute?: string;
};

export const initialTimeValue: TimeValue = {
  hour: '',
  minute: '',
};

export const isBefore = (earlierTime: TimeValue, laterTime: TimeValue): boolean => {
  if (earlierTime.hour && earlierTime.minute && laterTime.hour && laterTime.minute) {
    return (
      Number(earlierTime.hour) < Number(laterTime.hour) ||
      (earlierTime.hour === laterTime.hour && Number(earlierTime.minute) < Number(laterTime.minute))
    );
  }
  return false;
};

const getDateTime = (date: DateValue, time: TimeValue): Date | null => {
  const parsedDate = dateValueToUtcDate(date);

  if (parsedDate && time.hour && time.minute) {
    parsedDate.setHours(Number(time.hour));
    parsedDate.setMinutes(Number(time.minute));
    return parsedDate;
  }

  return null;
};

export const hasMinimumCoverLength = (
  earlierDate: DateValue,
  earlierTime: TimeValue,
  laterDate: DateValue,
  laterTime: TimeValue
): boolean => {
  // This is longer than the actual hour minimum to allow for the time taken to complete the form
  const minimumLengthHours = 1.5;
  const hourInMilliseconds = 60 * 60 * 1000;

  const parsedEarlierDate = getDateTime(earlierDate, earlierTime);
  const parsedLaterDate = getDateTime(laterDate, laterTime);

  if (parsedEarlierDate && parsedLaterDate) {
    return (
      // Since the start of the day is taken as 00:01, and the end of the day as 23:59, we validate a minute
      // less to allow for this time difference
      parsedLaterDate.valueOf() - parsedEarlierDate.valueOf() >=
      (minimumLengthHours - 1 / 60) * hourInMilliseconds
    );
  }
  return false;
};

export const dateTimesWithinXDaysOfEachOther = (
  daysDifference: number,
  earlierDate: DateValue,
  earlierTime: TimeValue,
  laterDate: DateValue,
  laterTime: TimeValue
): boolean => {
  const parsedEarlierDate = getDateTime(earlierDate, earlierTime);
  const parsedLaterDate = getDateTime(laterDate, laterTime);

  if (parsedEarlierDate && parsedLaterDate) {
    return addDaysToDate(parsedEarlierDate, daysDifference) >= parsedLaterDate;
  }
  return true;
};

export const dateTimeWithinXDaysFromNow = (
  daysDifference: number,
  laterDate: DateValue,
  laterTime: TimeValue
): boolean => {
  const now = new Date();
  const parsedLaterDate = getDateTime(laterDate, laterTime);

  if (now && parsedLaterDate) {
    return addDaysToDate(now, daysDifference) >= parsedLaterDate;
  }
  return true;
};

export const isInFuture = (date: DateValue, time: TimeValue): boolean => {
  const parsedDate = dateValueToUtcDate(date);
  if (parsedDate && time.hour && time.minute) {
    parsedDate.setHours(Number(time.hour), Number(time.minute), 0, 0);
    return parsedDate >= new Date();
  }
  return false;
};
