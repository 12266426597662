import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import {
  CallToAction,
  CallToActionWithoutLink,
} from '@rsa-digital/evo-shared-components/commonTypes/links';
import isEmpty from 'lodash/isEmpty';
import { BreadcrumbsData } from 'components/Breadcrumbs/types';
import { MetaTags } from 'components/Meta';
import { CsAsset, CsCta, CsCtaWithoutLink, CsVideo, PageMeta } from 'types/contentStack';
import { buildWarning } from './errorReporting';
import { PageLocation } from './eventTracking';
import { handleAndTrackCtaClick } from './navigation';

const forceNoindex = process.env.GATSBY_FORCE_NOINDEX;

/**
 * Returns undefined if the object if contains no truthy values,
 * otherwise returns the original object.
 *
 * For optional fields in contentstack that contain multiple fields, if
 * the user leaves the types blank then we get an object with empty values,
 * rather than null/undefined. For example, this occurs when dealing with
 * the 'link' type being used for optional CTAs. We can use this method
 * to process those cases.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const undefinedIfEmpty = <T extends Record<string, any>>(
  csObject: T | null
): T | undefined => {
  if (csObject != null && Object.values(csObject).some((v) => !!v)) {
    return csObject;
  }
  return undefined;
};

/**
 * Returns the single element in the array if it exists, otherwise returns undefined
 *
 * For certain fields in contentstack, if the user leaves it blank then we get an
 * empty array, and when filled, we get a singleton array. One such field is the
 * reference field. We can use this method to process those cases.
 */
export const unwrapSingleton = <T>(singletonOrEmptyArray: [T] | []): T | undefined =>
  singletonOrEmptyArray.length === 1 ? singletonOrEmptyArray[0] : undefined;

export type Asset = {
  publicUrl: string;
  filename?: string;
  fileSize?: string;
  description?: string;
};

export const processAsset = (csAsset: CsAsset | null): Asset | undefined => {
  const asset = undefinedIfEmpty(csAsset);

  /* istanbul ignore if */
  if (asset && !asset.description) {
    buildWarning(`Asset with filename "${asset.filename}" is missing a description`);
  }

  return asset
    ? {
        publicUrl: asset.localAsset.publicURL,
        filename: asset.filename ?? /* istanbul ignore next */ undefined,
        fileSize: asset.file_size ?? /* istanbul ignore next */ undefined,
        description: asset.description ?? /* istanbul ignore next */ undefined,
      }
    : undefined;
};

export const processMandatoryAsset = (csAsset: CsAsset | null): Asset => {
  const result = processAsset(csAsset);
  /* istanbul ignore next */
  if (result === undefined) {
    throw new Error('Missing asset field');
  }
  return result;
};

export const processImageAsset = (csAsset: CsAsset | null): Image | undefined => {
  const asset = processAsset(csAsset);

  return asset
    ? {
        url: asset.publicUrl,
        altText: asset.description ?? /* istanbul ignore next */ undefined,
      }
    : undefined;
};

export const processMandatoryImageAsset = (csAsset: CsAsset | null): Image => {
  const asset = processImageAsset(csAsset);

  if (asset === undefined) {
    throw new Error('Missing asset field');
  }

  return asset;
};

export const processCta = (cta: CsCta, location: PageLocation): CallToAction => ({
  displayText: cta.display_text,
  screenReaderText: cta.screen_reader_text,
  url: cta.url,
  openInNewTab: cta.open_in_new_tab,
  onClick: handleAndTrackCtaClick(
    cta.url,
    location,
    cta.screen_reader_text || cta.display_text,
    cta.open_in_new_tab
  ),
});

export const processOptionalCta = (
  maybeCta: [CsCta] | [] | null,
  location: PageLocation
): CallToAction | undefined => {
  if (!maybeCta || maybeCta.length === 0) {
    return undefined;
  }

  const cta = maybeCta?.[0] as CsCta;
  return processCta(cta, location);
};

export const processMandatoryCta = (cta: [CsCta], location: PageLocation): CallToAction => {
  const result = processOptionalCta(cta, location);
  /* istanbul ignore next */
  if (result === undefined) {
    throw new Error('Missing CTA field');
  }
  return result;
};

export const processCtaWithoutLink = (cta: [CsCtaWithoutLink]): CallToActionWithoutLink => {
  return {
    displayText: cta[0].display_text,
    screenReaderText: cta[0].screen_reader_text,
  };
};

export type Video = {
  url: string;
  screenReaderText: string;
  jsonLd: string;
};

export const processOptionalVideo = (maybeVideo: [CsVideo] | [] | null): Video | undefined => {
  if (isEmpty(maybeVideo)) {
    return undefined;
  }

  const video = maybeVideo?.[0] as CsVideo;
  /* istanbul ignore if */
  if (video && !video.screen_reader_text) {
    buildWarning(`Video with src "${video.url}" is missing a description`);
  }

  return {
    url: video.url,
    screenReaderText: video.screen_reader_text ?? /* istanbul ignore next */ '',
    jsonLd: video.json_ld,
  };
};

export const processMandatoryVideo = (video: [CsVideo] | null): Video => {
  const result = processOptionalVideo(video);
  /* istanbul ignore next */
  if (result === undefined) {
    throw new Error('Missing Video field');
  }
  return result;
};

export const processLink = (link: {
  url: string;
  text: string;
  open_in_new_tab: boolean;
}): {
  url: string;
  text: string;
  openInNewTab: boolean;
} => {
  return {
    url: link.url,
    text: link.text,
    openInNewTab: link.open_in_new_tab,
  };
};

const processRobots = (robots: string | undefined): string | undefined => {
  // This is used to hide pages from search engines in non-production environments
  if (forceNoindex === 'true') {
    return 'noindex, nofollow';
  }
  return robots;
};

const processCanonical = (canonical: string | undefined): string | undefined => {
  // When hiding pages we should not include a canonical tag
  // https://www.reddit.com/r/TechSEO/comments/8yahdr/2_questions_about_the_canonical_tag/e2dey9i/?context=1
  if (forceNoindex === 'true') {
    return undefined;
  }
  return canonical;
};

export const processPageMeta = (
  pageMeta: PageMeta,
  pageUrl: string
): { breadcrumbs: BreadcrumbsData; meta: MetaTags } => ({
  breadcrumbs: pageMeta.breadcrumbs,
  meta: {
    title: pageMeta.title,
    robots: processRobots(pageMeta.robots),
    description: pageMeta.description,
    canonical_tag: processCanonical(pageMeta.canonical_tag),
    og_url: pageMeta.og_url || pageMeta.canonical_tag || pageUrl,
    og_title: pageMeta.og_title || pageMeta.title,
    og_description: pageMeta.og_description || pageMeta.description,
    og_image: processImageAsset(pageMeta.og_image_asset),
    og_type: pageMeta.og_type,
    twitter_site: pageMeta.twitter_site,
    twitter_card: pageMeta.twitter_card || 'summary',
  },
});
