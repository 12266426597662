import React, { Fragment } from 'react';
import AuthorInfoBlock from 'components/blocks/AuthorInfo';
import BasicAccordionBlock from 'components/blocks/BasicAccordion';
import BasicAccountAccordionBlock from 'components/blocks/BasicAccountAccordion';
import ChecklistSectionBlock from 'components/blocks/ChecklistSection';
import CookiesListBlock from 'components/blocks/CookiesList';
import CtaBannerBlock from 'components/blocks/CtaBanner';
import DividerBlock from 'components/blocks/Divider';
import DocumentDownloadBlock from 'components/blocks/DocumentDownload';
import FeaturesBlock from 'components/blocks/Feature';
import ImageAndCtaCardsBlock from 'components/blocks/ImageAndCtaCards';
import InfoCardsBlock from 'components/blocks/InfoCards';
import InfoCardsHomePageBlock from 'components/blocks/InfoCards/InfoCardsHomePage';
import IntroBlock from 'components/blocks/Intro';
import NestedAccordionBlock from 'components/blocks/NestedAccordion';
import PolicyAccordionBlock from 'components/blocks/PolicyAccordion';
import PolicyCardBlock from 'components/blocks/PolicyCard';
import RelatedContentBlock from 'components/blocks/RelatedContent';
import RichTextBlock from 'components/blocks/RichText';
import ShareWidgetBlock from 'components/blocks/ShareWidget';
import SplitInfoCardBlock from 'components/blocks/SplitInfoCard';
import TabbedTableSectionBlock from 'components/blocks/TabbedTableSection';
import TableWithIconsBlock from 'components/blocks/TableWithIcons';
import TestimonialCardsBlock from 'components/blocks/Testimonials';
import { PageMeta } from 'types/contentStack';
import { nonFatalBuildError, warningWithDetail } from './errorReporting';

export type CsBlock = {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type BlockProps<T extends string> = {
  [K in T]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type MappingEntry<T extends string = string> = [T, React.ComponentType<BlockProps<T>>];

const m = <T extends string>(
  key: T,
  value: React.ComponentType<BlockProps<T>>
): MappingEntry<T> => [key, value];

/**
 * This is the main mapping from blocks in ContentStack to React components.
 *
 * The key should be the name of the block in ContentStack and the value is the corresponding
 * component, where the Props for the component match the block returned by the graphql query.
 *
 * The `m` function is a helper that ensures that the key and props correspond as expected.
 */
const MAPPING: MappingEntry[] = [
  m('info_cards', InfoCardsBlock),
  m('info_cards_home_page', InfoCardsHomePageBlock),
  m('split_info_card', SplitInfoCardBlock),
  m('text_block', RichTextBlock),
  m('intro', IntroBlock),
  m('features', FeaturesBlock),
  m('divider', DividerBlock),
  m('document_download', DocumentDownloadBlock),
  m('related_content_with_link', RelatedContentBlock),
  m('table_with_icons', TableWithIconsBlock),
  m('checklist_section', ChecklistSectionBlock),
  m('cta_banner', CtaBannerBlock),
  m('testimonials', TestimonialCardsBlock),
  m('tabbed_table_section', TabbedTableSectionBlock),
  m('basic_accordion', BasicAccordionBlock),
  m('nested_accordion', NestedAccordionBlock),
  m('image_and_cta_cards', ImageAndCtaCardsBlock),
  m('social_media_share_widget', ShareWidgetBlock),
  m('author_info', AuthorInfoBlock),
  m('policy_card', PolicyCardBlock),
  m('show_policy_accordion', PolicyAccordionBlock),
  m('basic_account_accordion', BasicAccountAccordionBlock),
  m('cookies_list', CookiesListBlock),
];

const mapBlock = (
  mapping: MappingEntry[],
  block: CsBlock,
  additionalParams?: {
    pageMeta?: PageMeta;
    pageUrl?: string;
  }
): JSX.Element | null => {
  const key = Object.entries(block).find(([, value]) => !!value)?.[0];
  /* istanbul ignore if */
  if (!key) {
    warningWithDetail(
      'Unrecognised empty block.',
      'Have you forgotten to extend the page query with a new block type?'
    );
    return null;
  }

  const Block = mapping.find(([k]) => k === key)?.[1];

  /* istanbul ignore if */
  if (!Block) {
    nonFatalBuildError(
      `Unrecognised block of type '${key}'.`,
      'Have you forgotten to update the block mapping?'
    );
    return null;
  }

  if (Block === (ShareWidgetBlock as unknown)) {
    return (
      <Block
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...{ [key]: block[key] }}
        canonical_url={additionalParams?.pageMeta?.canonical_tag}
        page_url={additionalParams?.pageUrl}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Block {...{ [key]: block[key] }} />;
};

const mapBlocksInternal = (mapping: MappingEntry[]) => (
  blocks: CsBlock[],
  additionalParams?: {
    pageMeta?: PageMeta;
    pageUrl?: string;
  }
): JSX.Element[] =>
  blocks.map((block, i) => (
    // Blocks will never be reordered
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>{mapBlock(mapping, block, additionalParams)}</Fragment>
  ));

export const mapBlocks = mapBlocksInternal(MAPPING);
