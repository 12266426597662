import { graphql } from 'gatsby';
import React from 'react';
import { StyledFullWidthDivider, StyledSimpleDivider } from './styles';

type DividerBlockProps = {
  divider: {
    style: 'Content Width' | 'Full Width';
  };
};
export const query = graphql`
  fragment DividerBlockInfo on cs__info_page_blocks {
    divider {
      style
    }
  }
  fragment DividerBlockNews on cs__news_article_page_blocks {
    divider {
      style
    }
  }
  fragment DividerBlockError on cs__error_page_blocks {
    divider {
      style
    }
  }
`;

const DividerBlock: React.FC<DividerBlockProps> = ({ divider }) =>
  divider.style === 'Content Width' ? (
    <StyledSimpleDivider data-cy="ContentWidthDivider" />
  ) : (
    <StyledFullWidthDivider data-cy="FullWidthDivider" />
  );

export default React.memo(DividerBlock);
