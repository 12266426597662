import {
  RowValueText,
  StyledTd,
  StyledTh,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import { Tooltip } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import driversService from 'apiHelpers/drivers/service';
import policyLockClient from 'apiHelpers/policyLockClient';
import { graphql, navigate } from 'gatsby';
import React, { MouseEventHandler, useState } from 'react';
import { RESET_ADD_A_DRIVER_STATE } from 'state/actions';
import * as KnockoutErrorCodes from 'state/error/errorCodes';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { RELOAD_POLICY } from 'state/policy/actions';
import { NamedDriver, TemporaryDriver, usePolicyState } from 'state/policy/state';
import useDispatch from 'state/useDispatch';
import { containsErrorCodeInResponse, isAxiosError } from 'helpers/axiosResponseHelper';
import { useIsUpdateBlocked } from 'helpers/blockedUpdateHelpers';
import createStaffUpdateBlockedModalContent from 'helpers/createStaffUpdateBlockedModalContent';
import { warningWithDetail } from 'helpers/errorReporting';
import {
  trackAccordionCollapseClick,
  trackAccordionExpandClick,
  trackNamedDriverFormStart,
  trackTooltipClick,
} from 'helpers/eventTracking';
import { handleAndTrackCtaClick } from 'helpers/navigation';
import replaceAccountDriverPlaceholders from 'helpers/placeholders/accountDriversPlaceholders';
import {
  CsPlaceholders,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import usePermanentDriverActionHandler from 'helpers/usePermanentDriverActionHandler';
import usePolicyNumber from 'helpers/usePolicyNumber';
import usePolicyUpdateAccess from 'helpers/usePolicyUpdateAccess';
import useRequestHandler from 'helpers/useRequestHandler';
import { DriverType } from 'types/forms';
import {
  ButtonContainer,
  CancelButton,
  ConfirmButton,
  IconWithLeftMargin,
  IconWithRightMargin,
  ModalButtonContainer,
  ModalContainer,
  ModalHeading,
  RedStyledTertiaryButton,
  RichTextWithMargin,
  RichTextWithTopPadding,
  RowTitle,
  StyledExpandableCard,
  StyledTable,
  StyledTertiaryButton,
  StyledTrWithNoTopPadding,
  TertiaryButtonWithTopMargin,
  TitleWithMargin,
} from '../styles';
import { NamedDriversData, NamedDriversProps } from '../types';

export const query = graphql`
  fragment NamedDriversPolicyAccordion on cs__policy_details_accordion_accordion_entries {
    named_drivers {
      heading
      additional_info {
        show_additional_info
        text
      }
      initially_display
      hirer
      date_of_birth
      business_use {
        heading
        tooltip {
          body
          button_text {
            open
            close
          }
        }
      }
      replacement_driver_suffix
      open_driving_replacement_text
      add_driver_button {
        display_text
        alt_text
      }
      remove_driver {
        display_text
        alt_text
      }
      replace_driver {
        display_text
        alt_text
      }
      maximum_drivers_replacement_text
      modals {
        remove_driver_success_modal {
          heading
          body
          close_text
        }
        remove_driver_confirmation_modal {
          heading
          body
          confirm_text
          cancel_text
        }
        remove_only_driver_error_modal {
          heading
          body
          close_text
        }
        remove_only_permanent_driver_error_modal {
          heading
          body
          close_text
        }
        remove_only_full_licence_driver_error_modal {
          heading
          body
          close_text
        }
        permanent_drivers_locked_modal {
          heading
          body
          close_text
        }
      }
    }
  }
`;

type ModalProps = {
  heading: string;
  body: string;
  confirmButton?: { onClick?: MouseEventHandler; displayText?: string };
  cancelText?: string;
};

const getPermanentDriverTitle = (
  permDriver: NamedDriver,
  replacementDriver: TemporaryDriver | undefined,
  suffix: string,
  hirerText: string | undefined
): string => {
  const placeholder: CsPlaceholders<{ driverName: string | null }> = {
    replacementDriver: {
      getSubstitutionText: ({ driverName }) => driverName ?? '',
      isPii: true,
    },
  };

  // TODO MDP-395
  const replacePlaceholder = replacePlaceholdersPlainText(
    placeholder,
    {
      driverName: replacementDriver?.fullName ?? null,
    },
    true
  );

  return `${permDriver.fullName}${hirerText ? ` ${hirerText}` : ''}${
    replacementDriver ? ` ${replacePlaceholder(suffix)}` : ''
  }`;
};

const NamedDriversSection: React.FC<NamedDriversProps> = ({ named_drivers }) => {
  const { policy } = usePolicyState();
  const policyNumber = usePolicyNumber();
  const dispatch = useDispatch();
  const addDriverHelper = usePolicyUpdateAccess();
  const permanentDriverActionHandler = usePermanentDriverActionHandler();
  const [, updateReplaceDriver] = useReplaceDriver();
  const handleRequest = useRequestHandler();
  const [modalInfo, setModalInfo] = useState<ModalProps | null>(null);
  const showModifyDriverButtons = !useIsUpdateBlocked();

  if (!policy) {
    return null;
  }

  if (addDriverHelper.hasOpenDriving) {
    return <RichTextWithTopPadding html={named_drivers.open_driving_replacement_text} />;
  }

  const getReplacedDriver = (permanentDriver: NamedDriver): TemporaryDriver | undefined =>
    policy.temporaryDrivers.find(
      (temporaryDriver) =>
        temporaryDriver.coverStart &&
        temporaryDriver.coverStart <= new Date() &&
        permanentDriver.driverRef === temporaryDriver.replacedDriverRef
    );

  const getModalCsContent = (indexOfNamedDriver: number): NamedDriversData =>
    replaceAccountDriverPlaceholders(policy.namedDrivers[indexOfNamedDriver], named_drivers);

  const driverRemovalErrors = [
    KnockoutErrorCodes.MinimumNamedDriver,
    KnockoutErrorCodes.MinimumNamedFullLicenceDriver,
  ] as const;

  const showRemoveDriverResponseModal = (
    indexOfNamedDriver: number,
    modalContentType: typeof driverRemovalErrors[number] | 'SUCCESS'
  ): void => {
    const modalsCsContent = getModalCsContent(indexOfNamedDriver).modals;

    const modalResponseContent = (() => {
      switch (modalContentType) {
        case KnockoutErrorCodes.MinimumNamedDriver:
          return modalsCsContent.remove_only_driver_error_modal;
        case KnockoutErrorCodes.MinimumNamedFullLicenceDriver:
          return modalsCsContent.remove_only_full_licence_driver_error_modal;
        default:
          return modalsCsContent.remove_driver_success_modal;
      }
    })();
    setModalInfo({
      heading: modalResponseContent.heading,
      body: modalResponseContent.body,
      confirmButton: {
        displayText: modalResponseContent.close_text,
        onClick:
          modalContentType === 'SUCCESS' ? () => dispatch({ type: RELOAD_POLICY }) : undefined,
      },
    });
  };

  const showConfirmRemoveDriverModal = (indexOfNamedDriver: number): void => {
    const modalsCsContent = getModalCsContent(indexOfNamedDriver).modals;

    const removeDriver = async (): Promise<void> => {
      await policyLockClient.lockPolicy(policyNumber).catch((e) => {
        // If this call errors we don't want to do anything
        // so catching and ignoring this error to prevent it bubbling up.
        warningWithDetail('Failed to lock policy', e.message);
      });
      return driversService
        .removePermanentDriverRequest(
          policyNumber,
          policy.namedDrivers[indexOfNamedDriver].driverRef ?? ''
        )
        .then(() => showRemoveDriverResponseModal(indexOfNamedDriver, 'SUCCESS'));
    };

    const handleError = (err: Error): void => {
      if (isAxiosError(err)) {
        const knockoutError = driverRemovalErrors.find((code) =>
          containsErrorCodeInResponse(err.response?.data, code)
        );
        if (knockoutError) {
          showRemoveDriverResponseModal(indexOfNamedDriver, knockoutError);
          return;
        }
      }
      throw err;
    };

    setModalInfo({
      heading: modalsCsContent.remove_driver_confirmation_modal.heading,
      body: modalsCsContent.remove_driver_confirmation_modal.body,
      cancelText: modalsCsContent.remove_driver_confirmation_modal.cancel_text,
      confirmButton: {
        displayText: modalsCsContent.remove_driver_confirmation_modal.confirm_text,
        onClick: async () => {
          await handleRequest(removeDriver, true, handleError);
        },
      },
    });
  };

  const showPermanentDriversLockedModal = (): void => {
    const permanentDriversBlockedModalContent = named_drivers.modals.permanent_drivers_locked_modal;

    setModalInfo({
      heading: permanentDriversBlockedModalContent.heading,
      body: permanentDriversBlockedModalContent.body,
      cancelText: permanentDriversBlockedModalContent.close_text,
    });
  };

  const replaceDriverHandler = (e: React.MouseEvent, indexOfNamedDriver: number): void => {
    dispatch({ type: RESET_ADD_A_DRIVER_STATE });
    updateReplaceDriver({
      replacedDriverRef: policy.namedDrivers[indexOfNamedDriver].driverRef,
    });
    handleAndTrackCtaClick(
      getFormPageRoute(DriverType.PERMANENT, FormPage.BeforeYouStart),
      'Body',
      `replaceDriver - ${named_drivers.replace_driver.display_text}`
    );
    navigate(getFormPageRoute(DriverType.PERMANENT, FormPage.BeforeYouStart));
  };

  const showStaffUpdateBlockedModal = (onContinue: () => void): void => {
    setModalInfo(createStaffUpdateBlockedModalContent(onContinue, DriverType.PERMANENT));
  };

  return (
    <div>
      {named_drivers.additional_info.show_additional_info && (
        <RichTextWithMargin
          html={named_drivers.additional_info.text}
          data-cy="AdditionalInfoText"
        />
      )}
      {policy.namedDrivers.map((driver, index) => (
        <StyledExpandableCard
          data-cy={`ExpandableCard${index}`}
          key={driver.fullName}
          title={getPermanentDriverTitle(
            driver,
            getReplacedDriver(driver),
            named_drivers.replacement_driver_suffix,
            driver.hirer ? named_drivers.hirer : undefined
          )}
          onExpand={trackAccordionExpandClick('Policy', `Named drivers - ${driver.fullName}`)}
          onCollapse={trackAccordionCollapseClick('Policy', `Named drivers - ${driver.fullName}`)}
          idSuffix={index.toString()}>
          <StyledTable>
            <StyledTrWithNoTopPadding>
              <StyledTh>
                <RowTitle>
                  <b>{named_drivers.date_of_birth}</b>
                </RowTitle>
              </StyledTh>
              <StyledTd>
                <RowValueText>
                  {driver.dateOfBirth ? formatLongDateWithDayFirst(driver.dateOfBirth) : ''}
                </RowValueText>
              </StyledTd>
            </StyledTrWithNoTopPadding>
            <StyledTrWithNoTopPadding>
              <StyledTh>
                <RowTitle>
                  <TitleWithMargin>{named_drivers.business_use.heading}</TitleWithMargin>
                  <Tooltip
                    body={named_drivers.business_use.tooltip.body}
                    buttonText={named_drivers.business_use.tooltip.button_text}
                    onExpand={trackTooltipClick(
                      'Expand',
                      named_drivers.business_use.tooltip.button_text.open,
                      named_drivers.business_use.heading
                    )}
                    onCollapse={trackTooltipClick(
                      'Collapse',
                      named_drivers.business_use.tooltip.button_text.close,
                      named_drivers.business_use.heading
                    )}
                  />
                </RowTitle>
              </StyledTh>
              <StyledTd>
                <RowValueText>{driver.businessUse ? 'Yes' : 'No'}</RowValueText>
              </StyledTd>
            </StyledTrWithNoTopPadding>
          </StyledTable>
          {showModifyDriverButtons && (
            <ButtonContainer>
              <StyledTertiaryButton
                screenReaderText={named_drivers.replace_driver.alt_text}
                data-cy={`ReplaceDriverButton${index}`}
                onClick={(e) => {
                  permanentDriverActionHandler(
                    () => showPermanentDriversLockedModal(),
                    () => replaceDriverHandler(e, index),
                    showStaffUpdateBlockedModal
                  );
                  trackNamedDriverFormStart('Replace driver');
                }}>
                <IconWithRightMargin name="switch" size="small" color={colors.core01} />
                {named_drivers.replace_driver.display_text}
              </StyledTertiaryButton>
              <RedStyledTertiaryButton
                onClick={() => {
                  permanentDriverActionHandler(
                    () => showPermanentDriversLockedModal(),
                    () => showConfirmRemoveDriverModal(index),
                    showStaffUpdateBlockedModal
                  );
                  trackNamedDriverFormStart('Remove driver');
                }}
                data-cy={`RemoveDriverButton${index}`}>
                <IconWithRightMargin name="remove" size="small" color={colors.notificationError} />
                {named_drivers.remove_driver.display_text}
              </RedStyledTertiaryButton>
            </ButtonContainer>
          )}
        </StyledExpandableCard>
      ))}
      {showModifyDriverButtons &&
        (addDriverHelper.hasMaxNamedDrivers ? (
          <RichTextWithTopPadding html={named_drivers.maximum_drivers_replacement_text} />
        ) : (
          <TertiaryButtonWithTopMargin
            onClick={() => {
              permanentDriverActionHandler(
                () => showPermanentDriversLockedModal(),
                () => {
                  dispatch({ type: RESET_ADD_A_DRIVER_STATE });
                  updateReplaceDriver({
                    replacedDriverRef: '',
                  });
                  trackNamedDriverFormStart('Add a driver');
                  handleAndTrackCtaClick(
                    getFormPageRoute(DriverType.PERMANENT, FormPage.BeforeYouStart),
                    'Body',
                    `addDriver - ${named_drivers.add_driver_button.display_text}`
                  );
                  navigate(getFormPageRoute(DriverType.PERMANENT, FormPage.BeforeYouStart));
                },
                showStaffUpdateBlockedModal
              );
            }}
            data-cy="AddDriverButton"
            screenReaderText={named_drivers.add_driver_button.alt_text}>
            <IconWithRightMargin name="add" size="small" color={colors.core01} />
            {named_drivers.add_driver_button.display_text}
          </TertiaryButtonWithTopMargin>
        ))}
      {modalInfo && (
        <Modal aria-labelledby="modal-heading">
          <ModalContainer>
            <ModalHeading id="modal-heading">{modalInfo.heading}</ModalHeading>
            <RichText html={modalInfo.body} data-cy="NamedDriverModalBody" />
            <ModalButtonContainer>
              {modalInfo.cancelText && (
                <CancelButton onClick={() => setModalInfo(null)} data-cy="NamedDriverModalCancel">
                  {modalInfo.cancelText}
                </CancelButton>
              )}
              {modalInfo.confirmButton?.displayText && (
                <ConfirmButton
                  onClick={(event: React.MouseEvent) => {
                    setModalInfo(null);
                    modalInfo.confirmButton?.onClick?.(event);
                  }}
                  data-cy="NamedDriverModalConfirmButton">
                  {modalInfo.confirmButton.displayText}
                  <IconWithLeftMargin name="chevron" size="x-small" color={colors.core01} />
                </ConfirmButton>
              )}
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}
    </div>
  );
};

export default React.memo(NamedDriversSection);
