import {
  RowTitle,
  RowValueText,
  StyledTd,
  StyledTh,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import { graphql } from 'gatsby';
import React from 'react';
import { usePolicyState } from 'state/policy/state';
import Table from '../Table';
import { StyledTrWithVerticalAlign } from '../styles';
import { VehicleDetailsProps } from '../types';

export const query = graphql`
  fragment VehicleDetailsPolicyAccordion on cs__policy_details_accordion_accordion_entries {
    vehicle_details {
      heading
      initially_display
      make_and_model
      make_and_model_placeholder
      registration_number
    }
  }
`;

const VehicleDetailsTable: React.FC<VehicleDetailsProps> = ({ vehicle_details }) => {
  const { policy } = usePolicyState();

  if (!policy) {
    return null;
  }

  return (
    <Table>
      {(policy.vehicleDetails.makeModel || vehicle_details.make_and_model_placeholder) && (
        <StyledTrWithVerticalAlign>
          <StyledTh>
            <RowTitle>
              <b>{vehicle_details.make_and_model}</b>
            </RowTitle>
          </StyledTh>
          <StyledTd>
            <RowValueText>
              {policy.vehicleDetails.makeModel || vehicle_details.make_and_model_placeholder}
            </RowValueText>
          </StyledTd>
        </StyledTrWithVerticalAlign>
      )}
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <b>{vehicle_details.registration_number}</b>
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>{policy.vehicleDetails.registrationNumber}</RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
    </Table>
  );
};

export default React.memo(VehicleDetailsTable);
