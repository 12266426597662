import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { ClaimsHistoryAction, UPDATE_CLAIMS_HISTORY } from './action';
import { ClaimsHistoryState, initialClaimsHistory } from './state';

export const claimsHistoryReducer = (
  claimsHistory: ClaimsHistoryState = initialClaimsHistory,
  action: ClaimsHistoryAction
): ClaimsHistoryState => {
  switch (action.type) {
    case UPDATE_CLAIMS_HISTORY:
      return {
        ...claimsHistory,
        ...action.update,
      };
    case RESET_STATE:
      return initialClaimsHistory;
    case RESET_ADD_A_DRIVER_STATE:
      return initialClaimsHistory;
    default:
      return claimsHistory;
  }
};
