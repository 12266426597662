import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';
import { Logo, StyledGridItem } from './styles';

type HeaderData = {
  csAccountHeader: CsAccountHeader;
};

type CsAccountHeader = {
  logo: {
    desktop: { image: CsAsset };
  };
};

const headerQuery = graphql`
  query {
    csAccountHeader {
      logo {
        desktop {
          image {
            ...CsAsset
          }
        }
      }
    }
  }
`;

const SignedOutHeader: React.FC = () => {
  const { csAccountHeader } = useStaticQuery<HeaderData>(headerQuery);

  const processedImage = processImageAsset(csAccountHeader.logo.desktop.image);

  /* istanbul ignore if */
  if (!processedImage) {
    throw new Error('Image missing for signed out header!');
  }

  return (
    <header aria-label="main-header">
      <GridContainer>
        <Grid alignLeft>
          <StyledGridItem>
            <Logo logoLink={{ image: processedImage }} />
          </StyledGridItem>
        </Grid>
      </GridContainer>
      <FullWidthDivider />
    </header>
  );
};
export default SignedOutHeader;
