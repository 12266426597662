import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import PolicyCard from '@rsa-digital/evo-shared-components/components/PolicyCard';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import React from 'react';
import { usePolicyState } from 'state/policy/state';
import { processMandatoryCta, unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { CsCta, Icon } from 'types/contentStack';
import { SectionHeading, StyledGrid, TextBlock, TextRow, TextTitle, TextValue } from './styles';

type PolicyCardProps = {
  policy_card: {
    heading: string;
    cta: [CsCta];
    policy_number_text: string;
    renewal_date_text: string;
    icon: [Icon];
  };
};

export const query = graphql`
  fragment PolicyCardBlockAccountArea on cs__account_area_page_blocks {
    policy_card {
      heading
      policy_number_text
      renewal_date_text
      cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
      icon {
        icon_code
      }
    }
  }
`;

const PolicyCardBlock: React.FC<PolicyCardProps> = ({ policy_card }) => {
  const { policy } = usePolicyState();

  const iconCode = unwrapSingleton(policy_card.icon)?.icon_code;
  /* istanbul ignore if */
  if (iconCode === undefined) {
    nonFatalBuildError('Icon missing', 'Icon missing for Policy Card');
  }

  return (
    <StyledGrid>
      <GridItem desktop={10} tabletLandscape={10} data-cy="MyPolicies">
        <SectionHeading id="my-policies-section-heading">{policy_card.heading}</SectionHeading>
        {policy?.policyDetails.policyNumber && (
          <PolicyCard
            icon={iconCode ?? ''}
            cta={processMandatoryCta(policy_card.cta, 'Body')}
            summaryAndRegistration={{
              registration: policy.vehicleDetails.registrationNumber ?? '',
              summary: policy.vehicleDetails.makeModel ?? '',
            }}
            data-cy="PolicyCard">
            <TextBlock>
              <TextRow>
                <TextTitle>{policy_card.policy_number_text}</TextTitle>
                <TextValue>{policy.policyDetails.policyNumber}</TextValue>
              </TextRow>
              <TextRow>
                <TextTitle>{policy_card.renewal_date_text}</TextTitle>
                <TextValue>
                  {policy.policyDetails.renewalDate
                    ? formatLongDateWithDayFirst(new Date(policy.policyDetails.renewalDate))
                    : ''}
                </TextValue>
              </TextRow>
            </TextBlock>
          </PolicyCard>
        )}
      </GridItem>
    </StyledGrid>
  );
};

export default PolicyCardBlock;
