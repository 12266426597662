import { NamedDriver, TemporaryDriver } from 'state/policy/state';
import { TemporaryDriversData } from 'components/blocks/PolicyAccordion/types';
import { CsPlaceholders, replacePlaceholdersRichText } from './replaceCsPlaceholders';

const accountTemporaryDriverPlaceholders: CsPlaceholders<{
  driver: TemporaryDriver;
  replacedDriver?: NamedDriver;
}> = {
  driver: {
    getSubstitutionText: ({ driver }): string => driver.fullName ?? '',
    isPii: true,
  },
  replacedDriver: {
    getSubstitutionText: ({ replacedDriver }): string => replacedDriver?.fullName ?? '',
    isPii: true,
  },
};

const replaceAccountTemporaryDriverPlaceholders = (
  driver: TemporaryDriver,
  replacedDriver: NamedDriver | undefined,
  temporary_drivers: TemporaryDriversData
): TemporaryDriversData => {
  const replaceRichTextAccountTemporaryDriverPlaceholders = replacePlaceholdersRichText(
    accountTemporaryDriverPlaceholders,
    {
      driver,
      replacedDriver,
    }
  );

  return {
    ...temporary_drivers,
    modals: {
      remove_driver_confirmation_modal: {
        ...temporary_drivers.modals.remove_driver_confirmation_modal,
        no_driver_replaced_body: replaceRichTextAccountTemporaryDriverPlaceholders(
          temporary_drivers.modals.remove_driver_confirmation_modal.no_driver_replaced_body
        ),
        driver_replaced_body: replaceRichTextAccountTemporaryDriverPlaceholders(
          temporary_drivers.modals.remove_driver_confirmation_modal.driver_replaced_body
        ),
      },
      remove_driver_success_modal: {
        ...temporary_drivers.modals.remove_driver_success_modal,
        no_driver_replaced_body: replaceRichTextAccountTemporaryDriverPlaceholders(
          temporary_drivers.modals.remove_driver_success_modal.no_driver_replaced_body
        ),
        driver_replaced_body: replaceRichTextAccountTemporaryDriverPlaceholders(
          temporary_drivers.modals.remove_driver_success_modal.driver_replaced_body
        ),
      },
    },
  };
};

export default replaceAccountTemporaryDriverPlaceholders;
