import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { DoubleHeaderProps } from '@rsa-digital/evo-shared-components/components/Header/DoubleHeader';
import { PrimaryLink } from '@rsa-digital/evo-shared-components/components/Header/types';
import { graphql, useStaticQuery } from 'gatsby';
import { processImageAsset, unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { trackMainNavigationClick } from 'helpers/eventTracking';
import {
  handleAndTrackCtaClick,
  handleAndTrackLinkClick,
  handleLinkClick,
} from 'helpers/navigation';
import { CsAsset, Icon } from 'types/contentStack';

type HeaderData = {
  csAccountHeader: CsAccountHeader;
};

type CsAccountHeader = {
  logo: {
    default: { image: CsAsset; link_screen_reader_text: string; url: string };
    desktop: { image: CsAsset; link_screen_reader_text: string; url: string };
  };
  primary_nav_items: {
    primary_link: CsAccountHeaderLinkNavItem;
  }[];
  tertiary_nav_item: {
    text: string;
    url: string;
    open_in_new_tab: boolean;
    icon: [Icon];
  };
  mobile_header_text: string;
};

type CsAccountHeaderLinkNavItem = {
  text: string;
  url: string;
  open_in_new_tab: boolean;
};

const headerQuery = graphql`
  query {
    csAccountHeader {
      logo {
        default {
          image {
            ...CsAsset
          }
          link_screen_reader_text
          url
        }
        desktop {
          image {
            ...CsAsset
          }
          link_screen_reader_text
          url
        }
      }
      primary_nav_items {
        primary_link {
          text
          url
          open_in_new_tab
        }
      }
      tertiary_nav_item {
        text
        url
        open_in_new_tab
        icon {
          icon_code
        }
      }
      mobile_header_text
    }
  }
`;

const mapDefaultLogo = (csAccountHeader: CsAccountHeader): NonTextLink => ({
  url: csAccountHeader.logo.default.url,
  screenReaderText: csAccountHeader.logo.default.link_screen_reader_text,
  onClick: handleAndTrackLinkClick(
    csAccountHeader.logo.default.url,
    'homeLogo',
    csAccountHeader.logo.default.link_screen_reader_text
  ),
});

const mapDesktopLogo = (csAccountHeader: CsAccountHeader): NonTextLink => ({
  url: csAccountHeader.logo.desktop.url,
  screenReaderText: csAccountHeader.logo.desktop.link_screen_reader_text,
  onClick: handleAndTrackLinkClick(
    csAccountHeader.logo.desktop.url,
    'homeLogo',
    csAccountHeader.logo.desktop.link_screen_reader_text
  ),
});

const mapPrimaryNavItems = (csAccountHeader: CsAccountHeader): PrimaryLink[] =>
  csAccountHeader.primary_nav_items.map((navItem) => ({
    name: navItem.primary_link.text,
    url: navItem.primary_link.url,
    openInNewTab: navItem.primary_link.open_in_new_tab,
    onClick: (event) => {
      trackMainNavigationClick(navItem.primary_link.text, 'Click')();
      handleLinkClick(navItem.primary_link.url)(event);
    },
  }));

export const useHeaderData = (): DoubleHeaderProps => {
  const { csAccountHeader } = useStaticQuery<HeaderData>(headerQuery);

  const defaultLogo = processImageAsset(csAccountHeader.logo.default.image);
  /* istanbul ignore if */
  if (!defaultLogo) {
    throw new Error('Header default logo missing!');
  }

  const desktopLogo = processImageAsset(csAccountHeader.logo.desktop.image);
  /* istanbul ignore if */
  if (!desktopLogo) {
    throw new Error('Header desktop logo missing!');
  }

  const iconName = unwrapSingleton(csAccountHeader.tertiary_nav_item.icon)?.icon_code;
  /* istanbul ignore if */
  if (iconName === undefined) {
    nonFatalBuildError('Icon missing', 'Icon missing for Account Header');
  }

  return {
    defaultLogoLink: {
      image: defaultLogo,
      link: mapDefaultLogo(csAccountHeader),
    },
    desktopLogoLink: {
      image: desktopLogo,
      link: mapDesktopLogo(csAccountHeader),
    },
    primaryNav: {
      navItems: mapPrimaryNavItems(csAccountHeader),
    },
    tertiaryNavItem: {
      name: csAccountHeader.tertiary_nav_item.text,
      url: csAccountHeader.tertiary_nav_item.url,
      openInNewTab: csAccountHeader.tertiary_nav_item.open_in_new_tab,
      onClick: handleAndTrackCtaClick(
        csAccountHeader.tertiary_nav_item.url,
        'Header',
        csAccountHeader.tertiary_nav_item.text
      ),
      icon: iconName
        ? {
            name: iconName,
            size: 'medium',
          }
        : undefined,
    },
    mobileMenuOnOpen: trackMainNavigationClick('Mobile menu toggle', 'Open'),
    mobileMenuOnClose: trackMainNavigationClick('Mobile menu toggle', 'Close'),
    mobileHeaderText: csAccountHeader.mobile_header_text,
  };
};
