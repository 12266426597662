export const InvalidInsuranceHistory = 'EVO_VAL_MO010';

export const DriverTooYoung = 'EVO_VAL_MO020';
export const MaximumYoungDrivers = 'EVO_VAL_MO021';
export const YoungDriverNotAtPolicyAddress = 'EVO_VAL_MO022';
export const YoungDriverInvalidInsuranceGroup = 'EVO_VAL_MO023';
export const YoungDriverInvalidBhp = 'EVO_VAL_MO024';

export const NoValidLicenceError = 'EVO_VAL_MO030';
export const ForeignLicenceError = 'EVO_VAL_MO031';
export const ProvisionalLicenceLimitError = 'EVO_VAL_MO032';
export const UnderMinAgeProvisionalLicenceError = 'EVO_VAL_MO033';

export const InvalidClaimsHistory = 'EVO_VAL_MO040';

export const InvalidConvictionsHistory = 'EVO_VAL_MO050';
export const BanLengthExceedsMaximum = 'EVO_VAL_MO051';
export const ConvictionsNeedsMoreInfo = 'EVO_VAL_MO052';

export const LicenceRestrictionsError = 'EVO_VAL_MO060';

export const InvalidTemporaryDriverCoverDates = 'EVO_VAL_MO070';

export const InvalidDriverForRemoval = 'EVO_VAL_MO080';
export const MinimumNamedDriver = 'EVO_VAL_MO081';
export const MinimumNamedFullLicenceDriver = 'EVO_VAL_MO082';

export const UpdateDriverTooManyDrivers = 'EVO_VAL_MO090';
export const PolicyUpdatesBlocked = 'EVO_VAL_MO091';

export const PolicySeatsFull = 'EVO_VAL_MO100';

export const DvlaLicenceCheckFailed = 'EVO_BUS_MO150';
export const DvlaLicenceCheckUnknown = 'EVO_BUS_MO151';

export const PolicyInvalidState = 'EVO_BUS_MO180';
