/* eslint-disable react/jsx-props-no-spreading */
import { DoubleHeader } from '@rsa-digital/evo-shared-components/components/Header';
import React from 'react';
import { useHeaderData } from './useHeaderData';

const StaffHeader: React.FC<{
  hideSearchButton?: boolean;
  toggleMenuCallback?: (open: boolean) => void;
  showStickyHeader?: boolean;
}> = ({ hideSearchButton, toggleMenuCallback, showStickyHeader }) => {
  const headerData = useHeaderData(hideSearchButton);

  return (
    <DoubleHeader
      {...headerData}
      toggleMenuCallback={toggleMenuCallback}
      isSticky={showStickyHeader}
      fontColourVariant
      data-cy="StaffHeader"
    />
  );
};

export default StaffHeader;
