export type ManagedPolicyState = {
  policyNumber: string | null;
};

// The use of (typeof window !== 'undefined' && window.Cypress && window.initialPolicyState)
// allows for pre-populating policy state during testing
// The property initialPolicyState in window is targeted during 'cy.visit -> onBeforeLoad'
export const initialManagedPolicyState = (typeof window !== 'undefined' &&
  window.Cypress &&
  window.initialManagedPolicyState) || { policyNumber: null };
