import { NamedDriver, TemporaryDriver } from 'state/policy/state';
import { CsPlaceholders, replacePlaceholdersPlainText } from './placeholders/replaceCsPlaceholders';

export const getTemporaryDriverTitle = (
  tempDriver: TemporaryDriver,
  isCurrentTemp: boolean,
  replacedDriver: NamedDriver | undefined,
  replacedDriverSuffix: string,
  notYetActiveSuffix: string,
  willReplaceSuffix: string
): string => {
  const placeholder: CsPlaceholders<{ driverName: string | null }> = {
    replacedDriver: {
      getSubstitutionText: ({ driverName }) => driverName ?? '',
      isPii: true,
    },
  };

  // TODO MDP-395
  const replacePlaceholder = replacePlaceholdersPlainText(
    placeholder,
    {
      driverName: replacedDriver?.fullName ?? null,
    },
    true
  );

  const currentTempTitle = `${tempDriver.fullName}${
    replacedDriver ? ` ${replacePlaceholder(replacedDriverSuffix)}` : ''
  }`;

  const futureTempTitle = `${tempDriver.fullName} ${notYetActiveSuffix}${
    replacedDriver ? ` ${replacePlaceholder(willReplaceSuffix)}` : ''
  }`;

  return isCurrentTemp ? currentTempTitle : futureTempTitle;
};
