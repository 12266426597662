/* istanbul ignore file */
import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { FieldErrors } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import debounce from 'lodash/debounce';
import { LicenceType } from 'state/forms/licenceDetails/state';
import { version } from '../../package.json';

declare global {
  interface Window {
    dataLayer?: (TrackingEvent | CustomEvent)[];
  }
}

// Max length of a label to include
const MAX_LENGTH = 100;

export type TrackingEvent = {
  event: string;
  eventCategory: string | undefined;
  eventAction: string | undefined;
  eventLabel: string | undefined;
  vpvPath?: string;
  siteVersion?: string;
  isException?: boolean;
};

export type CustomEvent = Record<string, unknown>;

export type PageLocation = 'Header' | 'Body' | 'Footer' | 'Modal' | 'FormNavigationFooter';

export const trackEvent = (event: TrackingEvent | CustomEvent): void => {
  if (event.eventLabel) {
    // eslint-disable-next-line no-param-reassign
    event.eventLabel = (event as TrackingEvent).eventLabel?.substring(0, MAX_LENGTH);
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const trackNamedDriverFormStart = (action: string): void => {
  trackEvent({
    event: 'namedDriverStart',
    action,
  });
};

export type namedDriverFormContinueData = {
  formPage: string;
  formStep: string;
  correctAddress?: boolean;
  driversConsent?: boolean;
  isHirer?: boolean;
  isNominee?: boolean;
  refusedInsurance?: boolean;
  nameLength?: number;
  sameAddress?: boolean;
  validLicence?: boolean;
  licenceType?: LicenceType;
  datePassed?: DateValue;
  anyClaims?: boolean;
  claimsEntered?: number;
  claimTypes?: string[];
  claimBriefLengths?: number[];
  anyOffences?: boolean;
  offencesEntered?: number;
  offenceCodes?: string[];
  anyRestrictions?: boolean;
  licenceRestrictions?: string[];
};

export const trackNamedDriverFormContinue = (
  data: namedDriverFormContinueData,
  replacedDriverRef: string | null
): void => {
  trackEvent({
    event: 'namedDriverFormContinue',
    ...data,
    formName: replacedDriverRef === null ? 'Add driver' : 'Replace driver',
  });
};

export const trackNamedDriverClaimRemoved = (claimName: string, claimType: string): void => {
  trackEvent({
    event: 'namedDriverRemoveClaim',
    claimName,
    claimType,
  });
};

export const trackNamedDriverOffenceRemoved = (offenceName: string): void => {
  trackEvent({
    event: 'namedDriverRemoveOffence',
    offenceName,
  });
};

export const trackPageView = (path: string): void => {
  trackEvent({
    event: 'newPage',
    vpvPath: path,
    siteVersion: version,
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  });
};

export const trackButtonClick = (style: string, label: string) => (): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'Button',
    eventAction: 'Click',
    eventLabel: `${style} - ${label}`,
  });
};

export const trackCtaClick = (
  ctaText: string,
  exitsSite: boolean,
  location: PageLocation
) => (): void => {
  trackEvent({
    event: 'ctaClick',
    ctaText,
    exitLink: exitsSite,
    location,
  });
};

export const trackFooterClick = (label: string, linkUrl: string) => (): void => {
  trackEvent({
    event: 'footerClick',
    eventCategory: 'Footer Link',
    eventAction: 'Click',
    eventLabel: label,
    url: linkUrl,
  });
};

export const trackSocialClick = (
  linkText: string,
  linkUrl: string,
  interactionType: string
) => (): void => {
  trackEvent({
    event: 'socialClick',
    site: linkText,
    interactionType,
    linkUrl,
  });
};

export const trackAwardTabClick = (
  year: string,
  numberOfAwards: number,
  awards: string[]
) => (): void => {
  trackEvent({
    event: 'awardNavigation',
    year,
    numberOfAwards,
    awards,
  });
};

export const trackPaginationEvent = (
  numberOfPages: number,
  linkUsed: string,
  navigatedTo: number,
  navigatedFrom: number
) => (): void => {
  trackEvent({
    event: 'articleNavigation',
    numberOfPages,
    linkUsed,
    navigatedTo,
    navigatedFrom,
  });
};

export const trackDocumentDownloadClick = (
  documentName: string,
  fileUrl: string,
  documentSize: string,
  index: number
) => (): void => {
  const event: CustomEvent = {
    event: 'documentDownload',
    documentName,
    documentSize,
    index: `${index}`,
  };
  const fileExtensionMatch = fileUrl.match(/\.([a-zA-Z]*)$/);
  const fileExtension = fileExtensionMatch && fileExtensionMatch[1];
  if (fileExtension) {
    event.documentType = fileExtension;
  }
  trackEvent(event);
};

export const trackAlertNotification = (dynamicName: string) => (): void => {
  trackEvent({
    event: 'alertBannerAppear',
    eventCategory: 'Alert Banner',
    eventAction: 'Triggered',
    eventLabel: `Banner - ${dynamicName}`,
  });
};

export const trackModalOpen = (label: string) => (): void => {
  trackEvent({
    event: 'modalWindow',
    eventCategory: 'Modal',
    eventAction: 'Appear',
    eventLabel: label,
  });
};

export const trackFormErrors = (errors: FieldErrors): void => {
  trackEvent({
    event: 'formErrors',
    eventCategory: 'Form Field',
    eventAction: 'Error',
    eventLabel: Object.keys(errors).join(','),
  });
};

export const trackDropdownSelect = (label: string, value: string): void => {
  trackEvent({
    event: 'dropdownSelector',
    eventCategory: 'Selector Dropdown',
    eventAction: 'Select',
    eventLabel: `${label} - ${value}`,
  });
};

export const trackSelectOrTypeaheadFocus = (label: string) => (): void => {
  trackEvent({
    event: 'primarySelector',
    eventCategory: 'Selector Primary',
    eventAction: 'Select',
    eventLabel: label,
  });
};

// The typeahead library typically triggers onChange twice. We use debounce to ensure the
// event is only tracked once
export const trackTypeaheadFocusWithDebounce = (label: string): (() => void) =>
  debounce(trackSelectOrTypeaheadFocus(label), 100);

export const trackFieldError = (fieldName: string, errorName: string): void => {
  trackEvent({
    event: 'fieldError',
    isException: false,
    eventCategory: 'Form Field',
    eventAction: 'Error',
    eventLabel: `${fieldName} - ${errorName}`,
  });
};

export const trackTextInputFocus = (label: string) => (): void => {
  trackEvent({
    event: 'formField',
    eventCategory: 'Form Field',
    eventAction: 'Focus',
    eventLabel: label,
  });
};

export const trackTooltipClick = (
  eventAction: string,
  linkLabel: string,
  fieldLabel: string,
  form?: { formName: string; formPage: string; formStep: string }
) => (): void => {
  trackEvent({
    event: 'tooltipClick',
    eventCategory: 'Tooltip',
    eventAction,
    eventLabel: `${linkLabel} - ${fieldLabel}`,
    formName: form?.formName,
    formPage: form?.formPage,
    formStep: form?.formStep,
  });
};

type AccordionType = 'FAQ' | 'General' | 'Policy';

export const trackAccordionExpandClick = (
  type: AccordionType,
  tier1Item: string,
  tier2Item?: string
) => (): void => {
  trackEvent({
    event: 'accordionInteraction',
    accordionType: type,
    accordionTier1: tier1Item,
    accordionTier2: tier2Item,
    accordionAction: 'Open',
  });
};

export const trackAccordionCollapseClick = (
  type: AccordionType,
  tier1Item: string,
  tier2Item?: string
) => (): void => {
  trackEvent({
    event: 'accordionInteraction',
    accordionType: type,
    accordionTier1: tier1Item,
    accordionTier2: tier2Item,
    accordionAction: 'Close',
  });
};

export const trackMainNavigationClick = (eventLabel: string, eventAction: string) => (): void => {
  trackEvent({
    event: 'navigationClick',
    eventCategory: 'Main Navigation',
    eventAction: `${eventAction}`,
    eventLabel: `${eventLabel}`,
  });
};

export const trackSecondaryNavigationClick = (
  eventLabel: string,
  eventAction: string
) => (): void => {
  trackEvent({
    event: 'navigationClick',
    eventCategory: 'Secondary Navigation',
    eventAction: `${eventAction}`,
    eventLabel: `${eventLabel}`,
  });
};

export const trackRadioButtonClick = (label: string, value: string): void => {
  trackEvent({
    event: 'radioButtonClick',
    eventCategory: 'Radio Button',
    eventAction: 'Select',
    eventLabel: `${label} - ${value}`,
  });
};
