/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { useCallback } from 'react';
import { useErrorState } from 'state/error/actions';
import { getKnockoutFromResponse, isAxiosError } from 'helpers/axiosResponseHelper';
import useInvalidPolicyStateErrorHandler from './useInvalidPolicyStateErrorHandler';

const useAxiosErrorHandler = (): ((err: any) => void) => {
  const [, updateErrorState] = useErrorState();
  const invalidPolicyStateErrorHandler = useInvalidPolicyStateErrorHandler();

  return useCallback(
    (error: any): void => {
      try {
        invalidPolicyStateErrorHandler(error);
      } catch (err) {
        if (!isAxiosError(err)) {
          throw err;
        }

        if (err.response?.status === 401) {
          const redirectUrl = axios.getUri({
            url: `${process.env.GATSBY_API_URL}/authentication/login`,
            params: {
              redirectUrl: window.location.href,
            },
          });
          window.location.assign(redirectUrl);
          return;
        }

        const knockout = getKnockoutFromResponse(err.response?.data);
        if (knockout) {
          updateErrorState({
            hasErrored: true,
            knockout: knockout.Knockout,
          });
          return;
        }

        console.error('Error when making API request: ', err.message);

        updateErrorState({ hasErrored: true });
      }
    },
    [invalidPolicyStateErrorHandler, updateErrorState]
  );
};

export default useAxiosErrorHandler;
