import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import {
  KnockoutOverridesAction,
  REMOVE_KNOCKOUT_OVERRIDES,
  UPDATE_KNOCKOUT_OVERRIDES,
} from './action';
import { initialKnockoutOverrides, KnockoutOverridesState } from './state';

export const knockoutOverridesReducer = (
  knockoutOverrides: KnockoutOverridesState = initialKnockoutOverrides,
  action: KnockoutOverridesAction
): KnockoutOverridesState => {
  switch (action.type) {
    case UPDATE_KNOCKOUT_OVERRIDES:
      return {
        ...knockoutOverrides,
        ...action.update,
      };
    case REMOVE_KNOCKOUT_OVERRIDES:
      return {
        ...knockoutOverrides,
        overriddenKnockouts: knockoutOverrides.overriddenKnockouts.filter(
          (overridenKnockout) => !action.knockoutsToRemove.includes(overridenKnockout.knockoutCode)
        ),
      };
    case RESET_STATE:
      return initialKnockoutOverrides;
    case RESET_ADD_A_DRIVER_STATE:
      return initialKnockoutOverrides;
    default:
      return knockoutOverrides;
  }
};
