import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { usePolicyState } from 'state/policy/state';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import CoverNotesHistory from './CoverNotesHistory';
import { mapAccordionBlocks } from './accordionMapping';
import { AccordionWrapperBlock, Heading, HeadingsGridItem, StyledAccordion } from './styles';
import {
  PolicyAccordionBlockData,
  PolicyAccordionBlockProps,
  PolicyAccordionTemporaryDriverDetailsBlockData,
} from './types';

export const query = graphql`
  fragment PolicyAccordionBlockAccountArea on cs__account_area_page_blocks {
    show_policy_accordion {
      show_policy_accordion
    }
  }
`;

const policyAccordionQuery = graphql`
  query {
    csPolicyDetailsAccordion {
      heading
      rich_text
      accordion_entries {
        ...PolicyDetailsPolicyAccordion
        ...VehicleDetailsPolicyAccordion
        ...PersonalDetailsPolicyAccordion
        ...NamedDriversPolicyAccordion
        ...PolicyDocumentsPolicyAccordion
        temporary_drivers {
          show_temporary_drivers_content_here
        }
        cover_notes {
          show_cover_notes_content_here
        }
      }
    }
    csPolicyDetailsAccordionTemporaryDriversDetails {
      ...TemporaryDriversPolicyAccordion
      ...CoverNotesPolicyAccordion
    }
  }
`;

const PolicyAccordionBlock: React.FC<PolicyAccordionBlockProps> = ({
  show_policy_accordion: { show_policy_accordion },
  initiallyDisplayEntries = false,
}) => {
  const {
    csPolicyDetailsAccordion,
    csPolicyDetailsAccordionTemporaryDriversDetails,
  } = useStaticQuery<PolicyAccordionBlockData & PolicyAccordionTemporaryDriverDetailsBlockData>(
    policyAccordionQuery
  );
  const policy = usePolicyState();
  const baseAccordionEntries = mapAccordionBlocks(
    csPolicyDetailsAccordion.accordion_entries,
    csPolicyDetailsAccordionTemporaryDriversDetails,
    initiallyDisplayEntries,
    !(policy.policy?.temporaryDrivers && policy.policy.temporaryDrivers.length > 0)
  );
  const accordionEntries = policy.coverNotesHistory
    ? [
        ...baseAccordionEntries,
        {
          title:
            csPolicyDetailsAccordionTemporaryDriversDetails.cover_notes.cover_notes_history.heading,
          content: (
            <CoverNotesHistory
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...csPolicyDetailsAccordionTemporaryDriversDetails}
              coverNotes={policy.coverNotesHistory}
            />
          ),
          onExpand: trackAccordionExpandClick(
            'General',
            csPolicyDetailsAccordionTemporaryDriversDetails.cover_notes.cover_notes_history.heading
          ),
          onCollapse: trackAccordionCollapseClick(
            'General',
            csPolicyDetailsAccordionTemporaryDriversDetails.cover_notes.cover_notes_history.heading
          ),
          initiallyDisplay: initiallyDisplayEntries,
        },
      ]
    : baseAccordionEntries;
  return show_policy_accordion ? (
    <AccordionWrapperBlock data-cy="PolicyAccordion">
      {csPolicyDetailsAccordion.heading && (
        <Grid>
          <HeadingsGridItem desktop={10} tabletLandscape={8}>
            <Heading>{csPolicyDetailsAccordion.heading}</Heading>
            {csPolicyDetailsAccordion.rich_text && (
              <RichText html={csPolicyDetailsAccordion.rich_text} />
            )}
          </HeadingsGridItem>
        </Grid>
      )}
      <StyledAccordion
        accordionEntries={accordionEntries}
        initiallyDisplayEntries={initiallyDisplayEntries}
      />
    </AccordionWrapperBlock>
  ) : (
    <></>
  );
};

export default React.memo(PolicyAccordionBlock);
