import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { useUserState } from 'state/user/state';

const userPolicyNumberSelector = (state: RootState): string =>
  state.policy.policy?.policyDetails?.policyNumber ?? '';

const managedPolicyNumberSelector = (state: RootState): string =>
  state.managedPolicy.policyNumber ?? '';

const usePolicyNumber = (): string => {
  const userSession = useUserState();

  const selector = userSession.isStaffMember
    ? managedPolicyNumberSelector
    : userPolicyNumberSelector;

  return useSelector(selector);
};

export default usePolicyNumber;
