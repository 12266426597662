import { EvenlySplitInfoCard } from '@rsa-digital/evo-shared-components/components/SplitInfoCard';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processOptionalCta, unwrapSingleton } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta, Icon } from 'types/contentStack';

type InfoCardData = {
  header_icon: [Icon] | [] | null;
  header_text: string;
  image: CsAsset | null;
  body_text: string;
  cta: [CsCta] | null;
};

type SplitInfoCardBlockProps = {
  split_info_card: {
    left_side: InfoCardData;
    right_side: InfoCardData;
  };
};

export const query = graphql`
  fragment SplitInfoCardBlockInfo on cs__info_page_blocks {
    split_info_card {
      left_side {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
      right_side {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const SplitInfoCardBlock: React.FC<SplitInfoCardBlockProps> = ({ split_info_card }) => (
  <EvenlySplitInfoCard
    infoCards={[split_info_card.left_side, split_info_card.right_side].map(
      ({ header_text, header_icon, body_text, cta, image }) => {
        return {
          heading: header_text,
          headerIcon: (header_icon && unwrapSingleton(header_icon)?.icon_code) ?? undefined,
          body: body_text,
          primaryCta: processOptionalCta(cta, 'Body'),
          logo: processImageAsset(image),
        };
      }
    )}
    data-cy="SplitInfoCard"
  />
);

export default React.memo(SplitInfoCardBlock);
