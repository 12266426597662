import { DocumentsAction, UPDATE_DOCUMENTS } from './actions';
import { DocumentsState, initialDocumentsState } from './state';

export const documentsReducer = (
  documents: DocumentsState = initialDocumentsState,
  action: DocumentsAction
): DocumentsState => {
  switch (action.type) {
    case UPDATE_DOCUMENTS:
      return action.documents;
    default:
      return documents;
  }
};
