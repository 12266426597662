import { graphql } from 'gatsby';
import React from 'react';
import InfoCards, { InfoCardsCsContent } from 'components/InfoCards';

type InforCardsBlockProps = InfoCardsCsContent;

export const query = graphql`
  fragment InfoCardsBlockInfo on cs__info_page_blocks {
    info_cards {
      info_cards {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
  fragment InfoCardsBlockError on cs__error_page_blocks {
    info_cards {
      info_cards {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const InfoCardsBlock: React.FC<InforCardsBlockProps> = ({ info_cards: { info_cards } }) => (
  <InfoCards cards={info_cards} />
);

export default React.memo(InfoCardsBlock);
