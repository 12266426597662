import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { initialDateValue } from '../shared/dateValue';

export type ClaimsHistoryState = {
  addClaimsHistory: boolean | undefined;
  claims: ClaimWithKey[];
};

export const initialClaimsHistory: ClaimsHistoryState = {
  addClaimsHistory: undefined,
  claims: [],
};

export type Claim = {
  incidentDate: DateValue;
  incidentType: string;
  incidentDetails: string;
};

export type ClaimWithKey = Claim & {
  key: number;
};

export const initialClaim: Claim = {
  incidentDate: initialDateValue,
  incidentType: '',
  incidentDetails: '',
};
