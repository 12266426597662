import { AccordionEntry } from '@rsa-digital/evo-shared-components/components/Accordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import React from 'react';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import { AccordionWrapperBlock, Heading, HeadingsGridItem, StyledAccordion } from './styles';

type BasicAccountAccordionBlockProps = {
  basic_account_accordion: {
    heading: string;
    rich_text: string;
    accordion_entries: {
      entry_title: string;
      entry_content: string;
    }[];
  };
};

export const query = graphql`
  fragment BasicAccountAccordionBlockAccountArea on cs__account_area_page_blocks {
    basic_account_accordion {
      heading
      rich_text
      accordion_entries {
        entry_title
        entry_content
      }
    }
  }
`;

const BasicAccountAccordionBlock: React.FC<BasicAccountAccordionBlockProps> = ({
  basic_account_accordion,
}) => {
  const convertedEntries = basic_account_accordion.accordion_entries.map(
    (entry): AccordionEntry => ({
      title: entry.entry_title,
      content: <RichText html={entry.entry_content} />,
      onExpand: trackAccordionExpandClick('General', entry.entry_title),
      onCollapse: trackAccordionCollapseClick('General', entry.entry_title),
    })
  );

  return (
    <AccordionWrapperBlock data-cy="BasicAccountAccordion">
      <Grid>
        <HeadingsGridItem desktop={10} tabletLandscape={8}>
          {basic_account_accordion.heading && (
            <>
              <Heading>{basic_account_accordion.heading}</Heading>
              {basic_account_accordion.rich_text && (
                <RichText html={basic_account_accordion.rich_text} />
              )}
            </>
          )}
          <StyledAccordion accordionEntries={convertedEntries} initiallyDisplayEntries={false} />
        </HeadingsGridItem>
      </Grid>
    </AccordionWrapperBlock>
  );
};

export default React.memo(BasicAccountAccordionBlock);
