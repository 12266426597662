import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { DriverDetailsAction, UPDATE_DRIVER_DETAILS } from './action';
import { DriverDetailsState, initialDriverDetailsState } from './state';

export const driverDetailsReducer = (
  driverDetailsState: DriverDetailsState = initialDriverDetailsState,
  action: DriverDetailsAction
): DriverDetailsState => {
  switch (action.type) {
    case UPDATE_DRIVER_DETAILS:
      return {
        ...driverDetailsState,
        ...action.update,
      };
    case RESET_STATE:
      return initialDriverDetailsState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialDriverDetailsState;
    default:
      return driverDetailsState;
  }
};
