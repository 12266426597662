import { Knockout } from 'state/error/state';
import { DriverType } from 'types/forms';

const ADD_DRIVER_ROUTE = '/add-driver';
const KNOCKOUT_ROUTE = '/referral';

// See docs/forms.md for an overview of this form's structure
export enum FormPage {
  BeforeYouStart = 'BeforeYouStart',
  ConfirmAddress = 'ConfirmAddress',
  DriverConsent = 'DriverConsent',
  NewDriversCover = 'NewDriversCover',
  DriverToReplace = 'DriverToReplace',
  AboutTheDriver = 'AboutTheDriver',
  DriverDetails = 'DriverDetails',
  LicenceDetails = 'LicenceDetails',
  ClaimsHistory = 'ClaimsHistory',
  DrivingOffences = 'DrivingOffences',
  LicenceRestrictions = 'LicenceRestrictions',
  PermanentDriverAgreement = 'PermanentDriverAgreement',
  Summary = 'Summary',
  HirerAgreement = 'HirerAgreement',
  Confirmation = 'Confirmation',
}

export const formPageRoutes: { [p in FormPage]: string } = {
  [FormPage.BeforeYouStart]: 'before-you-start',
  [FormPage.ConfirmAddress]: 'confirm-address',
  [FormPage.DriverConsent]: 'driver-consent',
  [FormPage.NewDriversCover]: 'new-drivers-cover',
  [FormPage.DriverToReplace]: 'driver-to-replace',
  [FormPage.AboutTheDriver]: 'about-the-driver',
  [FormPage.DriverDetails]: 'driver-details',
  [FormPage.LicenceDetails]: 'licence-details',
  [FormPage.ClaimsHistory]: 'claims-history',
  [FormPage.DrivingOffences]: 'driving-offences',
  [FormPage.LicenceRestrictions]: 'licence-restrictions',
  [FormPage.PermanentDriverAgreement]: 'permanent-driver-agreement',
  [FormPage.Summary]: 'summary',
  [FormPage.HirerAgreement]: 'hirer-agreement',
  [FormPage.Confirmation]: 'confirmation',
};

export const getFormPageRoute = (driverType: DriverType, formPage: FormPage): string =>
  `${ADD_DRIVER_ROUTE}/${driverType.toLowerCase()}/${formPageRoutes[formPage]}`;

const KnockoutPage: { [k in Knockout]: string } = {
  [Knockout.HIRER_ADDRESS_INCORRECT]: 'address',
  [Knockout.DRIVER_UNDER_SIXTEEN]: 'age-restrictions',
  [Knockout.DRIVER_MAX_UNDER_TWENTY_ONE_LIMIT]: 'young-driver-limit',
  [Knockout.DRIVER_UNDER_TWENTY_ONE_DIFFERENT_ADDRESS]: 'young-address',
  [Knockout.DRIVER_UNDER_TWENTY_FIVE_INVALID_INSURANCE]: 'vehicle-restrictions',
  [Knockout.DRIVER_UNDER_TWENTY_FIVE_INVALID_VEHICLE_BHP]: 'bhp',
  [Knockout.CONVICTION_HISTORY]: 'conviction-history',
  [Knockout.CONVICTION_NEED_MORE_INFO]: 'conviction-history-info',
  [Knockout.INSURANCE_HISTORY]: 'insurance-history',
  [Knockout.DRIVER_DOES_NOT_HAVE_VALID_LICENCE]: 'licence',
  [Knockout.DRIVER_UNDER_TWENTY_ONE_PROVISIONAL_LICENCE]: 'young-provisional',
  [Knockout.DRIVER_WITH_FOREIGN_LICENCE]: 'foreign-licence',
  [Knockout.PROVISIONAL_LIMIT_REACHED]: 'provisional',
  [Knockout.PERMANENT_DRIVER_REJECTED_AGREEMENT_TERMS]: 'driver-terms',
  [Knockout.PERMANENT_HIRER_DRIVER_REJECTED_AGREEMENT_TERMS]: 'driver-and-hirer-terms',
  [Knockout.HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS]: 'hirer-terms',
  [Knockout.CLAIMS_HISTORY]: 'claims',
  [Knockout.LICENCE_RESTRICTION]: 'licence-restrictions',
  [Knockout.TEMPORARY_DRIVER_COVER_LENGTH]: 'cover-length',
  [Knockout.BAN_HISTORY]: 'ban-history',
  [Knockout.POLICY_UPDATES_BLOCKED]: 'policy-updates-unavailable',
  [Knockout.UPDATE_DRIVER_TOO_MANY_DRIVERS]: 'driver-limit',
  [Knockout.DVLA_LICENCE_CHECK_FAILURE]: 'licence-check',
  [Knockout.DVLA_LICENCE_CHECK_UNKNOWN]: 'licence-check-unknown',
  [Knockout.REMOVING_ONLY_FULL_LICENCE_HOLDER]: 'minimum-full-licence-holder',
  [Knockout.POLICY_SEATS_FULL]: 'temporary-drivers-full',
};

export const getKnockoutRoute = (driverType: DriverType, knockout: Knockout): string =>
  `${KNOCKOUT_ROUTE}/${driverType.toLowerCase()}/${KnockoutPage[knockout]}`;
