export const RESET_STATE = 'RESET_STATE';

export type ResetStateAction = {
  type: typeof RESET_STATE;
};

export const RESET_ADD_A_DRIVER_STATE = 'RESET_ADD_A_DRIVER_STATE';

export type ResetAddADriverStateAction = {
  type: typeof RESET_ADD_A_DRIVER_STATE;
};
