import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { AboutTheDriverAction, UPDATE_ABOUT_THE_DRIVER } from './action';
import { AboutTheDriverState, initialAboutTheDriverState } from './state';

export const aboutTheDriverReducer = (
  aboutTheDriverState: AboutTheDriverState = initialAboutTheDriverState,
  action: AboutTheDriverAction
): AboutTheDriverState => {
  switch (action.type) {
    case UPDATE_ABOUT_THE_DRIVER:
      return {
        ...aboutTheDriverState,
        ...action.update,
      };
    case RESET_STATE:
      return initialAboutTheDriverState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialAboutTheDriverState;
    default:
      return aboutTheDriverState;
  }
};
