import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { ConfirmAddressAction, UPDATE_CONFIRM_ADDRESS } from './action';
import { ConfirmAddressState, initialConfirmAddressState } from './state';

export const confirmAddressReducer = (
  confirmAddressState: ConfirmAddressState = initialConfirmAddressState,
  action: ConfirmAddressAction
): ConfirmAddressState => {
  switch (action.type) {
    case UPDATE_CONFIRM_ADDRESS:
      return {
        ...confirmAddressState,
        ...action.update,
      };
    case RESET_STATE:
      return initialConfirmAddressState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialConfirmAddressState;
    default:
      return confirmAddressState;
  }
};
