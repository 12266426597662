import { MouseEventHandler } from 'react';
import { DriverType } from 'types/forms';

type ModalContent = {
  heading: string;
  body: string;
  confirmButton?: { onClick?: MouseEventHandler; displayText?: string };
  cancelText?: string;
};

const createStaffUpdateBlockedModalContent = (
  onContinue: MouseEventHandler,
  driverType: DriverType
): ModalContent => ({
  heading: 'Blocked policy',
  body: `<p>The customer's policy is currently blocked. You can still update ${
    driverType === DriverType.TEMPORARY ? 'temporary' : 'permanent'
  } driver details by clicking continue.</p>`,
  cancelText: 'Cancel',
  confirmButton: {
    displayText: 'Continue',
    onClick: onContinue,
  },
});

export default createStaffUpdateBlockedModalContent;
