import escape from 'lodash/escape';

export type CsPlaceholders<T> = {
  [variable: string]: { getSubstitutionText: (object: T) => string; isPii: boolean };
};

const getSubstitution = <T>(
  csPlaceholders: CsPlaceholders<T>,
  data: T,
  match: string
): { substitutionText: string; isPii: boolean } => {
  const matchedPlaceholder = match.substring(2, match.length - 2); // trim the curly braces
  const placeholderInfo = csPlaceholders[matchedPlaceholder];
  if (!placeholderInfo) {
    return {
      substitutionText: match,
      isPii: false,
    };
  }
  return {
    substitutionText: placeholderInfo.getSubstitutionText(data),
    isPii: placeholderInfo.isPii,
  };
};

/**
 * A function to replace variables set in ContentStack with values from an object returned from the api.
 * Variables in ContentStack are given by using double curly brackets e.g. {{Variable}}.
 * The corresponding variables are defined in the csPlaceholders object.
 *
 * @param csPlaceholders - the mappings from placeholder text to tranformed data
 * @param data - the object containing the values that will replace the placeholders
 * @param allowPii - (optional, defaults to false) whether to allow PII placeholders (if true, a
 * data-pii-mask attribute should be added by the caller)
 */
export const replacePlaceholdersPlainText = <T>(
  csPlaceholders: CsPlaceholders<T>,
  data: T,
  allowPii?: boolean
) => (csPlainTextString: string): string =>
  csPlainTextString.replace(/({{)\w+(}})/g, (match) => {
    const substitution = getSubstitution(csPlaceholders, data, match);
    return !substitution.isPii || allowPii ? substitution.substitutionText : match;
  });

/**
 * A function to replace variables set in ContentStack with values from an object returned from the api.
 * Variables in ContentStack are given by using double curly brackets e.g. {{Variable}}.
 * The corresponding variables are defined in the csPlaceholders object.
 *
 * Placeholder values are escaped to be used in RichText, and PII information is automatically masked.
 *
 * @param csPlaceholders - the mappings from placeholder text to transformed data
 * @param data - the object containing the values that will replace the placeholders
 */
export const replacePlaceholdersRichText = <T>(csPlaceholders: CsPlaceholders<T>, data: T) => (
  csRichTextString: string
): string =>
  csRichTextString.replace(/({{)\w+(}})/g, (match) => {
    const substitution = getSubstitution(csPlaceholders, data, match);
    const encodedSubstitutionText = escape(substitution.substitutionText);
    return substitution.isPii
      ? `<span data-pii-mask="true">${encodedSubstitutionText}</span>`
      : encodedSubstitutionText;
  });
