import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { ClaimsHistoryState } from './state';

export const UPDATE_CLAIMS_HISTORY = 'UPDATE_CLAIMS_HISTORY';

export type ClaimsHistoryAction =
  | UpdateClaimsHistoryAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateClaimsHistoryAction = {
  type: typeof UPDATE_CLAIMS_HISTORY;
  update: Partial<ClaimsHistoryState>;
};

export const useClaimsHistory = (): [
  ClaimsHistoryState,
  (update: Partial<ClaimsHistoryState>) => void
] => {
  const claimsHistory = useSelector((state: RootState) => state.claimsHistory);
  const dispatch = useDispatch();

  const updateClaimsHistory = useCallback(
    (update: Partial<ClaimsHistoryState>): void => {
      dispatch({ type: UPDATE_CLAIMS_HISTORY, update });
    },
    [dispatch]
  );

  return [claimsHistory, updateClaimsHistory];
};
