import { graphql } from 'gatsby';
import React from 'react';

type CookiesListBlockProps = {
  cookies_list: CookiesListData;
};

export type CookiesListData = {
  show_cookies_list: boolean;
};

export const query = graphql`
  fragment CookiesListBlockInfo on cs__info_page_blocks {
    cookies_list {
      show_cookies_list
    }
  }
  fragment CookiesListBlockAccountArea on cs__account_area_page_blocks {
    cookies_list {
      show_cookies_list
    }
  }
  fragment CookiesListBlockError on cs__error_page_blocks {
    cookies_list {
      show_cookies_list
    }
  }
  fragment CookiesListBlockNews on cs__news_article_page_blocks {
    cookies_list {
      show_cookies_list
    }
  }
`;

const CookiesListBlock: React.FC<CookiesListBlockProps> = ({ cookies_list }) => {
  return cookies_list ? <div id="ot-sdk-cookie-policy" data-cy="CookiesList" /> : null;
};

export default React.memo(CookiesListBlock);
