import { useErrorState } from 'state/error/actions';
import { PolicyInvalidState } from 'state/error/errorCodes';
import { isAxiosError } from './axiosResponseHelper';

const useInvalidPolicyStateErrorHandler = (): ((error: Error) => void) => {
  const [, updateErrorState] = useErrorState();

  return (error: Error) => {
    if (isAxiosError(error)) {
      if (error.response?.data.Code === PolicyInvalidState) {
        updateErrorState({
          hasErrored: true,
          errorCode: PolicyInvalidState,
        });
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  };
};

export default useInvalidPolicyStateErrorHandler;
