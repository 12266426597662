import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { KnockoutOverridesState } from './state';

export const UPDATE_KNOCKOUT_OVERRIDES = 'UPDATE_KNOCKOUT_OVERRIDES';
export const REMOVE_KNOCKOUT_OVERRIDES = 'REMOVE_KNOCKOUT_OVERRIDES';

export type KnockoutOverridesAction =
  | UpdateKnockoutOverridesAction
  | RemoveKnockoutOverridesAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateKnockoutOverridesAction = {
  type: typeof UPDATE_KNOCKOUT_OVERRIDES;
  update: Partial<KnockoutOverridesState>;
};

export type RemoveKnockoutOverridesAction = {
  type: typeof REMOVE_KNOCKOUT_OVERRIDES;
  knockoutsToRemove: string[];
};

export const useKnockoutOverrides = (): [
  KnockoutOverridesState,
  (update: Partial<KnockoutOverridesState>) => void
] => {
  const knockoutOverrides = useSelector((state: RootState) => state.knockoutOverrides);
  const dispatch = useDispatch();

  const updateKnockoutOverrides = useCallback(
    (update: Partial<KnockoutOverridesState>): void => {
      dispatch({ type: UPDATE_KNOCKOUT_OVERRIDES, update });
    },
    [dispatch]
  );

  return [knockoutOverrides, updateKnockoutOverrides];
};
