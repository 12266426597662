import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const RichTextGrid = styled(Grid)`
  && {
    margin-top: ${spacing(8)};
    margin-bottom: ${spacing(8)};
  }
`;
