import { AxiosError } from 'axios';
import { ErrorCodesKnockoutMap, KnockoutCodeMap } from 'state/error/errorCodesKnockoutMap';

export const isAxiosError = (error: Error): error is AxiosError =>
  !!(error as AxiosError).isAxiosError;

type ErrorDetails = {
  Code: string;
  Details: string;
};

export const containsErrorCodeInResponse = (
  data: ErrorDetails | ErrorDetails[] | undefined,
  code: string
): boolean => {
  if (!data) {
    return false;
  }
  if (Array.isArray(data)) {
    return data.map((e) => e.Code).includes(code);
  }
  return data.Code === code;
};

export const getKnockoutFromResponse = (
  data: ErrorDetails | undefined
): KnockoutCodeMap | undefined => {
  if (!data) {
    return undefined;
  }

  return ErrorCodesKnockoutMap.find((e) => e.Code === data.Code);
};
