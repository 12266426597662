import { CardGrid } from '@rsa-digital/evo-shared-components/components/CardGrid';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import { InfoCardRichText } from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, { spacingWithBorder } from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

const borderPx = 1;

export const InfoCardContainer = styled.div`
  margin: ${spacing(8)} 0;

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(12)} 0;
  `}

  ${IconWrapper} {
    margin-right: ${spacing(1.5)};

    ${mediaQuery.tabletPortrait`
        margin-right: ${spacing(2)};
    `}

    ${mediaQuery.tabletLandscape`
        margin-right: ${spacing(1.5)};
    `}
  }

  ${InfoCardRichText} {
    margin-top: ${spacing(3)};
  }
`;

export const Heading = styled.h2`
  ${fonts.headingLarge};
  margin: ${spacing(8)} 0 ${spacing(8)};

  ${mediaQuery.tabletPortrait`
      margin: ${spacing(8)} 0 ${spacing(6)};
  `}

  ${mediaQuery.tabletLandscape`
     margin: ${spacing(12)} 0 ${spacing(8)};
  `}
`;

export const StyledCardGrid = styled(CardGrid)`
  && {
    margin-bottom: ${spacing(-4)};

    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(-2)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-bottom: ${spacing(-4)};
    `}
  }
`;

export const StyledInfoCard = styled(InfoCard)`
  margin: 0 0 ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin: 0 0 ${spacing(2)};
    padding: ${spacingWithBorder(4, borderPx)}
  `}

  ${mediaQuery.tabletLandscape`
    margin: 0 0 ${spacing(4)};
  `}
`;
