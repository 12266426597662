import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { LicenceDetailsState } from 'state/forms/licenceDetails/state';
import { LicenceDetailsData } from '../types';

const mapLicenceDetails = (licenceDetailsState: LicenceDetailsState): LicenceDetailsData => {
  return {
    drivingLicenceType: licenceDetailsState.drivingLicenceType,
    drivingLicenceNumber: licenceDetailsState.drivingLicenceNumber ?? '',
    datePassedTest: dateValueToISODateString(licenceDetailsState.datePassedTest) ?? '',
    hasValidLicence: licenceDetailsState.hasValidLicence,
  } as LicenceDetailsData;
};

export default mapLicenceDetails;
