import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { PolicyAction, RELOAD_POLICY, UPDATE_POLICY } from './actions';
import { initialPolicyState, PolicyState } from './state';

export const policyReducer = (
  policy: PolicyState = initialPolicyState,
  action: PolicyAction
): PolicyState => {
  switch (action.type) {
    case UPDATE_POLICY:
      return action.policy;
    case RELOAD_POLICY:
      return { ...policy, requiresReload: true };
    case RESET_STATE:
      return initialPolicyState;
    case RESET_ADD_A_DRIVER_STATE:
    default:
      return policy;
  }
};
