import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { initialDateValue } from '../shared/dateValue';

export type DriverDetailsState = {
  fullName: string;
  dateOfBirth: DateValue;
  isAddressSameAsHirer?: boolean;
};

export const initialDriverDetailsState: DriverDetailsState = {
  fullName: '',
  dateOfBirth: initialDateValue,
  isAddressSameAsHirer: undefined,
};
