export type ReplaceDriverState = {
  replacedDriverRef: string | null;
};

// The use of (typeof window !== 'undefined' && window.Cypress && window.initialReplaceDriverState)
// allows for pre-populating policy state during testing
// The property initialReplaceDriverState in window is targeted during 'cy.visit -> onBeforeLoad'
export const initialReplaceDriverState = (typeof window !== 'undefined' &&
  window.Cypress &&
  window.initialReplaceDriverState) || { replacedDriverRef: null };
