import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { LicenceDetailsState } from './state';

export const UPDATE_LICENCE_DETAILS = 'UPDATE_LICENCE_DETAILS';

export type LicenceDetailsAction =
  | UpdateLicenceDetailsAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateLicenceDetailsAction = {
  type: typeof UPDATE_LICENCE_DETAILS;
  update: Partial<LicenceDetailsState>;
};

export const useLicenceDetails = (): [
  LicenceDetailsState,
  (update: Partial<LicenceDetailsState>) => void
] => {
  const licenceDetailsState = useSelector((state: RootState) => state.licenceDetails);
  const dispatch = useDispatch();

  const updateLicenceDetailsState = useCallback(
    (update: Partial<LicenceDetailsState>): void => {
      dispatch({ type: UPDATE_LICENCE_DETAILS, update });
    },
    [dispatch]
  );

  return [licenceDetailsState, updateLicenceDetailsState];
};
