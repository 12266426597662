import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { DriverDetailsState } from 'state/forms/driverDetails/state';
import { DriverDetailsData } from '../types';

const mapDriverDetails = (driverDetailsState: DriverDetailsState): DriverDetailsData => {
  return {
    fullName: driverDetailsState.fullName.trim(),
    dateOfBirth: dateValueToISODateString(driverDetailsState.dateOfBirth) ?? '',
    isAddressSameAsHirer: driverDetailsState.isAddressSameAsHirer ?? false,
  };
};

export default mapDriverDetails;
