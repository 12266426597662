import React from 'react';
import { usePolicyState } from 'state/policy/state';
import CoverNoteCards from '../CoverNoteCards';
import { ErrorText } from '../CoverNoteCards/styles';
import { CoverNotesProps } from '../types';

const CoverNotesSection: React.FC<CoverNotesProps> = ({ cover_notes }) => {
  const { coverNotes } = usePolicyState();

  if (coverNotes === null || coverNotes.length === 0) {
    return <ErrorText>{cover_notes.document_error}</ErrorText>;
  }

  return <CoverNoteCards cover_notes={cover_notes} coverNotes={coverNotes} />;
};

export default React.memo(CoverNotesSection);
