import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { DriverConsentAction, UPDATE_DRIVER_CONSENT } from './action';
import { DriverConsentState, initialDriverConsentState } from './state';

export const driverConsentReducer = (
  driverConsentState: DriverConsentState = initialDriverConsentState,
  action: DriverConsentAction
): DriverConsentState => {
  switch (action.type) {
    case UPDATE_DRIVER_CONSENT:
      return {
        ...driverConsentState,
        ...action.update,
      };
    case RESET_STATE:
      return initialDriverConsentState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialDriverConsentState;
    default:
      return driverConsentState;
  }
};
