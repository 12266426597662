import * as KnockoutErrorCodes from 'state/error/errorCodes';
import { Knockout } from './state';

export type KnockoutCodeMap = {
  Code: string;
  Knockout: Knockout;
};

export const ErrorCodesKnockoutMap: KnockoutCodeMap[] = [
  {
    Code: KnockoutErrorCodes.DriverTooYoung,
    Knockout: Knockout.DRIVER_UNDER_SIXTEEN,
  },
  {
    Code: KnockoutErrorCodes.MaximumYoungDrivers,
    Knockout: Knockout.DRIVER_MAX_UNDER_TWENTY_ONE_LIMIT,
  },
  {
    Code: KnockoutErrorCodes.YoungDriverNotAtPolicyAddress,
    Knockout: Knockout.DRIVER_UNDER_TWENTY_ONE_DIFFERENT_ADDRESS,
  },
  {
    Code: KnockoutErrorCodes.YoungDriverInvalidInsuranceGroup,
    Knockout: Knockout.DRIVER_UNDER_TWENTY_FIVE_INVALID_INSURANCE,
  },
  {
    Code: KnockoutErrorCodes.YoungDriverInvalidBhp,
    Knockout: Knockout.DRIVER_UNDER_TWENTY_FIVE_INVALID_VEHICLE_BHP,
  },
  {
    Code: KnockoutErrorCodes.BanLengthExceedsMaximum,
    Knockout: Knockout.BAN_HISTORY,
  },
  {
    Code: KnockoutErrorCodes.InvalidConvictionsHistory,
    Knockout: Knockout.CONVICTION_HISTORY,
  },
  {
    Code: KnockoutErrorCodes.ConvictionsNeedsMoreInfo,
    Knockout: Knockout.CONVICTION_NEED_MORE_INFO,
  },
  {
    Code: KnockoutErrorCodes.InvalidInsuranceHistory,
    Knockout: Knockout.INSURANCE_HISTORY,
  },
  {
    Code: KnockoutErrorCodes.UnderMinAgeProvisionalLicenceError,
    Knockout: Knockout.DRIVER_UNDER_TWENTY_ONE_PROVISIONAL_LICENCE,
  },
  {
    Code: KnockoutErrorCodes.ProvisionalLicenceLimitError,
    Knockout: Knockout.PROVISIONAL_LIMIT_REACHED,
  },
  {
    Code: KnockoutErrorCodes.InvalidClaimsHistory,
    Knockout: Knockout.CLAIMS_HISTORY,
  },
  {
    Code: KnockoutErrorCodes.LicenceRestrictionsError,
    Knockout: Knockout.LICENCE_RESTRICTION,
  },
  {
    Code: KnockoutErrorCodes.NoValidLicenceError,
    Knockout: Knockout.DRIVER_DOES_NOT_HAVE_VALID_LICENCE,
  },
  {
    Code: KnockoutErrorCodes.ForeignLicenceError,
    Knockout: Knockout.DRIVER_WITH_FOREIGN_LICENCE,
  },
  {
    Code: KnockoutErrorCodes.InvalidTemporaryDriverCoverDates,
    Knockout: Knockout.TEMPORARY_DRIVER_COVER_LENGTH,
  },
  {
    Code: KnockoutErrorCodes.UpdateDriverTooManyDrivers,
    Knockout: Knockout.UPDATE_DRIVER_TOO_MANY_DRIVERS,
  },
  {
    Code: KnockoutErrorCodes.PolicyUpdatesBlocked,
    Knockout: Knockout.POLICY_UPDATES_BLOCKED,
  },
  {
    Code: KnockoutErrorCodes.DvlaLicenceCheckFailed,
    Knockout: Knockout.DVLA_LICENCE_CHECK_FAILURE,
  },
  {
    Code: KnockoutErrorCodes.DvlaLicenceCheckUnknown,
    Knockout: Knockout.DVLA_LICENCE_CHECK_UNKNOWN,
  },
  {
    Code: KnockoutErrorCodes.MinimumNamedFullLicenceDriver,
    Knockout: Knockout.REMOVING_ONLY_FULL_LICENCE_HOLDER,
  },
  {
    Code: KnockoutErrorCodes.PolicySeatsFull,
    Knockout: Knockout.POLICY_SEATS_FULL,
  },
];

// The following knockouts are front-end only and so are not mapped
// HIRER_ADDRESS_INCORRECT = 'HIRER_ADDRESS_INCORRECT',
// DRIVER_REJECTED_AGREEMENT_TERMS = 'DRIVER_REJECTED_AGREEMENT_TERMS',
// PERMANENT_HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS = 'PERMANENT_HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS',
// TEMPORARY_HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS = 'TEMPORARY_HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS',
