import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export type LoadingState = {
  isLoading: boolean;
};

export const initialLoadingState: LoadingState = {
  isLoading: false,
};

export const useLoadingState = (): LoadingState => useSelector((state: RootState) => state.loading);
