import { Location } from 'history';
import React from 'react';
import { trackPageView } from '../../helpers/eventTracking';

type Props = {
  children: React.ReactNode;
  location: Location;
};

class PageTracking extends React.Component<Props> {
  public componentDidMount(): void {
    const { location } = this.props;
    trackPageView(location.pathname);
  }

  public componentDidUpdate(prevProps: Props): void {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      trackPageView(location.pathname);
    }
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default PageTracking;
