export type KnockoutOverridesState = {
  declinedKnockouts: string[];
  overriddenKnockouts: OverriddenKnockout[];
};

export const initialKnockoutOverrides: KnockoutOverridesState = {
  declinedKnockouts: [],
  overriddenKnockouts: [],
};

export type OverriddenKnockout = {
  knockoutCode: string;
  reasonForOverride: string;
};
