import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { initialDateValue } from '../shared/dateValue';

export type DrivingOffencesState = {
  addDrivingOffences: boolean | undefined;
  drivingOffences: DrivingOffenceWithKey[];
};

export const initialDrivingOffences: DrivingOffencesState = {
  addDrivingOffences: undefined,
  drivingOffences: [],
};

export type DrivingOffence = {
  offenceDate: DateValue;
  convictionDate: DateValue;
  convictionCode: string;
  penaltyPoints: number | undefined;
  banResult: boolean | undefined;
  banLength: string | undefined;
  banLengthFormat: string;
};

export type DrivingOffenceWithKey = DrivingOffence & {
  key: number;
};

export const initialDrivingOffence: DrivingOffence = {
  offenceDate: initialDateValue,
  convictionDate: initialDateValue,
  convictionCode: '',
  penaltyPoints: undefined,
  banResult: undefined,
  banLength: undefined,
  banLengthFormat: '',
};
