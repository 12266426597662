export type LicenceRestrictionsForApi = {
  addLicenceRestrictions: boolean | undefined;
  licenceRestrictions: string[];
};

export type LicenceRestrictionsState = {
  addLicenceRestrictions: boolean | undefined;
  licenceRestrictions: LicenceRestrictionWithKey[];
};

export const initialLicenceRestrictions: LicenceRestrictionsState = {
  addLicenceRestrictions: undefined,
  licenceRestrictions: [],
};

export type LicenceRestriction = {
  restrictionCode: string;
};

export type LicenceRestrictionWithKey = LicenceRestriction & {
  key: number;
};

export const initialLicenceRestriction: LicenceRestriction = {
  restrictionCode: '',
};
