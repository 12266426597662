import { DriverType } from 'types/forms';

export enum Knockout {
  HIRER_ADDRESS_INCORRECT = 'HIRER_ADDRESS_INCORRECT',
  DRIVER_UNDER_SIXTEEN = 'DRIVER_UNDER_SIXTEEN',
  DRIVER_MAX_UNDER_TWENTY_ONE_LIMIT = 'DRIVER_MAX_UNDER_TWENTY_ONE_LIMIT',
  DRIVER_UNDER_TWENTY_ONE_DIFFERENT_ADDRESS = 'DRIVER_UNDER_TWENTY_ONE_DIFFERENT_ADDRESS',
  DRIVER_UNDER_TWENTY_FIVE_INVALID_INSURANCE = 'DRIVER_UNDER_TWENTY_FIVE_INVALID_INSURANCE',
  DRIVER_UNDER_TWENTY_FIVE_INVALID_VEHICLE_BHP = 'DRIVER_UNDER_TWENTY_FIVE_INVALID_VEHICLE_BHP',
  CONVICTION_HISTORY = 'CONVICTION_HISTORY',
  CONVICTION_NEED_MORE_INFO = 'CONVICTION_NEED_MORE_INFO',
  BAN_HISTORY = 'BAN_HISTORY',
  INSURANCE_HISTORY = 'INSURANCE_HISTORY',
  DRIVER_DOES_NOT_HAVE_VALID_LICENCE = 'DRIVER_DOES_NOT_HAVE_VALID_LICENCE',
  DRIVER_UNDER_TWENTY_ONE_PROVISIONAL_LICENCE = 'DRIVER_UNDER_TWENTY_ONE_PROVISIONAL_LICENCE',
  DRIVER_WITH_FOREIGN_LICENCE = 'DRIVER_WITH_FOREIGN_LICENCE',
  PROVISIONAL_LIMIT_REACHED = 'PROVISIONAL_LIMIT_REACHED',
  PERMANENT_DRIVER_REJECTED_AGREEMENT_TERMS = 'PERMANENT_DRIVER_REJECTED_AGREEMENT_TERMS',
  PERMANENT_HIRER_DRIVER_REJECTED_AGREEMENT_TERMS = 'PERMANENT_HIRER_DRIVER_REJECTED_AGREEMENT_TERMS',
  HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS = 'HIRER_OR_NOMINEE_REJECTED_AGREEMENT_TERMS',
  CLAIMS_HISTORY = 'CLAIMS_HISTORY',
  LICENCE_RESTRICTION = 'LICENCE_RESTRICTION',
  TEMPORARY_DRIVER_COVER_LENGTH = 'TEMPORARY_DRIVER_COVER_LENGTH',
  POLICY_UPDATES_BLOCKED = 'POLICY_UPDATES_BLOCKED',
  UPDATE_DRIVER_TOO_MANY_DRIVERS = 'UPDATE_DRIVER_TOO_MANY_DRIVERS',
  DVLA_LICENCE_CHECK_FAILURE = 'DVLA_LICENCE_CHECK_FAILURE',
  DVLA_LICENCE_CHECK_UNKNOWN = 'DVLA_LICENCE_CHECK_UNKNOWN',
  REMOVING_ONLY_FULL_LICENCE_HOLDER = 'REMOVING_ONLY_FULL_LICENCE_HOLDER',
  POLICY_SEATS_FULL = 'POLICY_SEATS_FULL',
}

export type ErrorState = {
  hasErrored: boolean;
  errorCode?: string;
  knockout?: Knockout;
  driverType?: DriverType;
};

export const initialErrorState: ErrorState = {
  hasErrored: false,
};
