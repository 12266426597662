import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { ConfirmAddressState } from './state';

export const UPDATE_CONFIRM_ADDRESS = 'UPDATE_CONFIRM_ADDRESS';

export type ConfirmAddressAction =
  | UpdateConfirmAddressAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateConfirmAddressAction = {
  type: typeof UPDATE_CONFIRM_ADDRESS;
  update: Partial<ConfirmAddressState>;
};

export const useConfirmAddress = (): [
  ConfirmAddressState,
  (update: Partial<ConfirmAddressState>) => void
] => {
  const confirmAddressState = useSelector((state: RootState) => state.confirmAddress);
  const dispatch = useDispatch();

  const updateConfirmAddressState = useCallback(
    (update: Partial<ConfirmAddressState>): void => {
      dispatch({ type: UPDATE_CONFIRM_ADDRESS, update });
    },
    [dispatch]
  );

  return [confirmAddressState, updateConfirmAddressState];
};
