import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type DrivingOffencesReferenceDataResponse = {
  ConvictionCodes: ApiOption[];
  BanLengthFormats: ApiOption[];
};

export type DrivingOffencesReferenceData = {
  convictionCodes: ReferenceDataOption[];
  banLengthFormats: ReferenceDataOption[];
};

export const mapDrivingOffencesRefData = (
  data: DrivingOffencesReferenceDataResponse
): DrivingOffencesReferenceData => ({
  convictionCodes: data.ConvictionCodes.map(mapApiOption),
  banLengthFormats: data.BanLengthFormats.map(mapApiOption),
});
