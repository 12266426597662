import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type IncidentTypesReferenceDataResponse = {
  IncidentTypeCodes: ApiOption[];
};

export type IncidentTypesReferenceData = {
  incidentTypeCodes: ReferenceDataOption[];
};

export const mapIncidentTypesRefData = (
  data: IncidentTypesReferenceDataResponse
): IncidentTypesReferenceData => ({
  incidentTypeCodes: data.IncidentTypeCodes.map(mapApiOption),
});
