import {
  RowValueText,
  StyledTd,
  StyledTh,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import { Tooltip } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import React from 'react';
import { usePolicyState } from 'state/policy/state';
import { trackTooltipClick } from 'helpers/eventTracking';
import Table from '../Table';
import { RowTitle, StyledTrWithVerticalAlign, TitleWithMargin } from '../styles';
import { PolicyDetailsProps } from '../types';

export const query = graphql`
  fragment PolicyDetailsPolicyAccordion on cs__policy_details_accordion_accordion_entries {
    policy_details {
      heading
      initially_display
      policy_number
      policy_start
      renewal_date
      open_driving {
        heading
        tooltip {
          body
          button_text {
            open
            close
          }
        }
      }
    }
  }
`;

const PolicyDetailsTable: React.FC<PolicyDetailsProps> = ({ policy_details }) => {
  const { policy } = usePolicyState();

  if (!policy) {
    return null;
  }

  return (
    <Table>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <b>{policy_details.policy_number}</b>
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>{policy.policyDetails.policyNumber}</RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <b>{policy_details.policy_start}</b>
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>
            {policy.policyDetails.policyStartDate
              ? formatLongDateWithDayFirst(policy.policyDetails.policyStartDate)
              : ''}
          </RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <b>{policy_details.renewal_date}</b>
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>
            {policy.policyDetails.renewalDate
              ? formatLongDateWithDayFirst(policy.policyDetails.renewalDate)
              : ''}
          </RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <TitleWithMargin>{policy_details.open_driving.heading}</TitleWithMargin>
            <Tooltip
              body={policy_details.open_driving.tooltip.body}
              buttonText={policy_details.open_driving.tooltip.button_text}
              onExpand={trackTooltipClick(
                'Expand',
                policy_details.open_driving.tooltip.button_text.open,
                policy_details.open_driving.heading
              )}
              onCollapse={trackTooltipClick(
                'Collapse',
                policy_details.open_driving.tooltip.button_text.close,
                policy_details.open_driving.heading
              )}
            />
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>{policy.policyDetails.openDriving ? 'Yes' : 'No'}</RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
    </Table>
  );
};

export default React.memo(PolicyDetailsTable);
