import React from 'react';
import { isProductionSite } from 'helpers/analyticsHelpers';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const qubitProduction = 'https://static.goqubit.com/smartserve-5641.js';
const qubitStaging = 'https://static.goqubit.com/smartserve-5642.js';

const qubitScript: JSX.Element = (
  <script
    type="text/plain"
    className="optanon-category-C0002-C0004"
    src={isProductionSite(analyticsEnv) ? qubitProduction : qubitStaging}
    async
    defer
  />
);

export default qubitScript;
