import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { NewDriversCoverAction, UPDATE_NEW_DRIVERS_COVER } from './action';
import { initialNewDriversCoverState, NewDriversCoverState } from './state';

export const newDriversCoverReducer = (
  newDriversCoverState: NewDriversCoverState = initialNewDriversCoverState,
  action: NewDriversCoverAction
): NewDriversCoverState => {
  switch (action.type) {
    case UPDATE_NEW_DRIVERS_COVER:
      return {
        ...newDriversCoverState,
        ...action.update,
      };
    case RESET_STATE:
      return initialNewDriversCoverState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialNewDriversCoverState;
    default:
      return newDriversCoverState;
  }
};
