import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const HTML_ATTRIBUTES = {
  lang: 'en',
};

export type MetaTags = {
  title: string;
  robots?: string;
  canonical_tag?: string;
  description?: string;
  og_url?: string;
  og_title?: string;
  og_description?: string;
  og_image?: Image;
  og_type?: string;
  twitter_site?: string;
  twitter_card?: string;
};

export const query = graphql`
  fragment MetaDataTagsInfo on cs__info_page_page_meta {
    title
    robots
    canonical_tag
    description
    og_url
    og_title
    og_description
    og_image_asset {
      ...CsAsset
    }
    og_type
    twitter_site
    twitter_card
  }
  fragment MetaDataTagsNews on cs__news_article_page_page_meta {
    title
    robots
    canonical_tag
    description
    og_url
    og_title
    og_description
    og_image_asset {
      ...CsAsset
    }
    og_type
    twitter_site
    twitter_card
  }
  fragment MetaDataTagsNewsIndex on cs__news_index_page_page_meta {
    title
    robots
    canonical_tag
    description
    og_url
    og_title
    og_description
    og_image_asset {
      ...CsAsset
    }
    og_type
    twitter_site
    twitter_card
  }
  fragment MetaDataTagsAccountArea on cs__account_area_page_page_meta {
    title
    robots
    canonical_tag
    description
    og_url
    og_title
    og_description
    og_image_asset {
      ...CsAsset
    }
    og_type
    twitter_site
    twitter_card
  }
  fragment MetaDataTagsStaffArea on cs__staff_area_page_page_meta {
    title
    robots
    canonical_tag
    description
    og_url
    og_title
    og_description
    og_image_asset {
      ...CsAsset
    }
    og_type
    twitter_site
    twitter_card
  }
  fragment MetaDataTagsSignedOut on cs__signed_out_page_page_meta {
    title
    robots
    canonical_tag
    description
  }
`;

type SiteWideSeo = {
  csSiteWideSeo: { title: string; favicon: { url: string } };
};

type Site = {
  site: { siteMetadata: { siteUrl: string } };
};

const siteWideSeoQuery = graphql`
  query {
    csSiteWideSeo {
      title
      favicon {
        url
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const Meta: React.FC<{ meta: MetaTags }> = ({ meta }) => {
  const data = useStaticQuery<SiteWideSeo & Site>(siteWideSeoQuery);
  const twitterSite = meta.twitter_site || data.site.siteMetadata.siteUrl;
  const absoluteOgUrl = meta.og_url?.startsWith('/')
    ? data.site.siteMetadata.siteUrl + meta.og_url
    : meta.og_url;
  const absoluteOgImageUrl = meta.og_image?.url.startsWith('/')
    ? data.site.siteMetadata.siteUrl + meta.og_image.url
    : meta.og_image?.url;

  return (
    <Helmet htmlAttributes={HTML_ATTRIBUTES}>
      <title>
        {meta.title} {data.csSiteWideSeo.title}
      </title>
      <link rel="icon" type="image/png" href={data.csSiteWideSeo.favicon.url} />
      {meta.canonical_tag && <link rel="canonical" href={meta.canonical_tag} />}
      {meta.robots && <meta name="robots" content={meta.robots} />}
      {meta.description && <meta name="description" content={meta.description} />}
      {absoluteOgUrl && <meta property="og:url" content={absoluteOgUrl} />}
      {meta.og_title && <meta property="og:title" content={meta.og_title} />}
      {meta.og_description && <meta property="og:description" content={meta.og_description} />}
      {absoluteOgImageUrl && <meta property="og:image" content={absoluteOgImageUrl} />}
      {meta.og_type && <meta property="og:type" content={meta.og_type} />}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {meta.twitter_card && <meta name="twitter:card" content={meta.twitter_card} />}
    </Helmet>
  );
};

export default Meta;
