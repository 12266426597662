import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { ReferenceDataOption } from 'types/referenceData';
import { initialDateValue } from '../shared/dateValue';

export enum LicenceType {
  FULL_UK_LICENCE = 'FULL_UK_LICENCE',
  FULL_UK_LICENCE_NORTHERN_IRELAND = 'FULL_UK_LICENCE_NORTHERN_IRELAND',
  PROVISIONAL_LICENCE = 'PROVISIONAL_LICENCE',
  PROVISIONAL_LICENCE_NORTHERN_IRELAND = 'PROVISIONAL_LICENCE_NORTHERN_IRELAND',
  FOREIGN_LICENCE = 'FOREIGN_LICENCE',
}

export type LicenceDetailsState = {
  hasValidLicence?: boolean;
  drivingLicenceType: LicenceType | '';
  drivingLicenceNumber: string;
  datePassedTest: DateValue;
};

export const initialLicenceDetailsState: LicenceDetailsState = {
  hasValidLicence: undefined,
  drivingLicenceType: '',
  drivingLicenceNumber: '',
  datePassedTest: initialDateValue,
};

export const licenceDetailsRefData: { [fieldname: string]: ReferenceDataOption[] } = {
  drivingLicenceType: [
    { name: 'Full UK licence', value: LicenceType.FULL_UK_LICENCE },
    {
      name: 'Full UK licence (Northern Ireland)',
      value: LicenceType.FULL_UK_LICENCE_NORTHERN_IRELAND,
    },
    { name: 'Provisional licence', value: LicenceType.PROVISIONAL_LICENCE },
    {
      name: 'Provisional licence (Northern Ireland)',
      value: LicenceType.PROVISIONAL_LICENCE_NORTHERN_IRELAND,
    },
    { name: 'Foreign licence', value: LicenceType.FOREIGN_LICENCE },
  ],
};
