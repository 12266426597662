import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledSimpleDivider = styled.hr`
  background-color: ${colors.neutral05};
  border: none;
  height: 1px;
  margin: ${spacing(8)} 0;
`;

export const StyledFullWidthDivider = styled(FullWidthDivider)`
  margin-top: ${spacing(12)};
`;
