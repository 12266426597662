/* eslint-disable react/jsx-props-no-spreading */
import { tableColumnWidths } from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import {
  FullWidthBody,
  FullWidthTable,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';

const Table: React.FC = ({ children, ...rest }) => {
  return (
    <div {...componentProps(rest)}>
      <Grid>
        <GridItem {...tableColumnWidths}>
          <FullWidthTable>
            <FullWidthBody>{children}</FullWidthBody>
          </FullWidthTable>
        </GridItem>
      </Grid>
    </div>
  );
};

export default Table;
