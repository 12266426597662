import BaseLoadingOverlayComponent from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

type LoadingOverlayCsContent = {
  csLoadingOverlay: { loading_spinner_screenreader_text: string };
};

const loadingOverlayQuery = graphql`
  query {
    csLoadingOverlay {
      loading_spinner_screenreader_text
    }
  }
`;

const LoadingOverlay: React.FC = () => {
  const { csLoadingOverlay } = useStaticQuery<LoadingOverlayCsContent>(loadingOverlayQuery);

  return (
    <BaseLoadingOverlayComponent
      loadingMessage={csLoadingOverlay.loading_spinner_screenreader_text}
    />
  );
};

export default LoadingOverlay;
