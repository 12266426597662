import { useCallback } from 'react';
import { ResetStateAction } from 'state/actions';
import useDispatch from 'state/useDispatch';
import { LoadingState } from './state';

export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';

export type LoadingAction = UpdateLoadingStateAction | ResetStateAction;

export type UpdateLoadingStateAction = {
  type: typeof UPDATE_LOADING_STATE;
  state: LoadingState;
};

export const useUpdateLoadingState = (): ((state: LoadingState) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (state: LoadingState): void => {
      dispatch({ type: UPDATE_LOADING_STATE, state });
    },
    [dispatch]
  );
};
