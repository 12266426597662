import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiOption, ReferenceDataOption } from 'types/referenceData';

export type LicenceRestrictionsReferenceDataResponse = {
  RestrictionCodes: ApiOption[];
};

export type LicenceRestrictionsReferenceData = {
  restrictionCodes: ReferenceDataOption[];
};

export const mapLicenceRestrictionsRefData = (
  data: LicenceRestrictionsReferenceDataResponse
): LicenceRestrictionsReferenceData => ({
  restrictionCodes: data.RestrictionCodes.map(mapApiOption),
});
