import { InnerAccordionSection } from '@rsa-digital/evo-shared-components/components/Accordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import { FAQ, generateJsonLdFaqs } from './jsonLd';
import {
  AccordionWrapperBlock,
  Heading,
  HeadingsGridItem,
  StyledAccordion,
  StyledRichText,
} from './styles';

type NestedAccordionBlockProps = {
  nested_accordion: {
    heading: string;
    rich_text: string;
    accordion_entries: {
      entry_title: string;
      initially_display?: boolean;
      inner_accordion_entries: {
        entry_title: string;
        entry_content: string;
        include_in_faq_metadata: boolean;
      }[];
    }[];
  };
};

export const query = graphql`
  fragment NestedAccordionBlockInfo on cs__info_page_blocks {
    nested_accordion {
      heading
      rich_text
      accordion_entries {
        entry_title
        initially_display
        inner_accordion_entries {
          entry_title
          entry_content
          include_in_faq_metadata
        }
      }
    }
  }
  fragment NestedAccordionBlockError on cs__error_page_blocks {
    nested_accordion {
      heading
      rich_text
      accordion_entries {
        entry_title
        inner_accordion_entries {
          entry_title
          entry_content
          include_in_faq_metadata
        }
      }
    }
  }
`;

const NestedAccordionBlock: React.FC<NestedAccordionBlockProps> = ({ nested_accordion }) => {
  const faqsToIncludeInSeo: FAQ[] = nested_accordion.accordion_entries
    .flatMap(({ inner_accordion_entries }) => inner_accordion_entries)
    .filter(({ include_in_faq_metadata }) => include_in_faq_metadata)
    .map(({ entry_title, entry_content }) => ({
      question: entry_title,
      answer: entry_content,
    }));

  const accordionType = faqsToIncludeInSeo.length !== 0 ? 'FAQ' : 'General';

  const convertedEntries = nested_accordion.accordion_entries.map(
    ({ entry_title: outer_entry_title, inner_accordion_entries, initially_display }) => ({
      title: outer_entry_title,
      onExpand: trackAccordionExpandClick(accordionType, outer_entry_title),
      onCollapse: trackAccordionCollapseClick(accordionType, outer_entry_title),
      initiallyDisplay: initially_display,
      content: inner_accordion_entries.map(({ entry_title: inner_entry_title, entry_content }) => (
        <InnerAccordionSection
          title={inner_entry_title}
          key={inner_entry_title}
          sectionId={inner_entry_title}
          entryId={`text body: ${inner_entry_title}`}
          initiallyDisplayEntry={false}
          onExpand={trackAccordionExpandClick(accordionType, outer_entry_title, inner_entry_title)}
          onCollapse={trackAccordionCollapseClick(
            accordionType,
            outer_entry_title,
            inner_entry_title
          )}>
          <StyledRichText html={entry_content} />
        </InnerAccordionSection>
      )),
    })
  );

  const jsonLdFaqs = generateJsonLdFaqs(faqsToIncludeInSeo);

  return (
    <>
      {jsonLdFaqs && <Helmet script={[jsonLdFaqs]} />}
      <AccordionWrapperBlock data-cy="NestedAccordion">
        {nested_accordion.heading && (
          <Grid>
            <HeadingsGridItem desktop={10} tabletLandscape={8}>
              <Heading>{nested_accordion.heading}</Heading>
              {nested_accordion.rich_text && <RichText html={nested_accordion.rich_text} />}
            </HeadingsGridItem>
          </Grid>
        )}
        <StyledAccordion accordionEntries={convertedEntries} initiallyDisplayEntries={false} />
      </AccordionWrapperBlock>
    </>
  );
};

export default React.memo(NestedAccordionBlock);
