import { AboutTheDriverState } from 'state/forms/aboutTheDriver/state';
import { ClaimsHistoryState } from 'state/forms/claimsHistory/state';
import { DriverDetailsState } from 'state/forms/driverDetails/state';
import { DrivingOffencesState } from 'state/forms/drivingOffences/state';
import { KnockoutOverridesState } from 'state/forms/knockoutOverrides/state';
import { LicenceDetailsState } from 'state/forms/licenceDetails/state';
import { LicenceRestrictionsState } from 'state/forms/licenceRestrictions/state';
import { NewDriversCoverState } from 'state/forms/newDriversCover/state';
import { ReplaceDriverState } from 'state/forms/replaceDriver/state';
import driversClient from './client';
import mapAboutTheDriver from './mapping/mapAboutTheDriver';
import mapClaims from './mapping/mapClaim';
import mapDriverDetails from './mapping/mapDriverDetails';
import mapDrivingOffences from './mapping/mapDrivingOffences';
import mapLicenceDetails from './mapping/mapLicenceDetails';
import mapLicenceRestrictions from './mapping/mapLicenceRestriction';
import mapNewDriversCover from './mapping/mapNewDriversCover';
import {
  AboutTheDriverRequest,
  AddDriverRequest,
  AddTemporaryDriverRequest,
  ClaimsHistoryRequest,
  DriverDetailsRequest,
  DrivingOffencesRequest,
  LicenceDetailsRequest,
  LicenceRestrictionRequest,
  NewDriversCoverRequest,
  PolicySeatStatusApiResponse,
  ReplaceDriverRequest,
} from './types';

export type DriversService = {
  validateAddDriverRequestNewDriversCover: (
    policyNumber: string,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState
  ) => Promise<void>;
  getSeatStatus: (
    policyNumber: string,
    newDriversCoverState: NewDriversCoverState
  ) => Promise<PolicySeatStatusApiResponse>;
  validateAddDriverRequestAboutTheDriver: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  validateAddDriverRequestDriverDetails: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    replaceDriverState: ReplaceDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  validateAddDriverRequestLicenceDetails: (
    policyNumber: string,
    aboutTheDriver: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    licenceDetails: LicenceDetailsState,
    replaceDriverState: ReplaceDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  validateAddDriverRequestClaimsHistory: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    licenceDetails: LicenceDetailsState,
    claims: ClaimsHistoryState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  validateAddDriverRequestDrivingOffences: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claims: ClaimsHistoryState,
    licenceDetails: LicenceDetailsState,
    drivingOffencesState: DrivingOffencesState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  validateAddDriverRequestLicenceRestrictions: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => Promise<void>;
  addPermanentDriverRequest: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    knockoutOverrides: KnockoutOverridesState
  ) => Promise<void>;
  removePermanentDriverRequest: (policyNumber: string, driverRef: string) => Promise<void>;
  replacePermanentDriverRequest: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    replaceDriverState: ReplaceDriverState,
    knockoutOverrides: KnockoutOverridesState
  ) => Promise<void>;
  addTemporaryDriverRequest: (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    newDriversCoverState: NewDriversCoverState,
    replaceDriverState: ReplaceDriverState,
    knockoutOverrides: KnockoutOverridesState
  ) => Promise<void>;
  removeTemporaryDriverRequest: (policyNumber: string, driverRef: string) => Promise<void>;
};

const driversService: DriversService = {
  validateAddDriverRequestNewDriversCover: async (
    policyNumber: string,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState
  ) => {
    const newDriversCoverRequest: NewDriversCoverRequest = {
      coverDates: mapNewDriversCover(newDriversCoverState),
      overridableKnockouts: knockoutOverrides,
    };
    await driversClient.validateTemporaryDriverRequest(
      policyNumber,
      newDriversCoverRequest,
      'temporaryDriverCoverDates'
    );
  },
  getSeatStatus: async (policyNumber: string, newDriversCoverState: NewDriversCoverState) => {
    const coverDates = mapNewDriversCover(newDriversCoverState);
    return driversClient.getSeatStatus(policyNumber, coverDates.coverStart, coverDates.coverEnd);
  },
  validateAddDriverRequestAboutTheDriver: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const aboutTheDriverRequest: AboutTheDriverRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };
    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        aboutTheDriverRequest,
        'aboutTheDriver'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        aboutTheDriverRequest,
        'aboutTheDriver'
      );
    }
  },
  validateAddDriverRequestDriverDetails: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    replaceDriverState: ReplaceDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const driverDetailsRequest: DriverDetailsRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      replacedDriverRef: replaceDriverState.replacedDriverRef,
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };

    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        driverDetailsRequest,
        'driverDetails'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        driverDetailsRequest,
        'driverDetails'
      );
    }
  },
  validateAddDriverRequestLicenceDetails: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    licenceDetailsState: LicenceDetailsState,
    replaceDriverState: ReplaceDriverState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const licenceDetailsRequest: LicenceDetailsRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      replacedDriverRef: replaceDriverState.replacedDriverRef,
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };

    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        licenceDetailsRequest,
        'licenceDetails'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        licenceDetailsRequest,
        'licenceDetails'
      );
    }
  },
  validateAddDriverRequestClaimsHistory: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    licenceDetailsState: LicenceDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const claimsHistoryRequest: ClaimsHistoryRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      claims: mapClaims(claimsHistoryState),
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };

    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        claimsHistoryRequest,
        'claimsHistory'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        claimsHistoryRequest,
        'claimsHistory'
      );
    }
  },
  validateAddDriverRequestDrivingOffences: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    licenceDetailsState: LicenceDetailsState,
    drivingOffencesState: DrivingOffencesState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const drivingOffenceRequest: DrivingOffencesRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      claims: mapClaims(claimsHistoryState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      drivingOffences: mapDrivingOffences(drivingOffencesState),
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };

    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        drivingOffenceRequest,
        'drivingOffences'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        drivingOffenceRequest,
        'drivingOffences'
      );
    }
  },

  validateAddDriverRequestLicenceRestrictions: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    newDriversCoverState: NewDriversCoverState,
    knockoutOverrides: KnockoutOverridesState,
    isTemporaryDriver: boolean
  ) => {
    const licenceRestrictionRequest: LicenceRestrictionRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      claims: mapClaims(claimsHistoryState),
      drivingOffences: mapDrivingOffences(drivingOffencesState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      licenceRestrictions: mapLicenceRestrictions(licenceRestrictionsState),
      coverDates: isTemporaryDriver ? mapNewDriversCover(newDriversCoverState) : undefined,
      overridableKnockouts: knockoutOverrides,
    };

    if (isTemporaryDriver) {
      await driversClient.validateTemporaryDriverRequest(
        policyNumber,
        licenceRestrictionRequest,
        'licenceRestrictions'
      );
    } else {
      await driversClient.validatePermanentDriverRequest(
        policyNumber,
        licenceRestrictionRequest,
        'licenceRestrictions'
      );
    }
  },
  addPermanentDriverRequest: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    knockoutOverrides: KnockoutOverridesState
  ) => {
    const addPermanentDriverRequest: AddDriverRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      claims: mapClaims(claimsHistoryState),
      drivingOffences: mapDrivingOffences(drivingOffencesState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      licenceRestrictions: mapLicenceRestrictions(licenceRestrictionsState),
      overridableKnockouts: knockoutOverrides,
    };
    await driversClient.addPermanentDriver(policyNumber, addPermanentDriverRequest);
  },
  removePermanentDriverRequest: async (policyNumber: string, driverRef: string) => {
    await driversClient.removePermanentDriver(policyNumber, driverRef);
  },
  replacePermanentDriverRequest: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    replaceDriverState: ReplaceDriverState,
    knockoutOverrides: KnockoutOverridesState
  ) => {
    const replacePermanentDriverRequest: ReplaceDriverRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      claims: mapClaims(claimsHistoryState),
      drivingOffences: mapDrivingOffences(drivingOffencesState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      licenceRestrictions: mapLicenceRestrictions(licenceRestrictionsState),
      overridableKnockouts: knockoutOverrides,
      replacedDriverRef: replaceDriverState.replacedDriverRef ?? '',
    };
    await driversClient.replacePermanentDriver(policyNumber, replacePermanentDriverRequest);
  },
  addTemporaryDriverRequest: async (
    policyNumber: string,
    aboutTheDriverState: AboutTheDriverState,
    driverDetailsState: DriverDetailsState,
    claimsHistoryState: ClaimsHistoryState,
    drivingOffencesState: DrivingOffencesState,
    licenceDetailsState: LicenceDetailsState,
    licenceRestrictionsState: LicenceRestrictionsState,
    newDriversCoverState: NewDriversCoverState,
    replaceDriverState: ReplaceDriverState,
    knockoutOverrides: KnockoutOverridesState
  ) => {
    const addTemporaryDriverRequest: AddTemporaryDriverRequest = {
      aboutTheDriver: mapAboutTheDriver(aboutTheDriverState),
      driverDetails: mapDriverDetails(driverDetailsState),
      claims: mapClaims(claimsHistoryState),
      drivingOffences: mapDrivingOffences(drivingOffencesState),
      licenceDetails: mapLicenceDetails(licenceDetailsState),
      licenceRestrictions: mapLicenceRestrictions(licenceRestrictionsState),
      coverDates: mapNewDriversCover(newDriversCoverState),
      overridableKnockouts: knockoutOverrides,
      replacedDriverRef: replaceDriverState.replacedDriverRef ?? undefined,
    };
    await driversClient.addTemporaryDriver(policyNumber, addTemporaryDriverRequest);
  },
  removeTemporaryDriverRequest: async (policyNumber: string, driverRef: string) => {
    await driversClient.removeTemporaryDriver(policyNumber, driverRef);
  },
};

export default driversService;
