import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql } from 'gatsby';
import React from 'react';
import { GridWithMargins, LeadParagraph, SubParagraph } from './styles';

type IntroBlockProps = {
  intro: {
    lead_paragraph: string;
    sub_paragraph: string;
  };
};
export const query = graphql`
  fragment IntroBlockInfo on cs__info_page_blocks {
    intro {
      lead_paragraph
      sub_paragraph
    }
  }
`;

const IntroBlock: React.FC<IntroBlockProps> = ({ intro }) => (
  <GridWithMargins alignLeft data-cy="Intro">
    <GridItem desktop={1} tabletLandscape={1} />
    <GridItem desktop={8} tabletLandscape={8}>
      <LeadParagraph html={intro.lead_paragraph} />
      {intro.sub_paragraph && <SubParagraph html={intro.sub_paragraph} />}
    </GridItem>
  </GridWithMargins>
);

export default React.memo(IntroBlock);
