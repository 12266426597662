/* eslint-disable react/jsx-props-no-spreading */
import { DoubleHeader } from '@rsa-digital/evo-shared-components/components/Header';
import React from 'react';
import { useHeaderData } from './useHeaderData';

const Header: React.FC<{
  toggleMenuCallback?: (open: boolean) => void;
  showStickyHeader?: boolean;
}> = ({ toggleMenuCallback, showStickyHeader }) => {
  const headerData = useHeaderData();

  return (
    <DoubleHeader
      {...headerData}
      toggleMenuCallback={toggleMenuCallback}
      isSticky={showStickyHeader}
      data-cy="Header"
    />
  );
};

export default Header;
