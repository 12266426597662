import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { ManagedPolicyState } from './state';

export const UPDATE_MANAGED_POLICY = 'UPDATE_MANAGED_POLICY';

export type ManagedPolicyAction = UpdateManagedPolicyAction | ResetStateAction;

export type UpdateManagedPolicyAction = {
  type: typeof UPDATE_MANAGED_POLICY;
  policyNumber: string | null;
};

export const useManagedPolicy = (): [
  ManagedPolicyState['policyNumber'],
  (update: string | null) => void
] => {
  const managedPolicyNumber = useSelector((state: RootState) => state.managedPolicy).policyNumber;
  const dispatch = useDispatch();

  const updateManagedPolicyNumber = useCallback(
    (newPolicyNumber: string | null): void => {
      dispatch({ type: UPDATE_MANAGED_POLICY, policyNumber: newPolicyNumber });
    },
    [dispatch]
  );
  return [managedPolicyNumber, updateManagedPolicyNumber];
};
