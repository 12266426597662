import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  ${fonts.headingLarge};
  margin: 0 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}
`;
export const Subheading = styled.p`
  ${fonts.headingXSmall};
  margin: 0 0 ${spacing(3)};
  & {
    color: ${colors.neutral01};
  }
`;

export const Summary = styled(RichText)`
  margin: 0 0 ${spacing(3)};
`;
