import { AboutTheDriverState } from 'state/forms/aboutTheDriver/state';
import { AboutTheDriverData } from '../types';

const mapAboutTheDriver = (aboutTheDriverState: AboutTheDriverState): AboutTheDriverData => {
  return {
    isHirer: aboutTheDriverState.isHirer,
    isNominee: aboutTheDriverState.isNominee,
    refusedMotorInsurance: aboutTheDriverState.refusedMotorInsurance,
  } as AboutTheDriverData;
};

export default mapAboutTheDriver;
