import { useCallback } from 'react';
import { useErrorState } from 'state/error/actions';
import { Knockout } from 'state/error/state';
import { useUserState } from 'state/user/state';
import { DriverType } from 'types/forms';
import usePolicyUpdateAccess from './usePolicyUpdateAccess';

type BlockedUpdateHandler = (
  onUpdateAllowed: () => void,
  staffConfirmationRequiredHandler: (callback: () => void) => void
) => void;

export const useBlockedUpdatesHandler = (driverType: DriverType): BlockedUpdateHandler => {
  const policyUpdateAccess = usePolicyUpdateAccess();
  const { isStaffMember } = useUserState();
  const [, updateErrorState] = useErrorState();

  return useCallback(
    (
      onUpdateAllowed: () => void,
      staffConfirmationRequiredHandler: (callback: () => void) => void
    ) => {
      if (
        policyUpdateAccess.updatesBlockedForAll ||
        (!isStaffMember && policyUpdateAccess.updatesBlockedForCustomer)
      ) {
        updateErrorState({
          hasErrored: true,
          knockout: Knockout.POLICY_UPDATES_BLOCKED,
          driverType,
        });
      } else if (isStaffMember && policyUpdateAccess.updatesBlockedForCustomer) {
        staffConfirmationRequiredHandler(onUpdateAllowed);
      } else {
        onUpdateAllowed();
      }
    },
    [
      policyUpdateAccess.updatesBlockedForAll,
      policyUpdateAccess.updatesBlockedForCustomer,
      isStaffMember,
      updateErrorState,
      driverType,
    ]
  );
};

export const useIsUpdateBlocked = (): boolean => {
  const { isStaffMember } = useUserState();
  const addDriverHelper = usePolicyUpdateAccess();
  return (
    addDriverHelper.updatesBlockedForAll ||
    (addDriverHelper.updatesBlockedForCustomer && !isStaffMember)
  );
};
