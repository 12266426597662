import AlertNotificationComponent, {
  AlertNotificationProps,
} from '@rsa-digital/evo-shared-components/components/AlertNotification';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { trackAlertNotification, trackButtonClick } from 'helpers/eventTracking';
import { Icon } from 'types/contentStack';

export type AlertNotificationData = {
  csAlertNotification: {
    title: string;
    main_icon: [Icon];
    main_content: string;
    side_content: [SideContent] | [];
    isExpandable: boolean | false;
    minimised_content: string;
    left_icon: [Icon];
    expand_icon: [Icon];
    minimise_icon: [Icon];
  };
};

type SideContent = {
  link_section: LinkSection | null;
  rich_text_with_icon: RichTextWithIcon | null;
};

type LinkSection = {
  heading: string;
  links: {
    text: string;
    url: string;
  }[];
};

type RichTextWithIcon = {
  icon: [Icon];
  heading: string;
  rich_text: string;
};

const processSideContent = (
  wrappedSideContent: [SideContent] | []
): AlertNotificationProps['sideContent'] => {
  const sideContent = unwrapSingleton(wrappedSideContent);
  if (sideContent === undefined) {
    return undefined;
  }
  if (sideContent.link_section !== null) {
    return sideContent.link_section;
  }
  if (sideContent.rich_text_with_icon !== null) {
    const icon = unwrapSingleton(sideContent.rich_text_with_icon.icon)?.icon_code;

    /* istanbul ignore if */
    if (icon === undefined) {
      nonFatalBuildError(
        'Icon missing',
        "Icon expected for alert notification's rich text side content"
      );
    }

    return {
      icon: icon ?? '',
      heading: sideContent.rich_text_with_icon.heading,
      richText: sideContent.rich_text_with_icon.rich_text,
    };
  }

  /* istanbul ignore next */
  nonFatalBuildError(
    'Invalid side content',
    'Side content must be a link section or rich text with icon for alert notification'
  );
  return undefined;
};

const AlertNotificationQuery = graphql`
  query {
    csAlertNotification {
      title
      main_icon {
        icon_code
      }
      main_content
      left_icon {
        icon_code
      }
      minimised_content
      expand_icon {
        icon_code
      }
      minimise_icon {
        icon_code
      }
      side_content {
        link_section {
          heading
          links {
            text
            url
          }
        }
        rich_text_with_icon {
          icon {
            icon_code
          }
          heading
          rich_text
        }
      }
    }
  }
`;

const AlertNotification: React.FC = () => {
  const { csAlertNotification } = useStaticQuery<AlertNotificationData>(AlertNotificationQuery);

  const alertNotificationExists = csAlertNotification !== null;
  useEffect(() => {
    if (alertNotificationExists) {
      trackAlertNotification(csAlertNotification.title)();
    }
  }, [alertNotificationExists, csAlertNotification]);

  if (!alertNotificationExists) {
    return null;
  }

  const sideContent = processSideContent(csAlertNotification.side_content);

  const mainIcon = unwrapSingleton(csAlertNotification.main_icon)?.icon_code;
  const leftIcon = unwrapSingleton(csAlertNotification.left_icon)?.icon_code;
  const expandIcon = unwrapSingleton(csAlertNotification.expand_icon)?.icon_code;
  const minimiseIcon = unwrapSingleton(csAlertNotification.minimise_icon)?.icon_code;

  /* istanbul ignore if */
  if (mainIcon === undefined) {
    nonFatalBuildError('Icon missing', 'Main icon expected for alert notification');
  }
  if (leftIcon === undefined) {
    nonFatalBuildError('Icon missing', 'Left icon expected for alert notification');
  }
  if (expandIcon === undefined) {
    nonFatalBuildError('Icon missing', 'Expand Icon expected for alert notification');
  }
  if (minimiseIcon === undefined) {
    nonFatalBuildError('Icon missing', 'Minimise Icon expected for alert notification');
  }

  return (
    <div role="dialog" aria-modal="false" aria-label="Alert notification">
      <AlertNotificationComponent
        onClose={() => {
          trackButtonClick('alertNotification', 'Close')();
        }}
        heading={csAlertNotification.title}
        mainIcon={mainIcon ?? ''}
        mainContent={csAlertNotification.main_content}
        sideContent={sideContent}
        data-cy="AlertNotification"
        expandIcon={expandIcon}
        minimisedBannerContent={csAlertNotification.minimised_content}
        leftIcon={leftIcon}
        isExpandable
        minimiseIcon={minimiseIcon}
      />
    </div>
  );
};

export default React.memo(AlertNotification);
