import BreadcrumbsComponent from '@rsa-digital/evo-shared-components/components/Breadcrumbs';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { trackButtonClick } from 'helpers/eventTracking';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { generateJsonLdBreadcrumbs } from '../jsonLd';
import { BreadcrumbsData } from '../types';

type BreadcrumbsProps = {
  breadcrumbs: BreadcrumbsData;
};

export const query = graphql`
  fragment BreadcrumbsAccountArea on cs__account_area_page_page_meta {
    breadcrumbs {
      current_page_name
      parent_links {
        page_name
        url
      }
      hide_breadcrumbs
    }
  }
`;

type Root = {
  csSiteWideSeo: {
    breadcrumbs_account_home_link: {
      url: string;
      text: string;
    };
  };
};

type Site = {
  site: { siteMetadata: { siteUrl: string } };
};

const RootQuery = graphql`
  query {
    csSiteWideSeo {
      breadcrumbs_account_home_link {
        url
        text
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  const { csSiteWideSeo, site } = useStaticQuery<Root & Site>(RootQuery);
  const jsonLdBreadcrumbs = generateJsonLdBreadcrumbs(breadcrumbs, site.siteMetadata.siteUrl);

  const rootLink = csSiteWideSeo.breadcrumbs_account_home_link;

  const breadcrumbsLinks = breadcrumbs.parent_links.map((breadcrumb) => ({
    text: breadcrumb.page_name,
    url: breadcrumb.url,
    onClick: handleAndTrackLinkClick(breadcrumb.url, 'breadcrumb', breadcrumb.page_name),
  }));

  return !breadcrumbs.hide_breadcrumbs ? (
    <>
      <Helmet script={[jsonLdBreadcrumbs]} />
      <BreadcrumbsComponent
        breadcrumbs={breadcrumbsLinks}
        currentPageText={breadcrumbs.current_page_name}
        rootLink={rootLink}
        onBackToTop={trackButtonClick('backToTop', 'Back To Top')}
        data-cy="Breadcrumbs"
      />
    </>
  ) : null;
};

export default React.memo(Breadcrumbs);
