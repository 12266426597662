import { RESET_STATE } from 'state/actions';
import { ErrorAction, UPDATE_ERROR_STATE } from './actions';
import { ErrorState, initialErrorState } from './state';

export const errorReducer = (
  state: ErrorState = initialErrorState,
  action: ErrorAction
): ErrorState => {
  switch (action.type) {
    case UPDATE_ERROR_STATE:
      return action.state;
    case RESET_STATE:
      return initialErrorState;
    default:
      return state;
  }
};
