import { LicenceRestrictionsState } from 'state/forms/licenceRestrictions/state';
import { conditionalValue } from 'helpers/mappingHelpers';

const mapLicenceRestrictions = (licenceRestrictionsState: LicenceRestrictionsState): string[] =>
  conditionalValue(
    licenceRestrictionsState.addLicenceRestrictions,
    licenceRestrictionsState.licenceRestrictions.map((restriction) => restriction.restrictionCode)
  ) ?? [];

export default mapLicenceRestrictions;
