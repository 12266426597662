import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addYearsToDate,
  dateValueToUtcDate,
  isDateValueValidDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export const compareDatesInDescendingOrder = (
  firstDate: Date | null,
  secondDate: Date | null
): number => {
  if (!firstDate && !secondDate) {
    return 0;
  }

  // Any invalid dates get sorted to the end of the array
  if (!firstDate || !secondDate) {
    return firstDate ? -1 : 1;
  }

  return secondDate.getTime() - firstDate.getTime();
};

export const isLessThanTwentyOne = (dateOfBirth: Date): boolean => {
  return dateOfBirth && dateOfBirth > addYearsToDate(new Date(), -21);
};

export const isValidDateLessThanTwentyOne = (dateOfBirth: DateValue): boolean => {
  if (!isDateValueValidDate(dateOfBirth)) return false;
  const dateOfBirthUtc = dateValueToUtcDate(dateOfBirth);
  return !!dateOfBirthUtc && isLessThanTwentyOne(dateOfBirthUtc);
};
