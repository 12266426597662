import Testimonial from '@rsa-digital/evo-shared-components/components/Testimonial';
import { graphql } from 'gatsby';
import React from 'react';

type TestimonialCardsBlockProps = {
  testimonials: {
    heading: string;
    testimonial_cards: {
      quote: string;
      screen_reader_text: string;
    }[];
  };
};

export const query = graphql`
  fragment TestimonialsBlockInfo on cs__info_page_blocks {
    testimonials {
      heading
      testimonial_cards {
        quote
        screen_reader_text
      }
    }
  }
`;

const TestimonialCardsBlock: React.FC<TestimonialCardsBlockProps> = ({ testimonials }) => {
  return (
    <Testimonial
      headerText={testimonials.heading}
      testimonials={testimonials.testimonial_cards.map((testimonialCard) => ({
        quote: testimonialCard.quote,
        screenReaderText: testimonialCard.screen_reader_text,
      }))}
      data-cy="Testimonials"
    />
  );
};

export default React.memo(TestimonialCardsBlock);
