import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import React from 'react';
import { CoverNote, usePolicyState } from 'state/policy/state';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { compareDatesInDescendingOrder } from 'helpers/dateHelpers';
import { trackDocumentDownloadClick } from 'helpers/eventTracking';
import { CoverNotesCard } from './styles';
import { CoverNotesProps } from '../types';

export const query = graphql`
  fragment CoverNotesPolicyAccordion on cs__policy_details_accordion_temporary_drivers_details {
    cover_notes {
      heading
      initially_display
      document_error
      cover_started
      cover_ended
      drivers_on_cover
      document_heading
      icon {
        icon_code
      }
      cover_notes_history {
        heading
        rich_text
      }
    }
  }
`;

const showDateAndTime = (date: Date): string => {
  const hours = date.getHours().toLocaleString('en-GB', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const minutes = date.getMinutes().toLocaleString('en-GB', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${formatLongDateWithDayFirst(date)} ${hours}:${minutes}`;
};

const getSubheading = (
  startDate: Date | null,
  endDate: Date | null,
  startDatePrefix: string,
  endDatePrefix: string
): string | undefined => {
  if (!startDate || !endDate) {
    return '';
  }
  return `${startDatePrefix} ${showDateAndTime(startDate)}, ${endDatePrefix} ${showDateAndTime(
    endDate
  )}`;
};

const getBody = (driverNames: string[], driverPrefix: string): string =>
  `${driverPrefix} ${driverNames.join(', ')}`;

const getUrl = (id: string | null, policyNumber: string | null | undefined): string =>
  `${process.env.GATSBY_API_URL}/policy/${policyNumber}/cover-notes/${id}`;

const CoverNoteCards: React.FC<CoverNotesProps & { coverNotes: CoverNote[] }> = ({
  cover_notes: staticContent,
  coverNotes,
}) => {
  const { policy } = usePolicyState();

  const sortedCoverNotes = [...coverNotes].sort((x, y) =>
    compareDatesInDescendingOrder(y.coverStart, x.coverStart)
  );

  return (
    <div>
      {sortedCoverNotes.map((coverNote, index) => {
        const title = `${staticContent.document_heading} ${index + 1}`;
        return (
          <CoverNotesCard
            data-cy={`CoverNotesCards${index + 1}`}
            key={coverNote.coverNoteNumber}
            heading={title}
            subheading={getSubheading(
              coverNote.coverStart,
              coverNote.coverEnd,
              staticContent.cover_started,
              staticContent.cover_ended
            )}
            body={getBody(coverNote.drivers, staticContent.drivers_on_cover)}
            icon={unwrapSingleton(staticContent.icon)?.icon_code}
            href={getUrl(coverNote.coverNoteNumber, policy?.policyDetails.policyNumber)}
            onClick={trackDocumentDownloadClick(
              title,
              getUrl(coverNote.coverNoteNumber, policy?.policyDetails.policyNumber),
              '',
              index + 1
            )}
            download
          />
        );
      })}
    </div>
  );
};

export default React.memo(CoverNoteCards);
