import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LogoLink from '@rsa-digital/evo-shared-components/components/LogoLink';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: ${spacing(1)} 0;
  background-color: ${colors.neutral08};
  height: ${spacing(7)};

  ${mediaQuery.tabletLandscape`
    height: ${spacing(8)};
  `}
`;

export const Logo = styled(LogoLink)`
  display: flex;
  align-items: center;

  img {
    height: ${spacing(3)};

    ${mediaQuery.tabletLandscape`
      height: ${spacing(3.5)};
    `}
  }
`;
