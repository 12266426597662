import { RESET_STATE } from 'state/actions';
import { initialPolicyState } from 'state/policy/state';
import { LookupPolicyAction, UPDATE_LOOKUP_POLICY } from './actions';
import { initialLookupPolicyState, LookupPolicyState } from './state';

export const lookupPolicyReducer = (
  lookupPolicy: LookupPolicyState = initialLookupPolicyState,
  action: LookupPolicyAction
): LookupPolicyState => {
  switch (action.type) {
    case UPDATE_LOOKUP_POLICY:
      return action.lookupPolicy;
    case RESET_STATE:
      return initialPolicyState;
    default:
      return lookupPolicy;
  }
};
