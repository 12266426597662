import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { SeatStatus } from './state';

export const UPDATE_SEAT_STATUS = 'UPDATE_SEAT_STATUS';

export type SeatStatusAction =
  | UpdateSeatStatusAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateSeatStatusAction = {
  type: typeof UPDATE_SEAT_STATUS;
  update: SeatStatus;
};

export const useSeatStatus = (): [SeatStatus | null, (update: SeatStatus) => void] => {
  const seatStatus = useSelector((state: RootState) => state.seatStatus);
  const dispatch = useDispatch();

  const updateSeatStatusState = useCallback(
    (update: SeatStatus): void => {
      dispatch({ type: UPDATE_SEAT_STATUS, update });
    },
    [dispatch]
  );

  return [seatStatus, updateSeatStatusState];
};
