import AuthorInfo from '@rsa-digital/evo-shared-components/components/AuthorInfo';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';

export type AuthorInfoBlockProps = {
  author_info: {
    author_info: {
      author: string;
      body: string;
      image: CsAsset;
    }[];
  };
};

export const query = graphql`
  fragment AuthorInfoBlockNews on cs__news_article_page_blocks {
    author_info {
      author_info {
        author
        body
        image {
          ...CsAsset
        }
      }
    }
  }
`;

const AuthorInfoBlock: React.FC<AuthorInfoBlockProps> = ({ author_info }) => {
  const { author, body, image } = author_info.author_info[0];
  const processedImage = processImageAsset(image);

  /* istanbul ignore if */
  if (!processedImage) {
    throw new Error('Image missing for author info block!');
  }

  return <AuthorInfo author={author} body={body} image={processedImage} data-cy="AuthorInfo" />;
};

export default React.memo(AuthorInfoBlock);
