import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { AboutTheDriverState } from './state';

export const UPDATE_ABOUT_THE_DRIVER = 'UPDATE_ABOUT_THE_DRIVER';

export type AboutTheDriverAction =
  | UpdateAboutTheDriverAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateAboutTheDriverAction = {
  type: typeof UPDATE_ABOUT_THE_DRIVER;
  update: Partial<AboutTheDriverState>;
};

export const useAboutTheDriver = (): [
  AboutTheDriverState,
  (update: Partial<AboutTheDriverState>) => void
] => {
  const aboutTheDriverState = useSelector((state: RootState) => state.aboutTheDriver);
  const dispatch = useDispatch();

  const updateAboutTheDriverState = useCallback(
    (update: Partial<AboutTheDriverState>): void => {
      dispatch({ type: UPDATE_ABOUT_THE_DRIVER, update });
    },
    [dispatch]
  );

  return [aboutTheDriverState, updateAboutTheDriverState];
};
