import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

const MAX_NUMBER_OF_NAMED_DRIVERS = 3;

type PolicyUpdateAccessHelpers = {
  hasMaxNamedDrivers: boolean;
  hasOpenDriving: boolean;
  updatesBlockedForCustomer: boolean;
  updatesBlockedForAll: boolean;
  hasTemporaryDriver: boolean;
};

const policyUpdateAccessSelector = (state: RootState): PolicyUpdateAccessHelpers => {
  const hasMaxNamedDrivers =
    (state.policy.policy?.namedDrivers?.length || 0) >= MAX_NUMBER_OF_NAMED_DRIVERS;

  const hasOpenDriving = !!state.policy.policy?.policyDetails?.openDriving;

  const updatesBlockedForCustomer = !!state.policy.policy?.policyDetails
    ?.policyUpdatesBlockedForCustomers;
  const updatesBlockedForAll = !!state.policy.policy?.policyDetails?.policyUpdatesBlockedForAll;

  const hasTemporaryDriver = !!state.policy.policy?.temporaryDrivers.length;

  return {
    hasMaxNamedDrivers,
    hasOpenDriving,
    updatesBlockedForCustomer,
    updatesBlockedForAll,
    hasTemporaryDriver,
  };
};

const usePolicyUpdateAccess = (): PolicyUpdateAccessHelpers =>
  useSelector(policyUpdateAccessSelector);

export default usePolicyUpdateAccess;
