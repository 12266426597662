import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';

export type LookupPolicyState = {
  policyNumber: string | null;
  lookupStatus: LookupStatus;
};

export const initialLookupPolicyState: LookupPolicyState = {
  policyNumber: null,
  lookupStatus: LookupStatus.Pending,
};
