import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { DrivingOffencesState } from './state';

export const UPDATE_DRIVING_OFFENCES = 'UPDATE_DRIVING_OFFENCES';

export type DrivingOffencesAction =
  | UpdateDrivingOffencesAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateDrivingOffencesAction = {
  type: typeof UPDATE_DRIVING_OFFENCES;
  update: Partial<DrivingOffencesState>;
};

export const useDrivingOffences = (): [
  DrivingOffencesState,
  (update: Partial<DrivingOffencesState>) => void
] => {
  const drivingOffences = useSelector((state: RootState) => state.drivingOffences);
  const dispatch = useDispatch();

  const updateDrivingOffences = useCallback(
    (update: Partial<DrivingOffencesState>): void => {
      dispatch({ type: UPDATE_DRIVING_OFFENCES, update });
    },
    [dispatch]
  );

  return [drivingOffences, updateDrivingOffences];
};
