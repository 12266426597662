import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import TabbedTable from '@rsa-digital/evo-shared-components/components/TabbedTable';
import { graphql } from 'gatsby';
import React from 'react';
import { trackAwardTabClick } from 'helpers/eventTracking';
import { GridWithMargins, Heading, Subheading } from './styles';

type TabbedTableData = {
  tab_title: string;
  headings: string[];
  rows: { title: string; values: string[] }[];
};

type TabbedTableSectionBlockProps = {
  tabbed_table_section: {
    tables: { tabbed_table: TabbedTableData }[];
    heading: string;
    subheading: string;
  };
};

export const query = graphql`
  fragment TabbedTableSectionBlockInfo on cs__info_page_blocks {
    tabbed_table_section {
      heading
      subheading
      tables {
        tabbed_table {
          headings
          rows {
            values
            title
          }
          tab_title
        }
      }
    }
  }
`;

const TabbedTableSectionBlock: React.FC<TabbedTableSectionBlockProps> = ({
  tabbed_table_section,
}) => {
  const tables = tabbed_table_section.tables.map(({ tabbed_table }) => ({
    tabText: tabbed_table.tab_title,
    table: { headings: tabbed_table.headings, rows: tabbed_table.rows },
  }));

  const onTabClicked = (index: number): void => {
    const table = tables[index];
    trackAwardTabClick(
      table.tabText,
      table.table.rows.length,
      table.table.rows.map((award) => award.values[0])
    )();
  };

  return (
    <GridWithMargins>
      <GridItem desktop={10} tabletLandscape={10} data-cy="TabbedTableSection">
        <Heading>{tabbed_table_section.heading}</Heading>
        <Subheading>{tabbed_table_section.subheading}</Subheading>
        <TabbedTable tables={tables} onTabClick={onTabClicked} data-cy="TabbedTable" />
      </GridItem>
    </GridWithMargins>
  );
};

export default React.memo(TabbedTableSectionBlock);
