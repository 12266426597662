import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { PermanentDriverAgreementAction, UPDATE_PERMANENT_DRIVER_AGREEMENT } from './action';
import { initialPermanentDriverAgreementState, PermanentDriverAgreementState } from './state';

export const permanentDriverAgreementReducer = (
  permanentDriverAgreementState: PermanentDriverAgreementState = initialPermanentDriverAgreementState,
  action: PermanentDriverAgreementAction
): PermanentDriverAgreementState => {
  switch (action.type) {
    case UPDATE_PERMANENT_DRIVER_AGREEMENT:
      return {
        ...permanentDriverAgreementState,
        ...action.update,
      };
    case RESET_STATE:
      return initialPermanentDriverAgreementState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialPermanentDriverAgreementState;
    default:
      return permanentDriverAgreementState;
  }
};
