import { RESET_STATE } from 'state/actions';
import { ManagedPolicyAction, UPDATE_MANAGED_POLICY } from './actions';
import { initialManagedPolicyState, ManagedPolicyState } from './state';

export const managedPolicyReducer = (
  managedPolicyState: ManagedPolicyState = initialManagedPolicyState,
  action: ManagedPolicyAction
): ManagedPolicyState => {
  switch (action.type) {
    case UPDATE_MANAGED_POLICY:
      return { policyNumber: action.policyNumber };
    case RESET_STATE:
      return initialManagedPolicyState;
    default:
      return managedPolicyState;
  }
};
