import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import createStore from './createStore';
import { ReplaceDriverState } from './forms/replaceDriver/state';
import { ManagedPolicyState } from './managedPolicy/state';
import { PolicyState } from './policy/state';

declare global {
  interface Window {
    Cypress: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    store: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    // initialPolicyState and initialReplaceDriverState
    // property on window is utilised during testing within 'cy.visit -> onBeforeLoad'
    // to pre-populate policy details in state on "AddDriver" forms
    initialPolicyState?: PolicyState;
    initialManagedPolicyState?: ManagedPolicyState;
    initialReplaceDriverState?: ReplaceDriverState;
  }
}

const StoreProvider: React.FC = ({ children }) => {
  const store = createStore();

  useEffect(() => {
    // expose store when run in Cypress
    if (window.Cypress) {
      window.store = store;
    }
  }, [store]);

  return <Provider store={store}>{children}</Provider>;
};

export default StoreProvider;
