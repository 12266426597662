import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta } from 'types/contentStack';
import { StyledSimpleDivider } from '../Divider/styles';

type FeaturesBlockProps = {
  features: {
    features: {
      title: string;
      body: string;
      image: CsAsset | null;
      cta: [CsCta] | null;
    }[];
    layout: 'Z shaped' | 'Vertical' | 'News highlight' | 'Inverted z shaped';
  };
};

export const query = graphql`
  fragment FeaturesBlockInfo on cs__info_page_blocks {
    features {
      features {
        title
        body
        image {
          ...CsAsset
        }
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
      layout
    }
  }
`;

const FeaturesBlock: React.FC<FeaturesBlockProps> = ({ features }) => {
  return (
    <>
      {features.features?.map((feature, index, array) => {
        const invertContent =
          (features.layout === 'Z shaped' && index % 2 === 1) ||
          (features.layout === 'Inverted z shaped' && index % 2 === 0);
        const insertDivider = array.length - 1 !== index; // check for final feature

        const image = processImageAsset(feature.image);

        return (
          <Fragment key={feature.title}>
            <Feature
              title={feature.title}
              body={feature.body}
              image={image}
              cta={processOptionalCta(feature.cta, 'Body')}
              invertContent={invertContent}
              renderImageAsLink={false}
              data-cy={`Feature${index}`}
              data-cy-inverted={invertContent}
            />
            {insertDivider && <StyledSimpleDivider data-cy={`FeatureDivider${index}`} />}
          </Fragment>
        );
      })}
    </>
  );
};

export default React.memo(FeaturesBlock);
