import { InfoCardGrid } from '@rsa-digital/evo-shared-components/components/InfoCard';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta, Icon } from 'types/contentStack';
import { InfoCardContainer, StyledInfoCard } from './styles';

type InfoCardsProps = {
  cards: InfoCardsCsContent['info_cards']['info_cards'];
  alignLeft?: boolean;
} & ComponentProps;

export type InfoCardsCsContent = {
  info_cards: {
    info_cards: {
      header_icon: [Icon] | [];
      header_text: string;
      image: CsAsset | null;
      body_text: string;
      cta: [CsCta] | null;
    }[];
  };
};

export const query = graphql`
  fragment InfoCardsKnockout on cs__info_cards_set {
    info_cards {
      info_cards {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const InfoCards: React.FC<InfoCardsProps> = ({ cards, alignLeft, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <InfoCardContainer {...componentProps(props)}>
    <InfoCardGrid data-cy="InfoCards" alignLeft={alignLeft}>
      {cards.map(({ header_text, header_icon, body_text, cta, image }, index) => (
        <StyledInfoCard
          data-cy={`InfoCard${index}`}
          key={header_text}
          headerIcon={header_icon.length !== 0 ? header_icon[0].icon_code : undefined}
          heading={header_text}
          body={body_text}
          primaryCta={processOptionalCta(cta, 'Body')}
          logo={processImageAsset(image)}
        />
      ))}
    </InfoCardGrid>
  </InfoCardContainer>
);

export default React.memo(InfoCards);
