import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { DriverConsentState } from './state';

export const UPDATE_DRIVER_CONSENT = 'UPDATE_DRIVER_CONSENT';

export type DriverConsentAction =
  | UpdateDriverConsentAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateDriverConsentAction = {
  type: typeof UPDATE_DRIVER_CONSENT;
  update: Partial<DriverConsentState>;
};

export const useDriverConsent = (): [
  DriverConsentState,
  (update: Partial<DriverConsentState>) => void
] => {
  const driverConsentState = useSelector((state: RootState) => state.driverConsent);
  const dispatch = useDispatch();

  const updateDriverConsentState = useCallback(
    (update: Partial<DriverConsentState>): void => {
      dispatch({ type: UPDATE_DRIVER_CONSENT, update });
    },
    [dispatch]
  );

  return [driverConsentState, updateDriverConsentState];
};
