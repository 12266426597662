import {
  RowTitle,
  RowValueText,
  StyledTd,
  StyledTh,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import { Tooltip } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import { graphql } from 'gatsby';
import React from 'react';
import { usePolicyState } from 'state/policy/state';
import { trackTooltipClick } from 'helpers/eventTracking';
import Table from '../Table';
import { StyledTrWithVerticalAlign, TitleWithMargin } from '../styles';
import { PersonalDetailsProps } from '../types';

export const query = graphql`
  fragment PersonalDetailsPolicyAccordion on cs__policy_details_accordion_accordion_entries {
    personal_details {
      heading
      initially_display
      name
      address
      address_tooltip {
        body
        button_text {
          open
          close
        }
      }
    }
  }
`;

const PersonalDetailsTable: React.FC<PersonalDetailsProps> = ({ personal_details }) => {
  const { policy } = usePolicyState();

  if (!policy) {
    return null;
  }

  return (
    <Table>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <b>{personal_details.name}</b>
          </RowTitle>
        </StyledTh>
        <StyledTd>
          <RowValueText>
            {policy.personalDetails.fullName ??
              `${policy.personalDetails.firstName} ${policy.personalDetails.lastName}`}
          </RowValueText>
        </StyledTd>
      </StyledTrWithVerticalAlign>
      <StyledTrWithVerticalAlign>
        <StyledTh>
          <RowTitle>
            <TitleWithMargin>{personal_details.address}</TitleWithMargin>
            <Tooltip
              body={personal_details.address_tooltip.body}
              buttonText={personal_details.address_tooltip.button_text}
              onExpand={trackTooltipClick(
                'Expand',
                personal_details.address_tooltip.button_text.open,
                personal_details.address
              )}
              onCollapse={trackTooltipClick(
                'Collapse',
                personal_details.address_tooltip.button_text.close,
                personal_details.address
              )}
            />
          </RowTitle>
        </StyledTh>
        <StyledTd>
          {policy.personalDetails.address?.addressLine1 && (
            <RowValueText>{policy.personalDetails.address?.addressLine1}</RowValueText>
          )}
          {policy.personalDetails.address?.addressLine2 && (
            <RowValueText>{policy.personalDetails.address?.addressLine2}</RowValueText>
          )}
          {policy.personalDetails.address?.addressLine3 && (
            <RowValueText>{policy.personalDetails.address?.addressLine3}</RowValueText>
          )}
          {policy.personalDetails.address?.addressLine4 && (
            <RowValueText>{policy.personalDetails.address?.addressLine4}</RowValueText>
          )}
          {policy.personalDetails.address?.postCode && (
            <RowValueText>{policy.personalDetails.address?.postCode}</RowValueText>
          )}
        </StyledTd>
      </StyledTrWithVerticalAlign>
    </Table>
  );
};

export default React.memo(PersonalDetailsTable);
