import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { documentFileSizeDisplay } from '@rsa-digital/evo-shared-components/components/DocumentDownloadButton';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import React from 'react';
import { useDocumentsState } from 'state/document/state';
import {
  processMandatoryAsset,
  processOptionalCta,
  unwrapSingleton,
} from 'helpers/csTypeProcessors';
import { trackDocumentDownloadClick } from 'helpers/eventTracking';
import usePolicyNumber from 'helpers/usePolicyNumber';
import { ErrorText, PolicyDocumentCard, StyledRichText } from './styles';
import { PolicyDocumentsProps } from '../types';

export const query = graphql`
  fragment PolicyDocumentsPolicyAccordion on cs__policy_details_accordion_accordion_entries {
    policy_documents {
      heading
      initially_display
      document_error
      no_documents
      date_added_prefix
      icon {
        icon_code
      }
      api_document {
        filename
        heading
        summary
      }
      cs_documents {
        heading
        summary
        document {
          ...CsAsset
        }
      }
      request_documents {
        body
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const getSubheading = (date: Date | string | null, dateAddPrefix: string): string | undefined => {
  if (!date) {
    return undefined;
  }
  return dateAddPrefix
    ? `${dateAddPrefix} ${formatLongDateWithDayFirst(date)}`
    : formatLongDateWithDayFirst(date);
};

const ApiDocument: React.FC<PolicyDocumentsProps> = ({ policy_documents }) => {
  const policyDocuments = useDocumentsState();
  const policyNumber = usePolicyNumber();
  if (policyDocuments === null) {
    return <ErrorText>{policy_documents.document_error}</ErrorText>;
  }

  if (policyDocuments.length === 0) {
    return <ErrorText>{policy_documents.no_documents}</ErrorText>;
  }

  // the api document is the most recent document (which is the first document)
  const apiPolicyUrl = `${
    process.env.GATSBY_API_URL
  }/policy/${policyNumber}/documents/${encodeURIComponent(policyDocuments[0].id)}`;

  return (
    <PolicyDocumentCard
      data-cy="PolicyDocumentCards0"
      key={policy_documents.api_document.heading}
      heading={policy_documents.api_document.heading}
      subheading={getSubheading(
        policyDocuments[0].documentDate,
        policy_documents.date_added_prefix
      )}
      body={policy_documents.api_document.summary}
      icon={unwrapSingleton(policy_documents.icon)?.icon_code}
      href={apiPolicyUrl}
      onClick={trackDocumentDownloadClick(
        policy_documents.api_document.heading,
        apiPolicyUrl,
        '',
        0
      )}
      download={policy_documents.api_document.filename}
    />
  );
};

const PolicyDocumentsSection: React.FC<PolicyDocumentsProps> = ({ policy_documents }) => {
  const cta = processOptionalCta(policy_documents.request_documents.cta, 'Body');

  return (
    <div>
      <ApiDocument policy_documents={policy_documents} />
      {policy_documents.cs_documents.map((csDocument, index) => {
        const doc = processMandatoryAsset(csDocument.document);
        /* istanbul ignore if */
        if (!doc) {
          throw new Error('Document missing for policy documents');
        }
        return (
          <PolicyDocumentCard
            data-cy={`PolicyDocumentCards${index + 1}`}
            key={csDocument.heading}
            heading={csDocument.heading}
            subheading={getSubheading(
              csDocument.document.updated_at,
              policy_documents.date_added_prefix
            )}
            body={csDocument.summary}
            icon={unwrapSingleton(policy_documents.icon)?.icon_code}
            href={doc.publicUrl}
            onClick={trackDocumentDownloadClick(
              csDocument.heading,
              doc.publicUrl,
              documentFileSizeDisplay(doc.fileSize),
              index + 1
            )}
            download={doc.filename}
          />
        );
      })}
      <StyledRichText html={policy_documents.request_documents.body} />
      {cta && <PrimaryCta cta={cta} />}
    </div>
  );
};

export default React.memo(PolicyDocumentsSection);
