import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { ReplaceDriverAction, UPDATE_REPLACE_DRIVER } from './action';
import { initialReplaceDriverState, ReplaceDriverState } from './state';

export const replaceDriverReducer = (
  replaceDriverState: ReplaceDriverState = initialReplaceDriverState,
  action: ReplaceDriverAction
): ReplaceDriverState => {
  switch (action.type) {
    case UPDATE_REPLACE_DRIVER:
      return {
        ...replaceDriverState,
        ...action.update,
      };
    case RESET_STATE:
      return initialReplaceDriverState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialReplaceDriverState;
    default:
      return replaceDriverState;
  }
};
