import { helmetJsonLdProp } from 'react-schemaorg';
import { BreadcrumbList, ListItem } from 'schema-dts';
import { BreadcrumbsData } from './types';

export const generateJsonLdBreadcrumbs = (
  breadcrumbs: BreadcrumbsData,
  siteUrl: string
): {
  type: 'application/ld+json';
  innerHTML: string;
} => {
  // 'position' field uses a 1-based index
  const breadcrumbListItems: ListItem[] = breadcrumbs.parent_links.map((breadcrumb, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    name: breadcrumb.page_name,
    item: new URL(breadcrumb.url, siteUrl).href,
  }));
  breadcrumbListItems.push({
    '@type': 'ListItem',
    position: breadcrumbs.parent_links.length + 1,
    name: breadcrumbs.current_page_name,
  });
  return helmetJsonLdProp<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbListItems,
  });
};
