import ImageAndCtaCards from '@rsa-digital/evo-shared-components/components/ImageAndCtaCards';
import { graphql } from 'gatsby';
import React from 'react';
import { processMandatoryImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta } from 'types/contentStack';

type ImageAndCtaCardsBlockProps = {
  image_and_cta_cards: {
    heading: string;
    cards: ImageAndCtaCardData[];
  };
};

type ImageAndCtaCardData = {
  image: CsAsset;
  heading: string;
  body: string;
  primary_cta: [CsCta] | null;
  secondary_cta: [CsCta] | null;
};

export const query = graphql`
  fragment ImageAndCtaCardsBlockInfo on cs__info_page_blocks {
    image_and_cta_cards {
      heading
      cards {
        heading
        body
        image {
          ...CsAsset
        }
        primary_cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
        secondary_cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const ImageAndCtaCardsBlock: React.FC<ImageAndCtaCardsBlockProps> = ({ image_and_cta_cards }) => {
  const cards = image_and_cta_cards.cards.map((card) => {
    const image = processMandatoryImageAsset(card.image);
    const primaryCta = processOptionalCta(card.primary_cta, 'Body');
    const secondaryCta = processOptionalCta(card.secondary_cta, 'Body');
    return {
      image,
      heading: card.heading,
      body: card.body,
      primaryCta,
      secondaryCta,
    };
  });

  return (
    <ImageAndCtaCards
      heading={image_and_cta_cards.heading}
      cards={cards}
      data-cy="ImageAndCtaCards"
    />
  );
};

export default React.memo(ImageAndCtaCardsBlock);
