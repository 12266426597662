import { useCallback } from 'react';
import useDispatch from 'state/useDispatch';
import { DocumentsState } from './state';

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';

export type DocumentsAction = UpdateDocumentsAction;

export type UpdateDocumentsAction = {
  type: typeof UPDATE_DOCUMENTS;
  documents: DocumentsState;
};

export const useUpdateDocuments = (): ((newDocumentsState: DocumentsState) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (newDocumentsState: DocumentsState): void => {
      dispatch({ type: UPDATE_DOCUMENTS, documents: newDocumentsState });
    },
    [dispatch]
  );
};
