import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const LeadParagraph = styled(RichText)`
  ${fonts.headingXSmall}
  &:not(:last-child) {
    margin: 0 0 ${spacing(3)};
  }
`;

export const SubParagraph = styled(RichText)`
  ${fonts.paragraph}
  &:not(:last-child) {
    margin: 0 0 ${spacing(4)};
  }
`;

export const GridWithMargins = styled(Grid)`
  & {
    margin-bottom: ${spacing(6)};
  }
`;
