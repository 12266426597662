import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { SeatStatusAction, UPDATE_SEAT_STATUS } from './action';
import { initialSeatStatusState, SeatStatus } from './state';

export const seatStatusReducer = (
  newDriversCoverState: SeatStatus | null = initialSeatStatusState,
  action: SeatStatusAction
): SeatStatus | null => {
  switch (action.type) {
    case UPDATE_SEAT_STATUS:
      return action.update;
    case RESET_STATE:
      return null;
    case RESET_ADD_A_DRIVER_STATE:
      return null;
    default:
      return newDriversCoverState;
  }
};
