import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import { useLoadingState } from 'state/loading/state';
import { SessionState, useUserState } from 'state/user/state';
import AlertNotification from 'components/AlertNotification';
import Analytics from 'components/Analytics';
import AccountBreadcrumbsComponent from 'components/Breadcrumbs/AccountBreadcrumbs';
import BreadcrumbsComponent from 'components/Breadcrumbs/BrochurewareBreadcrumbs';
import { BreadcrumbsData } from 'components/Breadcrumbs/types';
import AccountFooterComponent from 'components/Footer/AccountFooter';
import FooterComponent from 'components/Footer/BrochurewareFooter';
import AccountHeader from 'components/Header/AccountHeader';
import BrochurewareHeader from 'components/Header/BrochurewareHeader';
import SignedOutHeader from 'components/Header/SignedOutHeader';
import StaffHeader from 'components/Header/StaffHeader';
import LoadingOverlay from 'components/LoadingOverlay';
import Meta, { MetaTags } from 'components/Meta';
import { reloadOneTrust } from 'helpers/oneTrustHelper';
import RefreshButton from './refresh';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export type PageType =
  | 'accountArea'
  | 'brochureware'
  | 'signedOut'
  | 'form'
  | 'staffAreaForm'
  | 'staffAreaHome';

const Header: React.FC<{
  pageType: PageType;
  showStickyHeader?: boolean;
}> = ({ pageType, showStickyHeader }) => {
  switch (pageType) {
    case 'brochureware':
      return <BrochurewareHeader showStickyHeader={showStickyHeader} />;
    case 'accountArea':
    case 'form':
      return <AccountHeader showStickyHeader={showStickyHeader} />;
    case 'staffAreaForm':
      return <StaffHeader showStickyHeader={showStickyHeader} />;
    case 'staffAreaHome':
      return <StaffHeader showStickyHeader={showStickyHeader} hideSearchButton />;
    case 'signedOut':
      return <SignedOutHeader />;
    default:
      return null;
  }
};

const Footer: React.FC<{
  pageType: PageType;
  complianceText?: string;
}> = ({ pageType, complianceText }) => {
  switch (pageType) {
    case 'brochureware':
      return <FooterComponent complianceText={complianceText} />;
    case 'accountArea':
    case 'staffAreaForm':
    case 'staffAreaHome':
    case 'form':
      return <AccountFooterComponent complianceText={complianceText} />;
    case 'signedOut':
    default:
      return null;
  }
};

const Breadcrumbs: React.FC<{
  pageType: PageType;
  breadcrumbs: BreadcrumbsData;
}> = ({ pageType, breadcrumbs }) => {
  switch (pageType) {
    case 'brochureware':
      return <BreadcrumbsComponent breadcrumbs={breadcrumbs} />;
    case 'accountArea':
      return <AccountBreadcrumbsComponent breadcrumbs={breadcrumbs} />;
    case 'signedOut':
    case 'form':
    case 'staffAreaForm':
    case 'staffAreaHome':
    default:
      return null;
  }
};

const Layout: React.FC<{
  pageType: PageType;
  meta: MetaTags;
  breadcrumbs?: BreadcrumbsData;
  complianceText?: string;
  showStickyHeader?: boolean;
  showAlertNotification?: boolean;
}> = ({
  pageType,
  meta,
  breadcrumbs,
  complianceText,
  showStickyHeader,
  showAlertNotification,
  children,
}) => {
  const { isLoading } = useLoadingState();
  const { sessionState, isStaffMember } = useUserState();

  useEffect(() => {
    reloadOneTrust();
  }, []);

  const isSessionLoading = sessionState === SessionState.Loading;

  return (
    <>
      <Meta meta={meta} />
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      {(isLoading || isSessionLoading) && <LoadingOverlay />}
      <Header
        pageType={pageType === 'form' && isStaffMember ? 'staffAreaForm' : pageType}
        showStickyHeader={showStickyHeader}
      />
      {showAlertNotification && <AlertNotification />}
      <GridContainer as="main">
        {!isSessionLoading && children}
        {breadcrumbs && <Breadcrumbs pageType={pageType} breadcrumbs={breadcrumbs} />}
        <Footer pageType={pageType} complianceText={complianceText} />
      </GridContainer>
    </>
  );
};

export default Layout;
