import React from 'react';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const oneTrustProduction = '02e5c02d-427e-407d-a093-fb06ba02575d';
const oneTrustMotability1 = 'c806cc57-5a1a-4547-97a6-21e5365f070d';
const oneTrustStaging = '02e5c02d-427e-407d-a093-fb06ba02575d-test';

const getDomainScript = (env: string | undefined): string => {
  switch (env) {
    case 'production':
      return oneTrustProduction;
    case 'motability1production':
      return oneTrustMotability1;
    default:
      return oneTrustStaging;
  }
};

export const oneTrustCdnScript: JSX.Element = (
  <script
    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    type="text/javascript"
    charSet="UTF-8"
    data-domain-script={getDomainScript(analyticsEnv)}
  />
);

export const oneTrustWrapperScript: JSX.Element = (
  <script type="text/javascript">function OptanonWrapper() &#123; &#125;</script>
);
