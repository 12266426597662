import { CardColumnSpanCalculator } from '@rsa-digital/evo-shared-components/components/CardGrid';
import { columnSpanCalculator as infoCardColumnSpanCalculator } from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCardGrid';
import fluidGridColumns from '@rsa-digital/evo-shared-components/theme/fluidGridColumns';
import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { CsAsset, CsCta, Icon } from 'types/contentStack';
import { Heading, InfoCardContainer, StyledCardGrid, StyledInfoCard } from './styles';

type InfoCardsBlockProps = {
  info_cards_home_page: {
    heading: string;
    info_cards: {
      header_icon: [Icon] | [];
      header_text: string;
      image: CsAsset | null;
      body_text: string;
      cta: [CsCta] | null;
    }[];
  };
};
export const query = graphql`
  fragment InfoCardsHomePageBlockInfo on cs__info_page_blocks {
    info_cards_home_page {
      heading
      info_cards {
        header_icon {
          icon_code
        }
        header_text
        image {
          ...CsAsset
        }
        body_text
        cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
      }
    }
  }
`;

const columnSpanCalculator: CardColumnSpanCalculator = (index: number, totalCards: number) => ({
  ...infoCardColumnSpanCalculator(index, totalCards),
  tabletPortrait: fluidGridColumns.tabletPortrait,
});

const InfoCardsHomePageBlock: React.FC<InfoCardsBlockProps> = ({ info_cards_home_page }) => (
  <InfoCardContainer>
    <Heading>{info_cards_home_page.heading}</Heading>
    <StyledCardGrid data-cy="InfoCardsHomePage" columnSpanCalculator={columnSpanCalculator}>
      {info_cards_home_page.info_cards.map(
        ({ header_text, header_icon, body_text, cta, image }) => (
          <StyledInfoCard
            key={header_text}
            headerIcon={header_icon.length !== 0 ? header_icon[0].icon_code : undefined}
            heading={header_text}
            body={body_text}
            secondaryCta={processOptionalCta(cta, 'Body')}
            logo={processImageAsset(image)}
          />
        )
      )}
    </StyledCardGrid>
  </InfoCardContainer>
);

export default React.memo(InfoCardsHomePageBlock);
