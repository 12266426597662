import { useCallback } from 'react';
import { DriverType } from 'types/forms';
import { useBlockedUpdatesHandler } from './blockedUpdateHelpers';
import usePolicyUpdateAccess from './usePolicyUpdateAccess';

type PermanentDriverActionHandler = (
  onPermanentDriversLocked: () => void,
  onDriverActionAllowed: () => void,
  staffActionAllowedCallbackHandler: (callback: () => void) => void
) => void;

const usePermanentDriverActionHandler = (): PermanentDriverActionHandler => {
  const policyUpdateAccess = usePolicyUpdateAccess();
  const blockedUpdatesHandler = useBlockedUpdatesHandler(DriverType.PERMANENT);

  return useCallback(
    (
      onPermanentDriversLocked: () => void,
      onDriverActionAllowed: () => void,
      staffConfirmationRequiredHandler: (callback: () => void) => void
    ) => {
      const onSuccess = (): void => {
        if (policyUpdateAccess.hasTemporaryDriver) {
          onPermanentDriversLocked();
        } else {
          onDriverActionAllowed();
        }
      };
      blockedUpdatesHandler(onSuccess, staffConfirmationRequiredHandler);
    },
    [blockedUpdatesHandler, policyUpdateAccess.hasTemporaryDriver]
  );
};

export default usePermanentDriverActionHandler;
