import {
  RowValueText,
  StyledTd,
  StyledTh,
} from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import React from 'react';
import { NamedDriver, TemporaryDriver } from 'state/policy/state';
import { getTemporaryDriverTitle } from 'helpers/accordionTitleHelpers';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import {
  ButtonContainer,
  IconWithRightMargin,
  RedStyledTertiaryButton,
  RowTitle,
  StyledExpandableCard,
  StyledTable,
  StyledTrWithNoTopPadding,
} from '../../styles';
import { TemporaryDriversData } from '../../types';

type TemporaryDriverProps = {
  temporaryDriversData: TemporaryDriversData;
  driver: TemporaryDriver;
  replacedDriver: NamedDriver | undefined;
  index: number;
  onRemove: () => void;
  showModifyDriverButtons: boolean;
};

const TemporaryDriverCard: React.FC<TemporaryDriverProps> = ({
  temporaryDriversData,
  driver,
  replacedDriver,
  index,
  onRemove,
  showModifyDriverButtons,
}) => {
  const isCurrentTemp = !!driver.coverStart && driver.coverStart <= new Date();

  const cardTitle = getTemporaryDriverTitle(
    driver,
    isCurrentTemp,
    replacedDriver,
    temporaryDriversData.replaced_driver_suffix,
    temporaryDriversData.upcoming_temporary_drivers.not_yet_active_suffix,
    temporaryDriversData.upcoming_temporary_drivers.will_replace_suffix
  );

  return (
    <StyledExpandableCard
      data-cy={`TempDriverExpandableCard${index}`}
      title={cardTitle}
      onExpand={trackAccordionExpandClick('Policy', `Temporary drivers - ${driver.fullName}`)}
      onCollapse={trackAccordionCollapseClick('Policy', `Temporary drivers - ${driver.fullName}`)}
      idSuffix={index.toString()}>
      <StyledTable>
        <StyledTrWithNoTopPadding>
          <StyledTh>
            <RowTitle>
              <b>{temporaryDriversData.cover_start}</b>
            </RowTitle>
          </StyledTh>
          <StyledTd>
            <RowValueText>
              {driver.coverStart ? formatLongDateWithDayFirst(driver.coverStart) : ''}
            </RowValueText>
          </StyledTd>
        </StyledTrWithNoTopPadding>
        <StyledTrWithNoTopPadding>
          <StyledTh>
            <RowTitle>
              <b>{temporaryDriversData.cover_end}</b>
            </RowTitle>
          </StyledTh>
          <StyledTd>
            <RowValueText>
              {driver.coverEnd ? formatLongDateWithDayFirst(driver.coverEnd) : ''}
            </RowValueText>
          </StyledTd>
        </StyledTrWithNoTopPadding>
      </StyledTable>
      {showModifyDriverButtons && (
        <ButtonContainer>
          <RedStyledTertiaryButton
            onClick={onRemove}
            screenReaderText={temporaryDriversData.remove_driver.alt_text}
            data-cy={`RemoveTempDriverButton${index}`}>
            <IconWithRightMargin name="remove" size="small" color={colors.notificationError} />
            {temporaryDriversData.remove_driver.display_text}
          </RedStyledTertiaryButton>
        </ButtonContainer>
      )}
    </StyledExpandableCard>
  );
};

export default TemporaryDriverCard;
