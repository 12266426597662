import apiClient from './apiClient';
import {
  DrivingOffencesReferenceData,
  DrivingOffencesReferenceDataResponse,
  mapDrivingOffencesRefData,
} from './referenceData/drivingOffences';
import {
  IncidentTypesReferenceData,
  IncidentTypesReferenceDataResponse,
  mapIncidentTypesRefData,
} from './referenceData/incidentTypes';
import {
  LicenceRestrictionsReferenceData,
  LicenceRestrictionsReferenceDataResponse,
  mapLicenceRestrictionsRefData,
} from './referenceData/licenceRestrictions';
import {
  mapTitlesRefData,
  TitlesReferenceData,
  TitlesReferenceDataResponse,
} from './referenceData/titles';

type ReferenceDataClient = {
  titles: () => Promise<TitlesReferenceData>;
  drivingOffences: () => Promise<DrivingOffencesReferenceData>;
  incidentTypes: () => Promise<IncidentTypesReferenceData>;
  licenceRestrictions: () => Promise<LicenceRestrictionsReferenceData>;
};

const referenceDataClient: ReferenceDataClient = {
  titles: () =>
    apiClient.get<TitlesReferenceDataResponse>('referencedata/titles').then(mapTitlesRefData),
  drivingOffences: () =>
    apiClient
      .get<DrivingOffencesReferenceDataResponse>('referencedata/driving-offences')
      .then(mapDrivingOffencesRefData),
  incidentTypes: () =>
    apiClient
      .get<IncidentTypesReferenceDataResponse>('referencedata/incident-types')
      .then(mapIncidentTypesRefData),
  licenceRestrictions: () =>
    apiClient
      .get<LicenceRestrictionsReferenceDataResponse>('referencedata/restriction-codes')
      .then(mapLicenceRestrictionsRefData),
};

export default referenceDataClient;
