import { dateValueToISOString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { DrivingOffence, DrivingOffencesState } from 'state/forms/drivingOffences/state';
import { conditionalValue } from 'helpers/mappingHelpers';
import { BanData, DrivingOffenceData } from '../types';

const getBanData = (
  banLength: string | undefined,
  banLengthFormat: string
): BanData | undefined => {
  if (banLength && banLengthFormat) {
    return { banDurationType: banLengthFormat, banDuration: Number(banLength) };
  }
  return undefined;
};

const mapDrivingOffence = (drivingOffence: DrivingOffence): DrivingOffenceData => {
  return {
    offenceDate: dateValueToISOString(drivingOffence.offenceDate),
    conviction: {
      convictionDate: dateValueToISOString(drivingOffence.convictionDate),
      convictionCode: drivingOffence.convictionCode,
      penaltyPoints: drivingOffence.penaltyPoints,
      ban: conditionalValue(
        drivingOffence.banResult,
        getBanData(drivingOffence.banLength, drivingOffence.banLengthFormat)
      ),
    },
  } as DrivingOffenceData;
};

const mapDrivingOffences = (drivingOffencesState: DrivingOffencesState): DrivingOffenceData[] =>
  conditionalValue(
    drivingOffencesState.addDrivingOffences,
    drivingOffencesState.drivingOffences.map(mapDrivingOffence)
  ) ?? [];

export default mapDrivingOffences;
