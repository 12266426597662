import { dateValueToISOString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Claim, ClaimsHistoryState } from 'state/forms/claimsHistory/state';
import { conditionalValue } from 'helpers/mappingHelpers';
import { ClaimData } from '../types';

const mapClaim = (claim: Claim): ClaimData => {
  return {
    incidentDate: dateValueToISOString(claim.incidentDate) || '',
    incidentType: claim.incidentType,
    incidentDetails: claim.incidentDetails,
  };
};

const mapClaims = (claimsHistoryState: ClaimsHistoryState): ClaimData[] =>
  conditionalValue(claimsHistoryState.addClaimsHistory, claimsHistoryState.claims.map(mapClaim)) ??
  [];

export default mapClaims;
