import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { LicenceRestrictionsState } from './state';

export const UPDATE_LICENCE_RESTRICTIONS = 'UPDATE_LICENCE_RESTRICTIONS';

export type LicenceRestrictionsAction =
  | UpdateLicenceRestrictionsAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateLicenceRestrictionsAction = {
  type: typeof UPDATE_LICENCE_RESTRICTIONS;
  update: Partial<LicenceRestrictionsState>;
};

export const useLicenceRestrictions = (): [
  LicenceRestrictionsState,
  (update: Partial<LicenceRestrictionsState>) => void
] => {
  const licenceRestrictions = useSelector((state: RootState) => state.licenceRestrictions);
  const dispatch = useDispatch();

  const updateLicenceRestrictions = useCallback(
    (update: Partial<LicenceRestrictionsState>): void => {
      dispatch({ type: UPDATE_LICENCE_RESTRICTIONS, update });
    },
    [dispatch]
  );

  return [licenceRestrictions, updateLicenceRestrictions];
};
