import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export type DocumentsState =
  | {
      id: string;
      documentDate: Date | null;
    }[]
  | null;

export const initialDocumentsState = null;

export const useDocumentsState = (): DocumentsState =>
  useSelector((state: RootState) => state.documents);
