import { graphql } from 'gatsby';
import React from 'react';
import Hero, { HeroData } from 'components/Hero';
import Layout, { PageType } from 'components/Layout';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';

export type ErrorPageProps = {
  data: ErrorPageData;
  pageType?: PageType;
};

export type ErrorPageData = {
  csErrorPage: {
    meta_title: string;
    show_sticky_header: boolean | null;
    show_alert_notification: boolean | null;
    hero: HeroData;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment ErrorPage on cs__error_page {
    meta_title
    show_sticky_header
    show_alert_notification
    ...HeroError
    blocks {
      ...InfoCardsBlockError
      ...RichTextBlockError
      ...DividerBlockError
      ...RelatedContentBlockError
      ...ChecklistSectionBlockError
      ...CtaBannerBlockError
      ...BasicAccordionBlockError
      ...NestedAccordionBlockError
      ...CookiesListBlockError
    }
  }
`;

const ErrorPage: React.FC<ErrorPageProps> = ({ data, pageType = 'brochureware' }) => {
  const page = data.csErrorPage;
  return (
    <Layout
      pageType={pageType}
      meta={{ title: page.meta_title }}
      showStickyHeader={page.show_sticky_header ?? undefined}
      showAlertNotification={page.show_alert_notification ?? undefined}>
      <Hero hero={page.hero} />
      {page.blocks && mapBlocks(page.blocks)}
    </Layout>
  );
};

export default ErrorPage;
