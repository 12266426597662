import { DetailedRelatedContentWithLink } from '@rsa-digital/evo-shared-components/components/RelatedContent';
import { graphql } from 'gatsby';
import React from 'react';
import { processMandatoryCta } from 'helpers/csTypeProcessors';
import { CsCta } from 'types/contentStack';
import { CsRelatedContentCard, processRelatedContentCards } from './processRelatedContentCards';

export type RelatedContentBlockProps = {
  related_content_with_link: {
    heading: string;
    related_content_cards: CsRelatedContentCard[];
    cta: [CsCta];
  };
};

export const query = graphql`
  fragment RelatedContentBlockInfo on cs__info_page_blocks {
    related_content_with_link {
      heading
      related_content_cards {
        url
        image {
          ...CsAsset
        }
        title
        body
        reading_time
        read_more_text
      }
      cta {
        url
        display_text
        screen_reader_text
        open_in_new_tab
      }
    }
  }
  fragment RelatedContentBlockNews on cs__news_article_page_blocks {
    related_content_with_link {
      heading
      related_content_cards {
        url
        image {
          ...CsAsset
        }
        title
        body
        reading_time
        read_more_text
      }
      cta {
        url
        display_text
        screen_reader_text
        open_in_new_tab
      }
    }
  }
  fragment RelatedContentBlockError on cs__error_page_blocks {
    related_content_with_link {
      heading
      related_content_cards {
        url
        image {
          ...CsAsset
        }
        title
        body
        reading_time
        read_more_text
      }
      cta {
        url
        display_text
        screen_reader_text
        open_in_new_tab
      }
    }
  }
`;

const RelatedContentBlock: React.FC<RelatedContentBlockProps> = ({ related_content_with_link }) => (
  <DetailedRelatedContentWithLink
    data-cy="DetailedRelatedContentWithLink"
    heading={related_content_with_link.heading}
    cardProps={processRelatedContentCards(related_content_with_link.related_content_cards)}
    cta={processMandatoryCta(related_content_with_link.cta, 'Body')}
  />
);

export default React.memo(RelatedContentBlock);
