import { useCallback } from 'react';
import { ResetStateAction } from 'state/actions';
import useDispatch from 'state/useDispatch';
import { UserState } from './state';

export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';

export type UserAction = UpdateUserStateAction | ResetStateAction;

export type UpdateUserStateAction = {
  type: typeof UPDATE_USER_STATE;
  state: UserState;
};

export const useUpdateUserState = (): ((state: UserState) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (state: UserState): void => {
      dispatch({
        type: UPDATE_USER_STATE,
        state,
      });
    },
    [dispatch]
  );
};
