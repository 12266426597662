/* istanbul ignore file */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import appDynamicsScripts from './appdynamics';
import { gtmNoscript, gtmScript } from './gtm';
import { oneTrustCdnScript, oneTrustWrapperScript } from './oneTrust';
import qubitScript from './qubit';

const Analytics: React.FC = () => {
  return (
    <>
      <Helmet>
        {appDynamicsScripts}
        {gtmScript}
        {oneTrustCdnScript}
        {oneTrustWrapperScript}
        {qubitScript}
      </Helmet>
      {gtmNoscript}
    </>
  );
};

export default Analytics;
