import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const SectionHeading = styled.h2`
  ${fonts.headingLarge};
  & {
    font-weight: bold;
  }
  margin-top: 0;
  margin-bottom: ${spacing(3)};
  padding: 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
  `}
`;

export const StyledGrid = styled(Grid)`
  margin-top: ${spacing(2)};
  margin-bottom: ${spacing(6)};
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(8)};
  `}
`;

export const TextBlock = styled.div`
  ${mediaQuery.tabletPortrait`
    display: flex;
    flex-direction: column;
  `}
`;

export const TextRow = styled.p`
  ${fonts.paragraph};
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-basis: 1;

  :not(:first-child) {
    margin: ${spacing(2)} 0 0;
  }

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    margin: 0;
  `};
`;

// The 'display: block' for TextTitle and TextValue are needed for styling to work in IE
export const TextTitle = styled.b`
  padding: 0;
  display: block;
`;

export const TextValue = styled.span`
  padding: 0;
  display: block;

  ${mediaQuery.tabletPortrait`
    margin-left: ${spacing(2)};
  `}
`;
