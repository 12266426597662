import { FeatureContainer } from '@rsa-digital/evo-shared-components/components/Feature';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import styled from 'styled-components';

export const StyledFeatureContainer = styled(FeatureContainer)`
  > :first-child {
    display: none;
  }

  ${mediaQuery.tabletPortrait`
    justify-content: left;
    width:60%;
  `}

  ${mediaQuery.tabletLandscape`
    justify-content: center;
    width: 100%;
    > :first-child {
      display: block;
    }
  `}
`;
