import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import React from 'react';
import { RichTextGrid } from './styles';

type RichTextBlockProps = {
  text_block: { text: string };
};
export const query = graphql`
  fragment RichTextBlockInfo on cs__info_page_blocks {
    text_block {
      text
    }
  }
  fragment RichTextBlockError on cs__error_page_blocks {
    text_block {
      text
    }
  }
  fragment RichTextBlockAccountArea on cs__account_area_page_blocks {
    text_block {
      text
    }
  }
`;

const RichTextBlock: React.FC<RichTextBlockProps> = ({ text_block }) => (
  <RichTextGrid alignLeft>
    <GridItem desktop={1} tabletLandscape={1} />
    <GridItem desktop={8} tabletLandscape={8}>
      <RichText html={text_block.text} />
    </GridItem>
  </RichTextGrid>
);

export default React.memo(RichTextBlock);
