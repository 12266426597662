import CtaBanner from '@rsa-digital/evo-shared-components/components/CtaBanner';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql } from 'gatsby';
import React from 'react';
import { processMandatoryCta } from 'helpers/csTypeProcessors';
import { CsCta } from 'types/contentStack';

type CtaBannerBlockProps = {
  cta_banner: CtaBannerData;
} & ComponentProps;

export type CtaBannerData = {
  heading: string;
  cta: [CsCta];
};

export const query = graphql`
  fragment CtaBannerBlockInfo on cs__info_page_blocks {
    cta_banner {
      heading
      cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
    }
  }
  fragment CtaBannerBlockNewsIndex on cs__news_index_page {
    cta_banner {
      heading
      cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
    }
  }
  fragment CtaBannerBlockError on cs__error_page_blocks {
    cta_banner {
      heading
      cta {
        display_text
        screen_reader_text
        url
        open_in_new_tab
      }
    }
  }
`;

const CtaBannerBlock: React.FC<CtaBannerBlockProps> = ({ cta_banner, ...props }) => {
  const cta = processMandatoryCta(cta_banner.cta, 'Body');

  return (
    <CtaBanner
      secondaryCta={cta}
      heading={cta_banner.heading}
      data-cy="CTABanner"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...componentProps(props)}
    />
  );
};

export default React.memo(CtaBannerBlock);
