import { TooltipProps } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';

export type Question = {
  questionText: string;
  explanatoryText?: string;
  tooltip?: TooltipProps;
  alertText?: string;
};

export type QuestionWithPlaceholder = {
  placeholder: string;
} & Question;

export type QuestionWithOptionalPlaceholder = {
  placeholder?: string;
} & Question;

export type QuestionWithTimePlaceholders = {
  placeholders: {
    hours: string;
    minutes: string;
  };
} & Question;

export type NestedQuestions<T extends object> = {
  label: string;
  addText: string;
  removeText: string;
  confirmDeleteText?: string;
} & T;

export enum DriverType {
  PERMANENT = 'Permanent',
  TEMPORARY = 'Temporary',
}

export type ConfirmationState = {
  driverType: DriverType;
  coverStartDate: Date;
  coverEndDate?: Date;
  // Note: should only be used for *temporary* driver replacements (will be ignored otherwise).
  replacedDriverName?: string;
};
