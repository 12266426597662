import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { LicenceDetailsAction, UPDATE_LICENCE_DETAILS } from './action';
import { initialLicenceDetailsState, LicenceDetailsState } from './state';

export const licenceDetailsReducer = (
  licenceDetailsState: LicenceDetailsState = initialLicenceDetailsState,
  action: LicenceDetailsAction
): LicenceDetailsState => {
  switch (action.type) {
    case UPDATE_LICENCE_DETAILS:
      return {
        ...licenceDetailsState,
        ...action.update,
      };
    case RESET_STATE:
      return initialLicenceDetailsState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialLicenceDetailsState;
    default:
      return licenceDetailsState;
  }
};
