import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { ReplaceDriverState } from './state';

export const UPDATE_REPLACE_DRIVER = 'UPDATE_REPLACE_DRIVER';

export type ReplaceDriverAction =
  | UpdateReplaceDriverAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateReplaceDriverAction = {
  type: typeof UPDATE_REPLACE_DRIVER;
  update: Partial<ReplaceDriverState>;
};

export const useReplaceDriver = (): [
  ReplaceDriverState,
  (update: Partial<ReplaceDriverState>) => void
] => {
  const replaceDriverState = useSelector((state: RootState) => state.replaceDriver);
  const dispatch = useDispatch();

  const updateReplaceDriverState = useCallback(
    (update: Partial<ReplaceDriverState>): void => {
      dispatch({ type: UPDATE_REPLACE_DRIVER, update });
    },
    [dispatch]
  );

  return [replaceDriverState, updateReplaceDriverState];
};
