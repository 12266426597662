/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { isProductionSite } from 'helpers/analyticsHelpers';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const gtmScriptProduction = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KBT2V3R');
`;

const gtmScriptStaging = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=AVvd9bPXbghdRe4UalJm_g&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KBT2V3R');
`;

const gtmNoscriptProductionUrl = 'https://www.googletagmanager.com/ns.html?id=GTM-KBT2V3R';
const gtmNoscriptStagingUrl =
  'https://www.googletagmanager.com/ns.html?id=GTM-KBT2V3R&gtm_auth=AVvd9bPXbghdRe4UalJm_g&gtm_preview=env-3&gtm_cookies_win=x';

export const gtmScript: JSX.Element = (
  <script>{isProductionSite(analyticsEnv) ? gtmScriptProduction : gtmScriptStaging}</script>
);

export const gtmNoscript: JSX.Element = (
  <noscript>
    <iframe
      src={isProductionSite(analyticsEnv) ? gtmNoscriptProductionUrl : gtmNoscriptStagingUrl}
      height="0"
      width="0"
      style={{
        display: 'none',
        visibility: 'hidden',
      }}
    />
  </noscript>
);
