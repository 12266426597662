import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { DrivingOffencesAction, UPDATE_DRIVING_OFFENCES } from './action';
import { DrivingOffencesState, initialDrivingOffences } from './state';

export const drivingOffencesReducer = (
  drivingOffences: DrivingOffencesState = initialDrivingOffences,
  action: DrivingOffencesAction
): DrivingOffencesState => {
  switch (action.type) {
    case UPDATE_DRIVING_OFFENCES:
      return {
        ...drivingOffences,
        ...action.update,
      };
    case RESET_STATE:
      return initialDrivingOffences;
    case RESET_ADD_A_DRIVER_STATE:
      return initialDrivingOffences;
    default:
      return drivingOffences;
  }
};
