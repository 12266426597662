export type AboutTheDriverState = {
  isHirer: boolean | undefined;
  isNominee: boolean | undefined;
  refusedMotorInsurance: boolean | undefined;
};

export const initialAboutTheDriverState: AboutTheDriverState = {
  isHirer: undefined,
  isNominee: undefined,
  refusedMotorInsurance: undefined,
};
