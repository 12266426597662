import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { LookupPolicyState } from './state';

export const UPDATE_LOOKUP_POLICY = 'UPDATE_LOOKUP_POLICY';

export type LookupPolicyAction = LookupPolicy | ResetStateAction;

export type LookupPolicy = {
  type: typeof UPDATE_LOOKUP_POLICY;
  lookupPolicy: LookupPolicyState;
};

export const useLookupPolicy = (): [
  LookupPolicyState,
  (newLookupPolicyState: LookupPolicyState) => void
] => {
  const lookupPolicyState = useSelector((state: RootState) => state.lookupPolicy);
  const dispatch = useDispatch();

  const UpdateLookupPolicy = useCallback(
    (newLookupPolicyState: LookupPolicyState): void => {
      dispatch({ type: UPDATE_LOOKUP_POLICY, lookupPolicy: newLookupPolicyState });
    },
    [dispatch]
  );
  return [lookupPolicyState, UpdateLookupPolicy];
};
