import FooterComponent from '@rsa-digital/evo-shared-components/components/Footer';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processLink } from 'helpers/csTypeProcessors';
import { trackFooterClick } from 'helpers/eventTracking';
import { handleLinkClick } from 'helpers/navigation';
import { CsAsset } from 'types/contentStack';

export type FooterData = {
  csFooter: {
    first_section: {
      title: string;
      links: { url: string; text: string; open_in_new_tab: boolean }[];
    };
    second_section: {
      title: string;
      links: { url: string; text: string; open_in_new_tab: boolean }[];
    };
    third_section: {
      title: string;
      links: { url: string; text: string; open_in_new_tab: boolean }[];
    };
    small_section: {
      title: string;
      links: { url: string; text: string; logo: CsAsset; open_in_new_tab: boolean }[];
    };
    footer_compliance: {
      compliance: string;
    };
  };
};

const FooterQuery = graphql`
  query {
    csFooter {
      first_section {
        title
        links {
          url
          text
          open_in_new_tab
        }
      }
      second_section {
        title
        links {
          url
          text
          open_in_new_tab
        }
      }
      third_section {
        title
        links {
          url
          text
          open_in_new_tab
        }
      }
      small_section {
        title
        links {
          url
          text
          open_in_new_tab
          logo {
            ...CsAsset
          }
        }
      }
      footer_compliance {
        compliance
      }
    }
  }
`;

/* istanbul ignore next */
const handleAndTrackFooterLink = (text: string, url: string) => (event: React.MouseEvent) => {
  trackFooterClick(text, url)();
  handleLinkClick(url)(event);
};

const Footer: React.FC<{ complianceText?: string }> = ({ complianceText }) => {
  const { csFooter } = useStaticQuery<FooterData>(FooterQuery);

  const firstSection = csFooter.first_section.links.map((link) => {
    return {
      ...processLink(link),
      onClick: handleAndTrackFooterLink(`${csFooter.first_section.title} - ${link.text}`, link.url),
    };
  });

  const secondSection = csFooter.second_section.links.map((link) => {
    return {
      ...processLink(link),
      onClick: handleAndTrackFooterLink(
        `${csFooter.second_section.title} - ${link.text}`,
        link.url
      ),
    };
  });

  const thirdSection = csFooter.third_section.links.map((link) => {
    return {
      ...processLink(link),
      onClick: handleAndTrackFooterLink(`${csFooter.third_section.title} - ${link.text}`, link.url),
    };
  });

  const footerLinks = {
    firstLargeSection: { title: csFooter.first_section.title, links: firstSection },
    secondLargeSection: { title: csFooter.second_section.title, links: secondSection },
    thirdLargeSection: { title: csFooter.third_section.title, links: thirdSection },
  };

  const footerCompliance = {
    compliance: complianceText ?? csFooter.footer_compliance.compliance,
  };

  return (
    <FooterComponent
      footerLinks={footerLinks}
      footerCompliance={footerCompliance}
      data-cy="Footer"
    />
  );
};

export default React.memo(Footer);
