import { RESET_STATE } from 'state/actions';
import { LoadingAction, UPDATE_LOADING_STATE } from './actions';
import { initialLoadingState, LoadingState } from './state';

export const loadingReducer = (
  state: LoadingState = initialLoadingState,
  action: LoadingAction
): LoadingState => {
  switch (action.type) {
    case UPDATE_LOADING_STATE:
      return action.state;
    case RESET_STATE:
      return initialLoadingState;
    default:
      return state;
  }
};
