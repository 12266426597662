import { dateValueToLocalDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { NewDriversCoverState } from 'state/forms/newDriversCover/state';
import { TimeValue } from 'state/forms/shared/timeValue';
import { NewDriversCoverData } from '../types';

const mapDateAndTime = (date: Date, time: TimeValue): string => {
  const hour = Number(time.hour);
  const minute = Number(time.minute);
  const returnDate = new Date(date);
  returnDate.setHours(hour, minute, 0, 0);
  return returnDate.toISOString();
};

const mapNewDriversCover = (newDriversCoverState: NewDriversCoverState): NewDriversCoverData => {
  const coverStart = newDriversCoverState.selectCustomStartDate
    ? dateValueToLocalDate(newDriversCoverState.startDate)! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    : new Date();

  const startTime = newDriversCoverState.selectCustomStartTime
    ? newDriversCoverState.startTime
    : { hour: '00', minute: '01' };

  const coverEnd = newDriversCoverState.selectCustomEndDate
    ? dateValueToLocalDate(newDriversCoverState.endDate)! // eslint-disable-line @typescript-eslint/no-non-null-assertion
    : new Date();

  const endTime = newDriversCoverState.selectCustomEndTime
    ? newDriversCoverState.endTime
    : { hour: '23', minute: '59' };

  const isCoverStartNow =
    !newDriversCoverState.selectCustomStartDate && !newDriversCoverState.selectCustomStartTime;

  return {
    isCoverStartNow,
    coverStart: isCoverStartNow ? undefined : mapDateAndTime(coverStart, startTime),
    coverEnd: mapDateAndTime(coverEnd, endTime),
  };
};

export default mapNewDriversCover;
