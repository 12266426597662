import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, { spacingWithBorder } from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

const borderPx = 1;

export const InfoCardContainer = styled.div`
  margin: ${spacing(8)} 0;
`;

export const StyledInfoCard = styled(InfoCard)`
  margin: ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(1)} 0;
 `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(2)} 0;
    padding: ${spacingWithBorder(6, borderPx)};
    padding-top: ${spacingWithBorder(4, borderPx)};
  `}
`;
