import { useCallback } from 'react';
import { useUpdateLoadingState } from 'state/loading/actions';
import useAxiosErrorHandler from './useAxiosErrorHandler';

type RequestHandlerType = <T>(
  makeRequest: () => Promise<T>,
  withLoadingSpinner?: boolean,
  errorHandler?: (err: Error) => void
) => Promise<T | undefined>;

export const useRequestHandler = (): RequestHandlerType => {
  const updateLoadingState = useUpdateLoadingState();
  const axiosErrorHandler = useAxiosErrorHandler();

  return useCallback(
    async <T>(
      makeRequest: () => Promise<T>,
      withLoadingSpinner = true,
      errorHandler?: (err: Error) => void | undefined
    ): Promise<T | undefined> => {
      try {
        try {
          if (withLoadingSpinner) {
            updateLoadingState({ isLoading: true });
          }

          const data = await makeRequest();

          if (withLoadingSpinner) {
            updateLoadingState({ isLoading: false });
          }
          return data;
        } catch (error) {
          if (errorHandler) {
            errorHandler(error as Error);

            if (withLoadingSpinner) {
              updateLoadingState({ isLoading: false });
            }
            return undefined;
          }
          throw error;
        }
      } catch (error) {
        axiosErrorHandler(error);
        if (withLoadingSpinner) {
          updateLoadingState({ isLoading: false });
        }
        return undefined;
      }
    },
    [axiosErrorHandler, updateLoadingState]
  );
};

export default useRequestHandler;
