import ChecklistWithIcons from '@rsa-digital/evo-shared-components/components/ChecklistWithIcons';
import { Grid, GridItem, GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import React from 'react';
import { useTheme } from 'styled-components';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { Icon } from 'types/contentStack';
import { Heading, Subheading, Summary } from './styles';

type ChecklistWithIconsData = {
  summary: string;
  icon: [Icon];
};
type ChecklistSectionProps = {
  checklist_section: {
    heading?: string;
    subheading?: string;
    summary?: string;
    checklist: { more_details?: string; checklist_rows: ChecklistWithIconsData[] }[];
  };
};
export const query = graphql`
  fragment ChecklistSectionBlockInfo on cs__info_page_blocks {
    checklist_section {
      heading
      subheading
      summary
      checklist {
        more_details
        checklist_rows {
          summary
          icon {
            icon_code
          }
        }
      }
    }
  }
  fragment ChecklistSectionBlockError on cs__error_page_blocks {
    checklist_section {
      heading
      subheading
      summary
      checklist {
        more_details
        checklist_rows {
          summary
          icon {
            icon_code
          }
        }
      }
    }
  }
`;

const gridItemsProps: GridItemProps = {
  desktop: 10,
  tabletLandscape: 10,
};

const ChecklistSectionBlock: React.FC<ChecklistSectionProps> = ({ checklist_section }) => {
  const theme = useTheme();
  return (
    <Grid>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <GridItem {...gridItemsProps} data-cy="ChecklistSection">
        {checklist_section.heading && <Heading>{checklist_section.heading}</Heading>}
        {checklist_section.subheading && <Subheading>{checklist_section.subheading}</Subheading>}
        {checklist_section.summary && <Summary html={checklist_section.summary} />}
        {checklist_section.checklist.map(({ checklist_rows, more_details }) => {
          const rows = checklist_rows.map(({ summary, icon }) => {
            const iconCode = unwrapSingleton(icon)?.icon_code;
            /* istanbul ignore if */
            if (iconCode === undefined) {
              nonFatalBuildError('Icon missing', 'Icon missing for Checklist With Icons');
            }
            const color =
              iconCode === 'cross'
                ? theme.colors.notificationError
                : theme.colors.notificationSuccess;
            return {
              summary,
              icon: iconCode ? { iconName: iconCode, color } : undefined,
            };
          });
          return (
            <>
              <ChecklistWithIcons rows={rows} data-cy="ChecklistWithIcons" />
              {more_details && <RichText html={more_details} />}
            </>
          );
        })}
      </GridItem>
    </Grid>
  );
};
export default React.memo(ChecklistSectionBlock);
