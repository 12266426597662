import 'core-js/features/url';

import React from 'react';
import ErrorBoundary from './src/components/ErrorBoundary';
import ThemeProvider from './src/components/Layout/theme';
import PageTracking from './src/components/PageTracking';
import StoreProvider from './src/state/storeProvider';

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <PageTracking location={props.location}>
      <ErrorBoundary location={props.location}>{element}</ErrorBoundary>
    </PageTracking>
  </ThemeProvider>
);

export const wrapRootElement = ({ element }) => <StoreProvider>{element}</StoreProvider>;

export const onInitialClientRender = () => {
  window.isHydrated = true;
};
