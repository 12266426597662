import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const AccordionWrapperBlock = styled.div`
  margin-top: ${spacing(8)};
  margin-bottom: ${spacing(8)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(12)};
  `}
`;

export const Heading = styled.h2`
  ${fonts.headingLarge};
  margin-bottom: ${spacing(2)};
  margin-top: 0;
`;

export const HeadingsGridItem = styled(GridItem)`
  margin: 0 0 ${spacing(8)};
`;
