import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { DriverDetailsState } from './state';

export const UPDATE_DRIVER_DETAILS = 'UPDATE_DRIVER_DETAILS';

export type DriverDetailsAction =
  | UpdateDriverDetailsAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateDriverDetailsAction = {
  type: typeof UPDATE_DRIVER_DETAILS;
  update: Partial<DriverDetailsState>;
};

export const useDriverDetails = (): [
  DriverDetailsState,
  (update: Partial<DriverDetailsState>) => void
] => {
  const driverDetailsState = useSelector((state: RootState) => state.driverDetails);
  const dispatch = useDispatch();

  const updateDriverDetailsState = useCallback(
    (update: Partial<DriverDetailsState>): void => {
      dispatch({ type: UPDATE_DRIVER_DETAILS, update });
    },
    [dispatch]
  );

  return [driverDetailsState, updateDriverDetailsState];
};
