import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { HirerOrNomineeAgreementState } from './state';

export const UPDATE_HIRER_OR_NOMINEE_AGREEMENT = 'UPDATE_HIRER_OR_NOMINEE_AGREEMENT';

export type HirerOrNomineeAgreementAction =
  | UpdateHirerOrNomineeAgreementAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdateHirerOrNomineeAgreementAction = {
  type: typeof UPDATE_HIRER_OR_NOMINEE_AGREEMENT;
  update: Partial<HirerOrNomineeAgreementState>;
};

export const useHirerOrNomineeAgreement = (): [
  HirerOrNomineeAgreementState,
  (update: Partial<HirerOrNomineeAgreementState>) => void
] => {
  const hirerOrNomineeAgreementState = useSelector(
    (state: RootState) => state.hirerOrNomineeAgreement
  );
  const dispatch = useDispatch();

  const updateHirerOrNomineeAgreementState = useCallback(
    (update: Partial<HirerOrNomineeAgreementState>): void => {
      dispatch({ type: UPDATE_HIRER_OR_NOMINEE_AGREEMENT, update });
    },
    [dispatch]
  );

  return [hirerOrNomineeAgreementState, updateHirerOrNomineeAgreementState];
};
