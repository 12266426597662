import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { DoubleHeaderProps } from '@rsa-digital/evo-shared-components/components/Header/DoubleHeader';
import {
  LinkSection,
  PrimaryNavItem,
  SecondaryNavItem,
} from '@rsa-digital/evo-shared-components/components/Header/types';
import { graphql, useStaticQuery } from 'gatsby';
import { processCta, processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { trackMainNavigationClick, trackSecondaryNavigationClick } from 'helpers/eventTracking';
import { handleAndTrackLinkClick, handleLinkClick } from 'helpers/navigation';
import { CsAsset, CsCta, Icon } from 'types/contentStack';

type HeaderData = {
  csHeader: CsHeader;
};

type CsTertiaryNavItem = {
  text: string;
  screen_reader_text?: string;
  url: string;
  open_in_new_tab: boolean;
  icon: [Icon] | [] | null;
};

type CsOptionalTertiaryNavItem = Partial<CsTertiaryNavItem>;

type CsHeader = {
  logo: {
    default: { image: CsAsset; link_screen_reader_text: string; url: string };
    desktop: { image: CsAsset; link_screen_reader_text: string; url: string };
  };
  primary_nav_items: {
    simple_nav_item: CsSimpleNavItem;
  }[];
  secondary_nav_items: CsSecondaryNavItem[];
  tertiary_nav_item: CsTertiaryNavItem;
  second_tertiary_nav_item: CsOptionalTertiaryNavItem;
};

type CsSimpleNavItem = {
  name: string;
  sections: LinkSection[];
  ctas: CsCta[] | null;
};

type CsSecondaryNavItem = {
  name: string;
  sections: LinkSection[];
  primary_cta: [CsCta] | [] | null;
  secondary_cta: [CsCta] | [] | null;
};

const headerQuery = graphql`
  query {
    csHeader {
      logo {
        default {
          image {
            ...CsAsset
          }
          link_screen_reader_text
          url
        }
        desktop {
          image {
            ...CsAsset
          }
          link_screen_reader_text
          url
        }
      }
      primary_nav_items {
        simple_nav_item {
          name
          sections {
            heading
            links {
              text
              url
            }
          }
          ctas {
            display_text
            screen_reader_text
            url
            open_in_new_tab
          }
        }
      }
      secondary_nav_items {
        name
        sections {
          heading
          links {
            text
            url
          }
        }
        secondary_cta {
          display_text
          screen_reader_text
          url
          open_in_new_tab
        }
        primary_cta {
          screen_reader_text
          display_text
          url
          open_in_new_tab
        }
      }
      tertiary_nav_item {
        text
        screen_reader_text
        url
        open_in_new_tab
        icon {
          icon_code
        }
      }
    }
  }
`;

const getLinkSectionWithMainNavigationTracking = (
  linkSection: LinkSection[],
  navItemName: string
): LinkSection[] =>
  linkSection.map((section) => ({
    heading: section.heading,
    links: section.links.map((link) => ({
      text: link.text,
      url: link.url,
      onClick: (event) => {
        trackMainNavigationClick([navItemName, section.heading, link.text].join(' - '), 'Click')();
        handleLinkClick(link.url)(event);
      },
    })),
  }));

const getLinkSectionWithSecondaryNavigationTracking = (linkSection: LinkSection[]): LinkSection[] =>
  linkSection.map((section) => ({
    heading: section.heading,
    links: section.links.map((link) => ({
      text: link.text,
      url: link.url,
      onClick: (event) => {
        trackSecondaryNavigationClick(link.text, 'Click')();
        handleLinkClick(link.url)(event);
      },
    })),
  }));

const mapDefaultLogo = (csHeader: CsHeader): NonTextLink => ({
  url: csHeader.logo.default.url,
  screenReaderText: csHeader.logo.default.link_screen_reader_text,
  onClick: handleAndTrackLinkClick(
    csHeader.logo.default.url,
    'homeLogo',
    csHeader.logo.default.link_screen_reader_text
  ),
});

const mapDesktopLogo = (csHeader: CsHeader): NonTextLink => ({
  url: csHeader.logo.desktop.url,
  screenReaderText: csHeader.logo.desktop.link_screen_reader_text,
  onClick: handleAndTrackLinkClick(
    csHeader.logo.desktop.url,
    'homeLogo',
    csHeader.logo.desktop.link_screen_reader_text
  ),
});

const mapPrimaryNavItems = (csHeader: CsHeader): PrimaryNavItem[] =>
  csHeader.primary_nav_items.map((navItem) => ({
    name: navItem.simple_nav_item.name,
    sections: getLinkSectionWithMainNavigationTracking(
      navItem.simple_nav_item.sections,
      navItem.simple_nav_item.name
    ),
    ctas: navItem.simple_nav_item.ctas
      ? navItem.simple_nav_item.ctas.map((cta) => processCta(cta, 'Header'))
      : [],
    onCollapse: trackMainNavigationClick(navItem.simple_nav_item.name, 'Collapse'),
    onExpand: trackMainNavigationClick(navItem.simple_nav_item.name, 'Expand'),
  }));

const mapSecondaryNavItems = (csHeader: CsHeader): SecondaryNavItem[] =>
  csHeader.secondary_nav_items.map((navItem) => {
    return {
      icon: '',
      name: navItem.name,
      sections: getLinkSectionWithSecondaryNavigationTracking(navItem.sections),
      primaryCta: processOptionalCta(navItem.primary_cta, 'Header'),
      secondaryCta: processOptionalCta(navItem.secondary_cta, 'Header'),
      onCollapse: trackSecondaryNavigationClick(navItem.name, 'Collapse'),
      onExpand: trackSecondaryNavigationClick(navItem.name, 'Expand'),
    };
  });


export const useHeaderData = (): DoubleHeaderProps => {
  const { csHeader } = useStaticQuery<HeaderData>(headerQuery);

  const defaultLogo = processImageAsset(csHeader.logo.default.image);
  /* istanbul ignore if */
  if (!defaultLogo) {
    throw new Error('Header default logo missing!');
  }

  const desktopLogo = processImageAsset(csHeader.logo.desktop.image);
  /* istanbul ignore if */
  if (!desktopLogo) {
    throw new Error('Header desktop logo missing!');
  }

  return {
    defaultLogoLink: {
      image: defaultLogo,
      link: mapDefaultLogo(csHeader),
    },
    desktopLogoLink: {
      image: desktopLogo,
      link: mapDesktopLogo(csHeader),
    },
    primaryNav: {
      navItems: mapPrimaryNavItems(csHeader),
    },
    secondaryNav: {
      navItems: mapSecondaryNavItems(csHeader),
    },
    mobileMenuOnOpen: trackMainNavigationClick('Mobile menu toggle', 'Open'),
    mobileMenuOnClose: trackMainNavigationClick('Mobile menu toggle', 'Close'),
  };
};
