import apiClient from './apiClient';

export type PolicyLockClient = {
  lockPolicy: (policyNumber: string) => Promise<void>;
};

const policyLockClient: PolicyLockClient = {
  lockPolicy: (policyNumber: string) => apiClient.post(`policy/${policyNumber}/lock`),
};

export default policyLockClient;
