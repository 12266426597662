import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export type PolicyDetails = {
  policyNumber: string | null;
  policyStartDate: Date | null;
  renewalDate: Date | null;
  openDriving: boolean | null;
  policyUpdatesBlockedForCustomers: boolean;
  policyUpdatesBlockedForAll: boolean;
};

export type VehicleDetails = {
  registrationNumber: string | null;
  makeModel: string | null;
};

export type AddressDetails = {
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  addressLine4: string | null;
  postCode: string | null;
};

export type PersonalDetails = {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  fullName: string | null;
  address: AddressDetails | null;
};

export type NamedDriver = {
  fullName: string | null;
  dateOfBirth: Date | null;
  businessUse: boolean | null;
  hirer: boolean | null;
  driverRef: string | null;
};

export type TemporaryDriver = {
  fullName: string | null;
  coverStart: Date | null;
  coverEnd: Date | null;
  driverRef: string | null;
  replacedDriverRef: string | null;
};

export type Policy = {
  policyDetails: PolicyDetails;
  vehicleDetails: VehicleDetails;
  personalDetails: PersonalDetails;
  namedDrivers: NamedDriver[];
  temporaryDrivers: TemporaryDriver[];
} | null;

export type CoverNote = {
  coverNoteNumber: string | null;
  coverStart: Date | null;
  coverEnd: Date | null;
  drivers: string[];
};

export type PolicyAndCoverNotes = {
  policy: Policy | null;
  coverNotes: CoverNote[] | null;
  coverNotesHistory?: CoverNote[] | null;
};

export type PolicyState = PolicyAndCoverNotes & {
  requiresReload: boolean | null;
};

// The use of (typeof window !== 'undefined' && window.Cypress && window.initialPolicyState)
// allows for pre-populating policy state during testing
// The property initialPolicyState in window is targeted during 'cy.visit -> onBeforeLoad'
export const initialPolicyState = (typeof window !== 'undefined' &&
  window.Cypress &&
  window.initialPolicyState) || { policy: null, coverNotes: null, requiresReload: null };

export const usePolicyState = (): PolicyState => useSelector((state: RootState) => state.policy);
