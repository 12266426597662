import Modal from '@rsa-digital/evo-shared-components/components/Modal';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import driversService from 'apiHelpers/drivers/service';
import policyLockClient from 'apiHelpers/policyLockClient';
import { graphql, navigate } from 'gatsby';
import { partition } from 'lodash';
import React, { MouseEventHandler, useState } from 'react';
import { RESET_ADD_A_DRIVER_STATE } from 'state/actions';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { RELOAD_POLICY } from 'state/policy/actions';
import { NamedDriver, TemporaryDriver, usePolicyState } from 'state/policy/state';
import useDispatch from 'state/useDispatch';
import { useBlockedUpdatesHandler, useIsUpdateBlocked } from 'helpers/blockedUpdateHelpers';
import createStaffUpdateBlockedModalContent from 'helpers/createStaffUpdateBlockedModalContent';
import { compareDatesInDescendingOrder } from 'helpers/dateHelpers';
import { warningWithDetail } from 'helpers/errorReporting';
import { handleAndTrackCtaClick } from 'helpers/navigation';
import replaceAccountTemporaryDriverPlaceholders from 'helpers/placeholders/accountTemporaryDriversPlaceholders';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import usePolicyNumber from 'helpers/usePolicyNumber';
import usePolicyUpdateAccess from 'helpers/usePolicyUpdateAccess';
import useRequestHandler from 'helpers/useRequestHandler';
import { DriverType } from 'types/forms';
import TemporaryDriverCard from './TemporaryDriverCard';
import {
  CancelButton,
  ConfirmButton,
  IconWithLeftMargin,
  IconWithRightMargin,
  ModalButtonContainer,
  ModalContainer,
  ModalHeading,
  RichTextWithMargin,
  RichTextWithTopPadding,
  StyledSmallHeading,
  TertiaryButtonWithTopMargin,
} from '../styles';
import { TemporaryDriversData, TemporaryDriversProps } from '../types';

export const query = graphql`
  fragment TemporaryDriversPolicyAccordion on cs__policy_details_accordion_temporary_drivers_details {
    temporary_drivers {
      heading
      additional_info {
        show_additional_info
        text
      }
      initially_display
      no_temporary_drivers
      upcoming_temporary_drivers {
        heading
        rich_text
        not_yet_active_suffix
        will_replace_suffix
      }
      open_driving_replacement_text
      replaced_driver_suffix
      cover_start
      cover_end
      add_driver_button {
        display_text
        alt_text
      }
      remove_driver {
        display_text
        alt_text
      }
      modals {
        remove_driver_confirmation_modal {
          heading
          no_driver_replaced_body
          driver_replaced_body
          confirm_text
          cancel_text
        }
        remove_driver_success_modal {
          heading
          no_driver_replaced_body
          driver_replaced_body
          close_text
        }
      }
    }
  }
`;

type ModalProps = {
  heading: string;
  body: string;
  confirmButton?: { onClick?: MouseEventHandler; displayText?: string };
  cancelText?: string;
};

const TemporaryDriversSection: React.FC<TemporaryDriversProps> = ({ temporary_drivers }) => {
  const { policy } = usePolicyState();
  const policyNumber = usePolicyNumber();
  const dispatch = useDispatch();
  const addDriverHelper = usePolicyUpdateAccess();
  const blockedUpdatesHandler = useBlockedUpdatesHandler(DriverType.TEMPORARY);
  const [, updateReplaceDriver] = useReplaceDriver();
  const handleRequest = useRequestHandler();
  const [modalInfo, setModalInfo] = useState<ModalProps | null>(null);
  const showModifyDriverButtons = !useIsUpdateBlocked();

  if (!policy) {
    return null;
  }

  if (addDriverHelper.hasOpenDriving) {
    return <RichTextWithTopPadding html={temporary_drivers.open_driving_replacement_text} />;
  }

  const getModalCsContent = (
    temporaryDriver: TemporaryDriver,
    replacedDriver: NamedDriver | undefined
  ): TemporaryDriversData =>
    replaceAccountTemporaryDriverPlaceholders(temporaryDriver, replacedDriver, temporary_drivers);

  const showRemoveDriverSuccessModal = (
    driver: TemporaryDriver,
    replacedDriver: NamedDriver | undefined
  ): void => {
    const { remove_driver_success_modal } = getModalCsContent(driver, replacedDriver).modals;

    setModalInfo({
      heading: remove_driver_success_modal.heading,
      body: replacedDriver
        ? remove_driver_success_modal.driver_replaced_body
        : remove_driver_success_modal.no_driver_replaced_body,
      confirmButton: {
        displayText: remove_driver_success_modal.close_text,
        onClick: () => dispatch({ type: RELOAD_POLICY }),
      },
    });
  };

  const showConfirmRemoveDriverModal = (
    driver: TemporaryDriver,
    replacedDriver: NamedDriver | undefined
  ): void => {
    const { remove_driver_confirmation_modal } = getModalCsContent(driver, replacedDriver).modals;

    const removeDriver = async (): Promise<void> => {
      await policyLockClient.lockPolicy(policyNumber).catch((e) => {
        // If this call errors we don't want to do anything
        // so catching and ignoring this error to prevent it bubbling up.
        warningWithDetail('Failed to lock policy', e.message);
      });
      return driversService
        .removeTemporaryDriverRequest(policyNumber, driver.driverRef ?? '')
        .then(() => showRemoveDriverSuccessModal(driver, replacedDriver));
    };

    setModalInfo({
      heading: remove_driver_confirmation_modal.heading,
      body: replacedDriver
        ? remove_driver_confirmation_modal.driver_replaced_body
        : remove_driver_confirmation_modal.no_driver_replaced_body,
      cancelText: remove_driver_confirmation_modal.cancel_text,
      confirmButton: {
        displayText: remove_driver_confirmation_modal.confirm_text,
        onClick: async () => {
          await handleRequest(removeDriver, true);
        },
      },
    });
  };

  const showStaffUpdateBlockedModal = (onContinue: () => void): void => {
    setModalInfo(createStaffUpdateBlockedModalContent(onContinue, DriverType.TEMPORARY));
  };

  const getReplacedDriver = (temporaryDriver: TemporaryDriver): NamedDriver | undefined =>
    policy.namedDrivers.find(
      (namedDriver) => namedDriver.driverRef === temporaryDriver.replacedDriverRef
    );

  const onRemove = (driver: TemporaryDriver): void => {
    blockedUpdatesHandler(() => {
      showConfirmRemoveDriverModal(driver, getReplacedDriver(driver));
    }, showStaffUpdateBlockedModal);
  };

  const [currentTemps, futureTemps] = partition(
    [...policy.temporaryDrivers].sort((x, y) =>
      compareDatesInDescendingOrder(y.coverStart, x.coverStart)
    ),
    (driver) => !!driver.coverStart && driver.coverStart <= new Date()
  );

  return (
    <div>
      {temporary_drivers.additional_info.show_additional_info && (
        <RichTextWithMargin
          html={temporary_drivers.additional_info.text}
          data-cy="AdditionalInfoText"
        />
      )}
      {currentTemps.length > 0 ? (
        currentTemps.map((driver, index) => (
          <TemporaryDriverCard
            temporaryDriversData={temporary_drivers}
            driver={driver}
            replacedDriver={getReplacedDriver(driver)}
            index={index}
            onRemove={() => onRemove(driver)}
            key={driver.driverRef}
            showModifyDriverButtons={showModifyDriverButtons}
          />
        ))
      ) : (
        <RichTextWithMargin html={temporary_drivers.no_temporary_drivers} />
      )}
      {futureTemps.length > 0 && (
        <>
          <StyledSmallHeading>
            {temporary_drivers.upcoming_temporary_drivers.heading}
          </StyledSmallHeading>
          <RichTextWithMargin html={temporary_drivers.upcoming_temporary_drivers.rich_text} />
          {futureTemps.map((driver, index) => (
            <TemporaryDriverCard
              temporaryDriversData={temporary_drivers}
              driver={driver}
              replacedDriver={getReplacedDriver(driver)}
              index={currentTemps.length + index}
              onRemove={() => onRemove(driver)}
              key={driver.driverRef}
              showModifyDriverButtons={showModifyDriverButtons}
            />
          ))}
        </>
      )}
      {showModifyDriverButtons && (
        <TertiaryButtonWithTopMargin
          onClick={() => {
            blockedUpdatesHandler(() => {
              dispatch({ type: RESET_ADD_A_DRIVER_STATE });
              updateReplaceDriver({
                replacedDriverRef: '',
              });
              handleAndTrackCtaClick(
                getFormPageRoute(DriverType.TEMPORARY, FormPage.BeforeYouStart),
                'Body',
                `addDriver - ${temporary_drivers.add_driver_button.display_text}`
              );
              navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.BeforeYouStart));
            }, showStaffUpdateBlockedModal);
          }}
          screenReaderText={temporary_drivers.add_driver_button.alt_text}
          data-cy="AddTempDriverButton">
          <IconWithRightMargin name="add" size="small" color={colors.core01} />
          {temporary_drivers.add_driver_button.display_text}
        </TertiaryButtonWithTopMargin>
      )}
      {modalInfo && (
        <Modal aria-labelledby="modal-heading">
          <ModalContainer>
            <ModalHeading id="modal-heading">{modalInfo.heading}</ModalHeading>
            <RichText html={modalInfo.body} data-cy="TemporaryDriverModalBody" />
            <ModalButtonContainer>
              {modalInfo.cancelText && (
                <CancelButton
                  onClick={() => setModalInfo(null)}
                  data-cy="TemporaryDriverModalCancel">
                  {modalInfo.cancelText}
                </CancelButton>
              )}
              {modalInfo.confirmButton?.displayText && (
                <ConfirmButton
                  onClick={(event: React.MouseEvent) => {
                    setModalInfo(null);
                    modalInfo.confirmButton?.onClick?.(event);
                  }}
                  data-cy="TemporaryDriverModalConfirmButton">
                  {modalInfo.confirmButton.displayText}
                  <IconWithLeftMargin name="chevron" size="x-small" color={colors.core01} />
                </ConfirmButton>
              )}
            </ModalButtonContainer>
          </ModalContainer>
        </Modal>
      )}
    </div>
  );
};

export default React.memo(TemporaryDriversSection);
