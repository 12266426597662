import { RESET_ADD_A_DRIVER_STATE, RESET_STATE } from 'state/actions';
import { HirerOrNomineeAgreementAction, UPDATE_HIRER_OR_NOMINEE_AGREEMENT } from './action';
import { HirerOrNomineeAgreementState, initialHirerOrNomineeAgreementState } from './state';

export const hirerOrNomineeAgreementReducer = (
  hirerOrNomineeAgreementState: HirerOrNomineeAgreementState = initialHirerOrNomineeAgreementState,
  action: HirerOrNomineeAgreementAction
): HirerOrNomineeAgreementState => {
  switch (action.type) {
    case UPDATE_HIRER_OR_NOMINEE_AGREEMENT:
      return {
        ...hirerOrNomineeAgreementState,
        ...action.update,
      };
    case RESET_STATE:
      return initialHirerOrNomineeAgreementState;
    case RESET_ADD_A_DRIVER_STATE:
      return initialHirerOrNomineeAgreementState;
    default:
      return hirerOrNomineeAgreementState;
  }
};
