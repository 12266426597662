import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { initialDateValue } from '../shared/dateValue';
import { initialTimeValue, TimeValue } from '../shared/timeValue';

export type NewDriversCoverState = {
  selectCustomStartDate: boolean | undefined;
  startDate: DateValue;
  selectCustomStartTime?: boolean;
  startTime: TimeValue;
  selectCustomEndDate: boolean | undefined;
  endDate: DateValue;
  selectCustomEndTime?: boolean;
  endTime: TimeValue;
};

export const initialNewDriversCoverState: NewDriversCoverState = {
  selectCustomStartDate: undefined,
  startDate: initialDateValue,
  selectCustomStartTime: undefined,
  startTime: initialTimeValue,
  selectCustomEndDate: undefined,
  endDate: initialDateValue,
  selectCustomEndTime: undefined,
  endTime: initialTimeValue,
};

export enum DateType {
  TODAY = 'Today',
  CUSTOM_DATE = 'Select another date',
}

export enum DateTypeId {
  TODAY = 'today',
  CUSTOM_DATE = 'selectAnotherDate',
}

export enum TimeType {
  START_OF_DAY = 'Start of the day',
  END_OF_DAY = 'End of the day',
  CUSTOM_TIME = 'Select another time',
  NOW = 'Now',
}

export enum TimeTypeId {
  START_OF_DAY = 'startOfTheDay',
  END_OF_DAY = 'endOfTheDay',
  CUSTOM_TIME = 'selectAnotherTime',
  NOW = 'now',
}
