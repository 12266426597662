import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export enum SessionState {
  Loaded,
  Loading,
  Empty,
}

export type UserState = {
  crn: string;
  isStaffMember: boolean;
  sessionState: SessionState;
};

export const initialUserState: UserState = {
  crn: '',
  isStaffMember: false,
  sessionState: SessionState.Empty,
};

export const useUserState = (): UserState => useSelector((state: RootState) => state.user);
