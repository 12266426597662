import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageType } from 'components/Layout';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "bw-500" }) {
      ...ErrorPage
    }
  }
`;

type UnknownErrorPageProps = {
  pageType?: PageType;
};

/* istanbul ignore next */
const UnknownErrorPage: React.FC<UnknownErrorPageProps> = ({ pageType }) => {
  const data = useStaticQuery<ErrorPageData>(query);
  useAnalyticsPageVariables(
    {
      pageCategories: [],
      pageType: 'ErrorPage',
      pageError: 500,
    },
    data.csErrorPage.blocks
  );

  return <ErrorPage data={data} pageType={pageType} />;
};

export default UnknownErrorPage;
