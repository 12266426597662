import { ActionCard } from '@rsa-digital/evo-shared-components/components/ActionCard';
import {
  Body,
  ChevronWrapper,
  Heading,
  IconWrapper,
  Subheading,
} from '@rsa-digital/evo-shared-components/components/ActionCard/ActionCard/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const CoverNotesCard = styled(ActionCard)`
  border-color: ${colors.core01};

  ${Heading} {
    font-weight: bold;
  }

  ${Subheading} {
    ${fonts.paragraph};
    margin-top: ${spacing(1)};
  }

  ${Body} {
    padding-left: ${spacing(6)};
    margin-top: ${spacing(1)};
  }

  ${IconWrapper}, ${ChevronWrapper}, ${Heading}, ${Subheading}, ${Body} {
    color: ${colors.core01};
  }

  margin: ${spacing(2)} 0;
  ${mediaQuery.tabletPortrait`
    margin: ${spacing(3)} 0;
  `}
`;

export const ErrorText = styled.p`
  ${fonts.paragraph};
`;

export const StyledRichText = styled(RichText)`
  margin: ${spacing(6)} 0 ${spacing(4)};
`;
