import { NamedDriver } from 'state/policy/state';
import { NamedDriversData } from 'components/blocks/PolicyAccordion/types';
import { CsPlaceholders, replacePlaceholdersRichText } from './replaceCsPlaceholders';

const accountDriverPlaceholders: CsPlaceholders<NamedDriver> = {
  driver: {
    getSubstitutionText: (driver: NamedDriver): string => driver.fullName ?? '',
    isPii: true,
  },
};

const replaceAccountDriverPlaceholders = (
  driver: NamedDriver,
  named_drivers: NamedDriversData
): NamedDriversData => {
  const replaceRichTextAccountDriverPlaceholders = replacePlaceholdersRichText(
    accountDriverPlaceholders,
    driver
  );
  return {
    ...named_drivers,
    modals: {
      remove_driver_success_modal: {
        ...named_drivers.modals.remove_driver_success_modal,
        body: replaceRichTextAccountDriverPlaceholders(
          named_drivers.modals.remove_driver_success_modal.body
        ),
      },
      remove_driver_confirmation_modal: {
        ...named_drivers.modals.remove_driver_confirmation_modal,
        body: replaceRichTextAccountDriverPlaceholders(
          named_drivers.modals.remove_driver_confirmation_modal.body
        ),
      },
      remove_only_driver_error_modal: {
        ...named_drivers.modals.remove_only_driver_error_modal,
        body: replaceRichTextAccountDriverPlaceholders(
          named_drivers.modals.remove_only_driver_error_modal.body
        ),
      },
      remove_only_permanent_driver_error_modal: {
        ...named_drivers.modals.remove_only_permanent_driver_error_modal,
        body: replaceRichTextAccountDriverPlaceholders(
          named_drivers.modals.remove_only_permanent_driver_error_modal.body
        ),
      },
      remove_only_full_licence_driver_error_modal: {
        ...named_drivers.modals.remove_only_full_licence_driver_error_modal,
        body: replaceRichTextAccountDriverPlaceholders(
          named_drivers.modals.remove_only_full_licence_driver_error_modal.body
        ),
      },
      permanent_drivers_locked_modal: {
        ...named_drivers.modals.permanent_drivers_locked_modal,
      },
    },
  };
};

export default replaceAccountDriverPlaceholders;
