import { DoubleHeaderProps } from '@rsa-digital/evo-shared-components/components/Header/DoubleHeader';
import { graphql, useStaticQuery } from 'gatsby';
import { trackMainNavigationClick } from 'helpers/eventTracking';
import { handleAndTrackCtaClick } from 'helpers/navigation';
import { CsStaffAreaPage } from 'templates/StaffAreaPage';
import { useHeaderData as useAccountHeaderData } from '../AccountHeader/useHeaderData';

export const query = graphql`
  query {
    csStaffAreaPage(page_id: { eq: "staff-portal" }) {
      url
    }
  }
`;

type CsStaffAreaPageUrl = {
  csStaffAreaPage: Pick<CsStaffAreaPage, 'url'>;
};

export const useHeaderData = (hideSearchButton?: boolean): DoubleHeaderProps => {
  const accountHeaderData = useAccountHeaderData();
  const { url } = useStaticQuery<CsStaffAreaPageUrl>(query).csStaffAreaPage;

  return {
    defaultLogoLink: accountHeaderData.defaultLogoLink,
    desktopLogoLink: accountHeaderData.desktopLogoLink,
    primaryNav: hideSearchButton
      ? undefined
      : // This is required for the nav menu to appear on mobile
        {
          navItems: [],
        },
    tertiaryNavItem: hideSearchButton
      ? undefined
      : {
          name: 'New search',
          url,
          openInNewTab: false,
          onClick: handleAndTrackCtaClick('/staff-portal', 'Header', 'New search'),
          icon: {
            name: 'magnifying-glass',
            size: 'medium',
          },
        },
    mobileMenuOnOpen: trackMainNavigationClick('Mobile menu toggle', 'Open'),
    mobileMenuOnClose: trackMainNavigationClick('Mobile menu toggle', 'Close'),
    mobileHeaderText: 'Menu',
  };
};
