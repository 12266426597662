import { AccordionEntry } from '@rsa-digital/evo-shared-components/components/Accordion';
import React from 'react';
import CoverNotesSection from 'components/blocks/PolicyAccordion/CoverNotes';
import NamedDriversSection from 'components/blocks/PolicyAccordion/NamedDrivers';
import PersonalDetailsTable from 'components/blocks/PolicyAccordion/PersonalDetails';
import PolicyDetailsTable from 'components/blocks/PolicyAccordion/PolicyDetails';
import PolicyDocumentsSection from 'components/blocks/PolicyAccordion/PolicyDocuments';
import TemporaryDriversSection from 'components/blocks/PolicyAccordion/TemporaryDrivers';
import VehicleDetailsTable from 'components/blocks/PolicyAccordion/VehicleDetails';
import { nonFatalBuildError, warningWithDetail } from 'helpers/errorReporting';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import { CoverNotesProps, PolicyAccordionEntriesBlock, TemporaryDriversProps } from './types';

export type CsBlock = {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type BlockProps<T extends keyof PolicyAccordionEntriesBlock> = {
  [K in T]: PolicyAccordionEntriesBlock[T];
};

type MappingEntry<T extends keyof PolicyAccordionEntriesBlock> = [T, React.FC<BlockProps<T>>];

const m = <T extends keyof PolicyAccordionEntriesBlock>(
  key: T,
  value: React.FC<BlockProps<T>>
): MappingEntry<T> => [key, value];

const mapDetails = <T extends keyof PolicyAccordionEntriesBlock>(
  props: BlockProps<T>,
  Block: React.FC<BlockProps<T>>,
  blockKey: T,
  initiallyDisplayAllEntries: boolean
): AccordionEntry => ({
  title: props[blockKey].heading,
  // eslint-disable-next-line react/jsx-props-no-spreading
  content: <Block {...props} />,
  onExpand: trackAccordionExpandClick('General', props[blockKey].heading),
  onCollapse: trackAccordionCollapseClick('General', props[blockKey].heading),
  initiallyDisplay: initiallyDisplayAllEntries || props[blockKey].initially_display,
});

const ACCORDION_MAPPING = [
  m('policy_details', PolicyDetailsTable),
  m('vehicle_details', VehicleDetailsTable),
  m('personal_details', PersonalDetailsTable),
  m('named_drivers', NamedDriversSection),
  m('temporary_drivers', TemporaryDriversSection),
  m('policy_documents', PolicyDocumentsSection),
  m('cover_notes', CoverNotesSection),
];

const mapBlock = (block: CsBlock, initiallyDisplayAllEntries: boolean): AccordionEntry | null => {
  const key = Object.entries(block).find(([, value]) => !!value)?.[0];
  /* istanbul ignore if */
  if (!key) {
    warningWithDetail(
      'Unrecognised empty accordion block.',
      'Have you forgotten to extend the accordion with a new block type?'
    );
    return null;
  }

  const Block = ACCORDION_MAPPING.find(([k]) => k === key)?.[1];

  /* istanbul ignore if */
  if (!Block) {
    nonFatalBuildError(
      `Unrecognised block of type '${key}'.`,
      'Have you forgotten to update the accordion block mapping?'
    );
    return null;
  }

  return mapDetails(
    { [key]: block[key] } as BlockProps<keyof PolicyAccordionEntriesBlock>,
    Block as React.FC<BlockProps<keyof PolicyAccordionEntriesBlock>>,
    key as keyof PolicyAccordionEntriesBlock,
    initiallyDisplayAllEntries
  );
};

const getBlock = (
  block: CsBlock,
  temporaryDriverBlocks: TemporaryDriversProps & CoverNotesProps
): CsBlock => {
  const blockType = Object.entries(block).find(([, value]) => !!value)?.[0];
  if (blockType !== 'cover_notes' && blockType !== 'temporary_drivers') {
    return block;
  }
  return { [blockType]: temporaryDriverBlocks[blockType] };
};

export const mapAccordionBlocks = (
  blocks: CsBlock[],
  temporaryDriverBlocks: TemporaryDriversProps & CoverNotesProps,
  initiallyDisplayEntries: boolean,
  hideCoverNotes?: boolean
): AccordionEntry[] =>
  blocks
    .filter(
      (block) =>
        !(
          Object.entries(block).find(([, value]) => !!value)?.[0] === 'cover_notes' &&
          hideCoverNotes
        )
    )
    .map(
      (block) =>
        mapBlock(getBlock(block, temporaryDriverBlocks), initiallyDisplayEntries) as AccordionEntry
    );
