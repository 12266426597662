import React from 'react';
import { CoverNote } from 'state/policy/state';
import CoverNoteCards from '../CoverNoteCards';
import { RichTextWithMargin } from '../styles';
import { CoverNotesProps } from '../types';

const CoverNotesHistorySection: React.FC<CoverNotesProps & { coverNotes: CoverNote[] }> = ({
  cover_notes: coverNotesStaticContent,
  coverNotes,
}) => (
  <div>
    <RichTextWithMargin html={coverNotesStaticContent.cover_notes_history.rich_text} />
    <CoverNoteCards cover_notes={coverNotesStaticContent} coverNotes={coverNotes} />;
  </div>
);

export default React.memo(CoverNotesHistorySection);
