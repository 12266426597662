import referenceDataClient from 'apiHelpers/referenceDataClient';
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery,
} from 'redux-saga/effects';
import { RootState } from 'state/createStore';
import { ErrorAction, UPDATE_ERROR_STATE } from 'state/error/actions';
import { isAxiosError } from 'helpers/axiosResponseHelper';
import {
  REFERENCE_DATA_FETCHED,
  REFERENCE_DATA_LOADING,
  REFERENCE_DATA_REQUESTED,
  ReferenceDataAction,
  RequestReferenceDataAction,
} from './actions';
import { ReferenceData, ReferenceDataKey, ReferenceDataStatus } from './state';

type ReferenceDataGenerator = Generator<
  | CallEffect<ReferenceData[ReferenceDataKey]>
  | PutEffect<ReferenceDataAction | ErrorAction>
  | SelectEffect
>;

function* fetchReferenceData({ dataType }: RequestReferenceDataAction): ReferenceDataGenerator {
  const currentStatus = yield select((state: RootState) => state.referenceData[dataType].status);
  if (currentStatus !== ReferenceDataStatus.NotLoaded) {
    return;
  }
  yield put({ type: REFERENCE_DATA_LOADING, dataType });
  try {
    const data = yield call(referenceDataClient[dataType]);
    yield put({
      type: REFERENCE_DATA_FETCHED,
      dataType,
      data: data as ReferenceData[ReferenceDataKey],
    });
  } catch (error) {
    const possibleAxiosError = error as Error;
    if (isAxiosError(possibleAxiosError)) {
      console.error(
        `Error when making API request for reference data '${dataType}': server returned ${possibleAxiosError.response?.status}`,
        ...(possibleAxiosError.response?.data
          ? [' with data ', possibleAxiosError.response?.data]
          : [])
      );
    } else {
      console.error(
        `Error when making API request for reference data '${dataType}': `,
        (error as Error).message
      );
    }
    const errorAction: ErrorAction = {
      type: UPDATE_ERROR_STATE,
      state: { hasErrored: true },
    };
    yield put(errorAction);
  }
}

function* handleReferenceDataRequest(): Generator<ForkEffect<never>> {
  yield takeEvery(REFERENCE_DATA_REQUESTED, fetchReferenceData);
}

export default handleReferenceDataRequest;
