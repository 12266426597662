import Accordion, { AccordionEntry } from '@rsa-digital/evo-shared-components/components/Accordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql } from 'gatsby';
import React from 'react';
import { trackAccordionCollapseClick, trackAccordionExpandClick } from 'helpers/eventTracking';
import { AccordionWrapperBlock, Heading, HeadingsGridItem } from './styles';

type BasicAccordionBlockProps = {
  basic_accordion: {
    heading: string;
    rich_text: string;
    accordion_entries: {
      entry_title: string;
      entry_content: string;
    }[];
  };
};

export const query = graphql`
  fragment BasicAccordionBlockInfo on cs__info_page_blocks {
    basic_accordion {
      heading
      rich_text
      accordion_entries {
        entry_title
        entry_content
      }
    }
  }
  fragment BasicAccordionBlockError on cs__error_page_blocks {
    basic_accordion {
      heading
      rich_text
      accordion_entries {
        entry_title
        entry_content
      }
    }
  }
`;

const BasicAccordionBlock: React.FC<BasicAccordionBlockProps> = ({ basic_accordion }) => {
  const convertedEntries = basic_accordion.accordion_entries.map(
    (entry): AccordionEntry => ({
      title: entry.entry_title,
      content: <RichText html={entry.entry_content} />,
      onExpand: trackAccordionExpandClick('General', entry.entry_title),
      onCollapse: trackAccordionCollapseClick('General', entry.entry_title),
    })
  );

  return (
    <AccordionWrapperBlock data-cy="BasicAccordion">
      {basic_accordion.heading && (
        <Grid>
          <HeadingsGridItem desktop={10} tabletLandscape={8}>
            <Heading>{basic_accordion.heading}</Heading>
            {basic_accordion.rich_text && <RichText html={basic_accordion.rich_text} />}
          </HeadingsGridItem>
        </Grid>
      )}
      <Accordion accordionEntries={convertedEntries} initiallyDisplayEntries={false} />
    </AccordionWrapperBlock>
  );
};

export default React.memo(BasicAccordionBlock);
