/* eslint-disable @typescript-eslint/naming-convention */
import { RelatedContentCardProps } from '@rsa-digital/evo-shared-components/components/RelatedContent';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { nonFatalBuildError } from 'helpers/errorReporting';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { CsAsset } from 'types/contentStack';

export type CsRelatedContentCard = {
  url: string;
  image: CsAsset | null;
  title: string;
  body: string | null;
  reading_time: string | null;
  read_more_text: string | null;
};

const processRelatedContentCard = ({
  image,
  body,
  reading_time,
  title,
  url,
  read_more_text,
}: CsRelatedContentCard): RelatedContentCardProps => ({
  url,
  image: processImageAsset(image),
  heading: title,
  body: body ?? undefined,
  subheading: reading_time ?? undefined,
  onClick: handleAndTrackLinkClick(url, 'relatedContentCard', title),
  readMore: read_more_text ? { url, text: read_more_text } : undefined,
});

export const processRelatedContentCards = (
  cardsData: CsRelatedContentCard[]
): RelatedContentCardProps[] => {
  /* istanbul ignore if */
  if (cardsData.length < 2) {
    nonFatalBuildError(
      'Too few cards provided',
      'The related content component should have at least 2 cards'
    );
  }

  return cardsData.map(processRelatedContentCard);
};
