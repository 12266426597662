import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
  Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { DocumentsAction } from './document/actions';
import { documentsReducer } from './document/reducer';
import { ErrorAction } from './error/actions';
import { errorReducer } from './error/reducer';
import { AboutTheDriverAction } from './forms/aboutTheDriver/action';
import { aboutTheDriverReducer } from './forms/aboutTheDriver/reducer';
import { ClaimsHistoryAction } from './forms/claimsHistory/action';
import { claimsHistoryReducer } from './forms/claimsHistory/reducer';
import { ConfirmAddressAction } from './forms/confirmAddress/action';
import { confirmAddressReducer } from './forms/confirmAddress/reducer';
import { DriverConsentAction } from './forms/driverConsent/action';
import { driverConsentReducer } from './forms/driverConsent/reducer';
import { DriverDetailsAction } from './forms/driverDetails/action';
import { driverDetailsReducer } from './forms/driverDetails/reducer';
import { DrivingOffencesAction } from './forms/drivingOffences/action';
import { drivingOffencesReducer } from './forms/drivingOffences/reducer';
import { HirerOrNomineeAgreementAction } from './forms/hirerOrNomineeAgreement/action';
import { hirerOrNomineeAgreementReducer } from './forms/hirerOrNomineeAgreement/reducer';
import { KnockoutOverridesAction } from './forms/knockoutOverrides/action';
import { knockoutOverridesReducer } from './forms/knockoutOverrides/reducer';
import { LicenceDetailsAction } from './forms/licenceDetails/action';
import { licenceDetailsReducer } from './forms/licenceDetails/reducer';
import { LicenceRestrictionsAction } from './forms/licenceRestrictions/action';
import { licenceRestrictionsReducer } from './forms/licenceRestrictions/reducer';
import { NewDriversCoverAction } from './forms/newDriversCover/action';
import { newDriversCoverReducer } from './forms/newDriversCover/reducer';
import { PermanentDriverAgreementAction } from './forms/permanentDriverAgreement/action';
import { permanentDriverAgreementReducer } from './forms/permanentDriverAgreement/reducer';
import { ReplaceDriverAction } from './forms/replaceDriver/action';
import { replaceDriverReducer } from './forms/replaceDriver/reducer';
import { LoadingAction } from './loading/actions';
import { loadingReducer } from './loading/reducer';
import { LookupPolicyAction } from './lookupPolicy/actions';
import { lookupPolicyReducer } from './lookupPolicy/reducer';
import { ManagedPolicyAction } from './managedPolicy/actions';
import { managedPolicyReducer } from './managedPolicy/reducer';
import { PolicyAction } from './policy/actions';
import { policyReducer } from './policy/reducer';
import { ReferenceDataAction } from './referenceData/actions';
import referenceDataReducer from './referenceData/reducer';
import referenceDataSaga from './referenceData/saga';
import { SeatStatusAction } from './seatStatus/action';
import { seatStatusReducer } from './seatStatus/reducer';
import { UserAction } from './user/actions';
import { userReducer } from './user/reducer';

const rootReducer = combineReducers({
  error: errorReducer,
  loading: loadingReducer,
  policy: policyReducer,
  documents: documentsReducer,
  driverDetails: driverDetailsReducer,
  confirmAddress: confirmAddressReducer,
  drivingOffences: drivingOffencesReducer,
  licenceRestrictions: licenceRestrictionsReducer,
  aboutTheDriver: aboutTheDriverReducer,
  referenceData: referenceDataReducer,
  driverConsent: driverConsentReducer,
  licenceDetails: licenceDetailsReducer,
  hirerOrNomineeAgreement: hirerOrNomineeAgreementReducer,
  permanentDriverAgreement: permanentDriverAgreementReducer,
  claimsHistory: claimsHistoryReducer,
  newDriversCover: newDriversCoverReducer,
  seatStatus: seatStatusReducer,
  replaceDriver: replaceDriverReducer,
  user: userReducer,
  lookupPolicy: lookupPolicyReducer,
  knockoutOverrides: knockoutOverridesReducer,
  managedPolicy: managedPolicyReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type RootAction =
  | PolicyAction
  | ErrorAction
  | LoadingAction
  | DocumentsAction
  | DriverDetailsAction
  | ConfirmAddressAction
  | DrivingOffencesAction
  | LicenceRestrictionsAction
  | AboutTheDriverAction
  | ReferenceDataAction
  | DriverConsentAction
  | LicenceDetailsAction
  | HirerOrNomineeAgreementAction
  | PermanentDriverAgreementAction
  | ClaimsHistoryAction
  | NewDriversCoverAction
  | SeatStatusAction
  | ReplaceDriverAction
  | UserAction
  | LookupPolicyAction
  | KnockoutOverridesAction
  | ManagedPolicyAction;

const createStore = (): Store<RootState, RootAction> => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    (typeof window !== 'undefined' &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const store = reduxCreateStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(referenceDataSaga);

  return store;
};

export default createStore;
