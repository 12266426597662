import { useCallback } from 'react';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import useDispatch from 'state/useDispatch';
import { PolicyState } from './state';

export const UPDATE_POLICY = 'UPDATE_POLICY';

export const RELOAD_POLICY = 'RELOAD_POLICY';

export type PolicyAction =
  | ReloadPolicyAction
  | UpdatePolicyAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdatePolicyAction = {
  type: typeof UPDATE_POLICY;
  policy: PolicyState;
};

export type ReloadPolicyAction = {
  type: typeof RELOAD_POLICY;
};

export const useUpdatePolicy = (): ((newPolicyState: PolicyState) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (newPolicyState: PolicyState): void => {
      dispatch({ type: UPDATE_POLICY, policy: newPolicyState });
    },
    [dispatch]
  );
};
