import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ResetAddADriverStateAction, ResetStateAction } from 'state/actions';
import { RootState } from 'state/createStore';
import useDispatch from 'state/useDispatch';
import { PermanentDriverAgreementState } from './state';

export const UPDATE_PERMANENT_DRIVER_AGREEMENT = 'UPDATE_PERMANENT_DRIVER_AGREEMENT';

export type PermanentDriverAgreementAction =
  | UpdatePermanentDriverAgreementAction
  | ResetStateAction
  | ResetAddADriverStateAction;

export type UpdatePermanentDriverAgreementAction = {
  type: typeof UPDATE_PERMANENT_DRIVER_AGREEMENT;
  update: Partial<PermanentDriverAgreementState>;
};

export const usePermanentDriverAgreement = (): [
  PermanentDriverAgreementState,
  (update: Partial<PermanentDriverAgreementState>) => void
] => {
  const permanentDriverAgreementState = useSelector(
    (state: RootState) => state.permanentDriverAgreement
  );
  const dispatch = useDispatch();

  const updatePermanentDriverAgreementState = useCallback(
    (update: Partial<PermanentDriverAgreementState>): void => {
      dispatch({ type: UPDATE_PERMANENT_DRIVER_AGREEMENT, update });
    },
    [dispatch]
  );

  return [permanentDriverAgreementState, updatePermanentDriverAgreementState];
};
