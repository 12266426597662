import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addDaysToDate,
  dateToDateValue,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';

export const initialDateValue: DateValue = {
  day: '',
  month: '',
  year: '',
};

export const startDateBeforeEndDate = (startDate: DateValue, endDate: DateValue): boolean => {
  const parsedStartDate = dateValueToUtcDate(startDate);
  const parsedEndDate = dateValueToUtcDate(endDate);
  if (parsedEndDate && parsedStartDate) {
    return parsedStartDate <= parsedEndDate;
  }
  return true;
};

export const datesWithinXDaysOfEachOther = (
  daysDifference: number,
  startDate: DateValue,
  endDate: DateValue
): boolean => {
  const parsedStartDate = dateValueToUtcDate(startDate);
  const parsedEndDate = dateValueToUtcDate(endDate);
  if (parsedEndDate && parsedStartDate) {
    return addDaysToDate(parsedStartDate, daysDifference) >= parsedEndDate;
  }
  return true;
};

export const dateWithinXDaysFromNow = (daysDifference: number, date: DateValue): boolean => {
  const today = dateToDateValue(new Date());

  return datesWithinXDaysOfEachOther(daysDifference, today, date);
};
