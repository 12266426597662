import { twoLayeredBoxShadow } from '@rsa-digital/evo-shared-components/commonStyles/styling';
import Accordion from '@rsa-digital/evo-shared-components/components/Accordion';
import { Title as InnerSectionTitle } from '@rsa-digital/evo-shared-components/components/Accordion/InnerAccordionSection/styles';
import {
  InnerSectionsWrapper,
  OuterSection,
  SectionButton,
  SectionTitle,
} from '@rsa-digital/evo-shared-components/components/Accordion/styles';
import { TertiaryButton } from '@rsa-digital/evo-shared-components/components/Button';
import ExpandableCard from '@rsa-digital/evo-shared-components/components/ExpandableCard';
import { StyledTr } from '@rsa-digital/evo-shared-components/components/ExpandableTable/Row/styles';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import Table from './Table';

export const AccordionWrapperBlock = styled.div`
  margin-top: ${spacing(8)};
  margin-bottom: ${spacing(8)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(12)};
  `}
`;

export const Heading = styled.h2`
  ${fonts.headingLarge};
  margin-bottom: ${spacing(2)};
  margin-top: 0;
`;

export const HeadingsGridItem = styled(GridItem)`
  margin: 0 0 ${spacing(8)};
`;

export const StyledAccordion = styled(Accordion)`
  ${SectionTitle}, ${InnerSectionTitle} {
    ${fonts.headingLarge}
  }

  ${OuterSection} {
    padding: ${spacing(3)} 0;
  }

  ${SectionButton} {
    padding-left: ${spacing(1)};

    ${mediaQuery.tabletPortrait`
      padding-bottom: ${spacing(2)};
    `}
  }

  ${InnerSectionsWrapper} {
    margin-top: ${spacing(3)};
    border-top: 1px solid ${colors.core03};
  }
`;

export const TitleWithMargin = styled.b`
  margin-right: ${spacing(2)};
`;

export const RowTitle = styled.div`
  ${fonts.headingXSmall};
  text-align: left;
  margin: 0;
`;

export const TooltipWrapper = styled.div`
  display: flex;
`;

export const StyledTrWithVerticalAlign = styled(StyledTr)`
  align-items: flex-start;
  padding-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(3)};
    padding-left: ${spacing(3.5)};
  `}

  ${mediaQuery.tabletLandscape`
    padding-left: 0;
  `}
`;

export const StyledTrWithNoTopPadding = styled(StyledTrWithVerticalAlign)`
  :first-child {
    padding-top: 0;
  }
`;

export const StyledTable = styled(Table)`
  padding: ${spacing(2)} ${spacing(3.5)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)} ${spacing(3.5)} ${spacing(2)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} ${spacing(3.75)};
  `}
`;

export const StyledExpandableCard = styled(ExpandableCard)`
  margin: ${spacing(4)} 0;
  padding: 0;

  :last-child {
    margin-bottom: ${spacing(3.5)};
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(4)};
    `}
  }
`;

export const StyledSmallHeading = styled.h3`
  ${fonts.headingSmall};
`;

export const RichTextWithTopPadding = styled(RichText)`
  &&& {
    padding: ${spacing(3)} 0 0;
  }
`;

const TertiaryButtonStyling = css`
  &&& {
    text-decoration: none;
    padding: ${spacing(1.5)} ${spacing(2)};
    transition: all 0.3s ease-out;

    display: flex;
    align-items: flex-end;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${spacing(2)} ${spacing(3.5)};

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    padding: 0 ${spacing(3.5)} ${spacing(3)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(3.75)} ${spacing(3)};
  `}
`;

export const StyledTertiaryButton = styled(TertiaryButton)`
  ${TertiaryButtonStyling};
  border-radius: ${spacing(2.5)};

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(2)};
  `}
`;

export const TertiaryButtonWithTopMargin = styled(StyledTertiaryButton)`
  margin-top: ${spacing(4)};
`;

export const RedStyledTertiaryButton = styled(TertiaryButton)`
  ${TertiaryButtonStyling};
  border-radius: ${spacing(2.5)};
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
  `}

  &&& {
    background: ${colors.neutral08};
    border-color: ${colors.notificationError};
    color: ${colors.notificationError};

    &&&:hover,
    &&&:focus {
      text-shadow: 0 0 0.5px ${colors.notificationError}, 0 0 0.5px ${colors.notificationError};
      background: ${colors.notificationError}1A;
      box-shadow: inset 0 0 0 1px ${colors.notificationError};
      color: ${colors.notificationError};
    }

    &&&:focus {
      box-shadow: inset 0 0 0 1px ${colors.notificationError},
        ${twoLayeredBoxShadow(colors.neutral08, colors.notificationError)};
      background: ${colors.neutral08};
    }
  }
`;

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};
  margin: 0 0 ${spacing(2)};
`;

export const ModalBody = styled.div`
  margin: 0;
  padding: 0;
`;

export const IconWithRightMargin = styled(Icon)`
  margin-right: ${spacing(2)};
`;

export const IconWithLeftMargin = styled(Icon)`
  margin-left: ${spacing(2)};
`;

export const InfoText = styled.p`
  ${fonts.paragraphLarge};
`;

export const ModalContainer = styled.div`
  padding: ${spacing(2)} ${spacing(3)} ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(2)} ${spacing(7)} ${spacing(1)};
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(2)} ${spacing(8)} ${spacing(1)};
  `}
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing(4)};
`;

export const CancelButton = styled(ButtonLink)`
  margin-right: ${spacing(2)};
  padding: 0;
  ${fonts.paragraph};

  &&& {
    border: none;
    box-shadow: none;
    text-decoration: underline;
    color: ${colors.core01};

    &&&:hover,
    &&&:focus {
      background: ${colors.neutral08};
      box-shadow: none;
      text-shadow: 0 0 0.5px ${colors.core01}, 0 0 0.5px ${colors.core01};
    }

    &&&:focus {
      box-shadow: none;
      background: ${colors.neutral08};
    }
  }

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(5.75)};
  `}
`;

export const ConfirmButton = styled(TertiaryButton)`
  ${TertiaryButtonStyling};
`;

export const RichTextWithMargin = styled(RichText)`
  &&& {
    margin: ${spacing(2)} 0 ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      margin: ${spacing(3)} 0 0;
    `}
  }
`;
